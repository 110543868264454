import useStore from 'model/store';

import Select from 'mda2-frontend/src/generic/components/Form/Select';
import {
  type LabelPartsFragment,
  useLabelsQuery,
} from 'mda2-frontend/src/graphql/types';
import { useMemo } from 'react';

interface LabelSelectProps {
  isDeselectable?: boolean;
  onLabelSelect?: (l: LabelPartsFragment | LabelPartsFragment[] | null) => void;
  labelNames?: string[] | null;
}

export default function LabelSelect({
  isDeselectable,
  onLabelSelect,
  labelNames,
}: LabelSelectProps) {
  const [{ data: labelData }] = useLabelsQuery({
    context: useMemo(() => ({ additionalTypenames: ['Labels'] }), []),
  });

  const labelOptions = useMemo(
    () => labelData?.Labels.sort((a, b) => a.Name.localeCompare(b.Name)),
    [labelData?.Labels],
  );
  const labels = useStore((state) => state.userSettings.labels);
  const setSelectedLabels = useStore((state) => state.userApi.setLabels);
  const options = useMemo(
    () =>
      labelOptions?.filter((l) =>
        labelNames !== undefined
          ? labelNames?.includes(l.Name)
          : labels?.includes(l.Name),
      ),
    [labelNames, labelOptions, labels],
  );

  return (labelOptions?.length ?? 0) > 0 ? (
    <Select
      dataTestId="select-labels"
      label="Labels"
      value={
        options || (labelOptions && !isDeselectable ? labelOptions[0] : null)
      }
      onChangeSelected={(selected) => {
        if (onLabelSelect) {
          onLabelSelect(selected ?? null);
        } else if (selected) {
          if (Array.isArray(selected)) {
            setSelectedLabels(selected.map((s) => s.Name));
          } else {
            setSelectedLabels([selected.Name]);
          }
        }
      }}
      renderValue={(r) => r?.Name ?? ''}
      keyParameter="Id"
      options={labelOptions ?? []}
      isDeselectable={isDeselectable}
    />
  ) : null;
}
