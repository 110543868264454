import useStore from 'model/store';

import { isAfter, subMinutes } from 'date-fns';

export default function deskIsUsed(
  updatedAt: Date,
  value: number,
  isPrivate: boolean,
) {
  return (
    value === 1 ||
    (value === 0 &&
      isAfter(
        new Date(updatedAt),
        subMinutes(
          new Date(),
          useStore.getState().organizationSettings.warmMinutesPolicy,
        ),
      )) ||
    isPrivate
  );
}
