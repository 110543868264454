import Accordion from 'generic/components/Accordion';
import { FormattedMessage } from 'translations/Intl';

import SetSceneConfigButton from './components/SetSceneConfigButton';
import SetSceneIdentifyButton from './components/SetSceneIdentifyButton';
import SetSceneNoneButton from './components/SetSceneNoneButton';
import SetSceneResetButton from './components/SetSceneResetButton';

interface BluerangeMqttActionProps {
  mqttTopic: string;
}

export default function BluerangeMqttAction({
  mqttTopic,
}: BluerangeMqttActionProps) {
  return (
    <Accordion title={<FormattedMessage id="MQTT Actions" />} initialStateOpen>
      <div className="relative">
        <div className="flex space-x-2">
          <SetSceneIdentifyButton mqttTopic={mqttTopic} />
          <SetSceneNoneButton mqttTopic={mqttTopic} />
          <SetSceneResetButton mqttTopic={mqttTopic} />
          <SetSceneConfigButton mqttTopic={mqttTopic} />
        </div>
      </div>
    </Accordion>
  );
}
