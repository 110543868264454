import { FormattedMessage, type IntlMessageKeys } from 'translations/Intl';

import { ClimateType } from 'mda2-frontend/src/common/types';
import { useClimateSensorsQuery } from 'mda2-frontend/src/graphql/types';
import { lower, upper } from 'mda2-frontend/src/utils/numberrange';
import { defaultClimateValues } from 'pages/SettingsView/components/OrganizationView/components/LimitSelect/LimitSelect';
import { Fragment, type ReactNode } from 'react';
import { ClimateStatus } from 'utils/getClimateStatus';

function Title({
  text,
  className,
}: {
  text: IntlMessageKeys;
  className: string;
}) {
  return (
    <div className={`col-span-1 text-right text-bold ${className}`}>
      <FormattedMessage id={text} />
    </div>
  );
}

function Row({
  className = '',
  children,
}: {
  className?: string;
  children: ReactNode;
}) {
  return (
    <div
      className={`col-span-1 text-black dark:text-white text-right ${className}`}
    >
      {children}
    </div>
  );
}

interface ClimateValueProps {
  hideRecommended?: boolean;
}

export default function ClimateValues({
  hideRecommended = false,
}: ClimateValueProps) {
  const [{ data, fetching }] = useClimateSensorsQuery();

  const renderDefaultValue = (
    type: ClimateType,
    valueType: ClimateStatus,
    range: string,
  ) => {
    return (
      <div>
        {lower(range) && upper(range)
          ? `${defaultClimateValues[type][valueType].start?.value}-${defaultClimateValues[type][valueType].end?.value}`
          : !lower(range) && upper(range)
            ? `<${defaultClimateValues[type][valueType].end?.value}`
            : // Only lower range
              `>${defaultClimateValues[type][valueType].start?.value}`}
      </div>
    );
  };

  return (
    <div className="space-y-2">
      <div className="grid grid-cols-4">
        <div className="col-span-1" />
        <Title text="Good" className="text-green-500 mr-4" />
        <Title text="Acceptable" className="text-yellow-500" />
        <Title text="Poor" className="text-red-500" />
        {fetching &&
          Array.from({ length: 4 }).map((_, i) => (
            <div
              className="col-span-4 grid grid-cols-4 gap-2 pt-2"
              key={`${i}-row`}
            >
              <div className="col-span-1 animate-pulse bg-neutral-200 h-4 rounded-md" />
              <div className="col-span-1 animate-pulse bg-neutral-200 h-4 rounded-md" />
              <div className="col-span-1 animate-pulse bg-neutral-200 h-4 rounded-md" />
              <div className="col-span-1 animate-pulse bg-neutral-200 h-4 rounded-md" />
            </div>
          ))}
        <div className="col-span-4 divide-y dark:divide-neutral-700">
          {data?.SensorTypes.filter(
            (s) =>
              // TODO: Remove when stable
              ![ClimateType.HUMIDITY].includes(s.Name as ClimateType),
          ).map((sensorType) => (
            <Fragment key={sensorType.Name}>
              <div className="col-span-4 dark:text-neutral-100 text-left bg-neutral-100 dark:bg-neutral-900 font-bold">
                <FormattedMessage id={sensorType.Name as IntlMessageKeys} /> (
                {sensorType.Unit})
              </div>
              <div className="grid grid-cols-4 col-span-4">
                <div className="col-span-1">
                  <FormattedMessage id="Configured" />
                </div>
                <Row className="mr-4">
                  {lower(sensorType.ClimateSensorLimits[0]?.GoodValue!)
                    ? `${lower(
                        sensorType.ClimateSensorLimits[0]?.GoodValue!,
                      )}-${upper(sensorType.ClimateSensorLimits[0]?.GoodValue!)}`
                    : `<${upper(sensorType.ClimateSensorLimits[0]?.GoodValue!)}`}
                </Row>
                <Row>
                  {lower(sensorType.ClimateSensorLimits[0]?.AcceptableValue!)}-
                  {upper(sensorType.ClimateSensorLimits[0]?.AcceptableValue!)}
                </Row>
                <Row>
                  {'>'}
                  {lower(sensorType.ClimateSensorLimits[0]?.PoorValue!)}
                </Row>
                {!hideRecommended && (
                  <>
                    <div className="col-span-1">
                      <FormattedMessage id="Recommended" />
                    </div>
                    <Row className="mr-4">
                      {renderDefaultValue(
                        sensorType.Name as ClimateType,
                        ClimateStatus.GOOD,
                        sensorType.ClimateSensorLimits[0]?.GoodValue!,
                      )}
                    </Row>
                    <Row>
                      {renderDefaultValue(
                        sensorType.Name as ClimateType,
                        ClimateStatus.ACCEPTABLE,
                        sensorType.ClimateSensorLimits[0]?.AcceptableValue!,
                      )}
                    </Row>
                    <Row>
                      {renderDefaultValue(
                        sensorType.Name as ClimateType,
                        ClimateStatus.POOR,
                        sensorType.ClimateSensorLimits[0]?.PoorValue!,
                      )}
                    </Row>
                  </>
                )}
              </div>
            </Fragment>
          ))}
        </div>
      </div>
      {!hideRecommended && (
        <div>
          <FormattedMessage
            id="Room climate description"
            values={{
              linkCo2Temp: (
                <a
                  className="text-primary-500"
                  title="ArGV3_art16"
                  href="https://www.seco.admin.ch/dam/seco/de/dokumente/Arbeit/Arbeitsbedingungen/Arbeitsgesetz%20und%20Verordnungen/Wegleitungen/Wegleitungen%203/ArGV3_art16.pdf.download.pdf/ArGV3_art16_de.pdf"
                >
                  ArGV3 Art. 16
                </a>
              ),
              linkAudio: (
                <a
                  className="text-primary-500"
                  title="ArGV3_art22"
                  href="https://www.seco.admin.ch/dam/seco/de/dokumente/Arbeit/Arbeitsbedingungen/Arbeitsgesetz%20und%20Verordnungen/Wegleitungen/Wegleitungen%203/ArGV3_art22.pdf.download.pdf/ArGV3_art22_de.pdf"
                >
                  ArGV3 Art. 22 1.3.2.1/1.3.2.2
                </a>
              ),
            }}
          />
        </div>
      )}
    </div>
  );
}
