import useStore from 'model/store';
import CheckMark from '../Tile/CheckMark';
import TileButton from '../Tile/TileButton';

export default function BackgroundSwitch() {
  const backgroundEnabled = useStore(
    (state) => state.userSettings.backgroundEnabled,
  );
  const setBackgroundEnabled = useStore(
    (state) => state.userApi.setBackgroundEnabled,
  );

  return (
    <div className="flex flex-col space-y-6 xl:space-x-6 xl:space-y-0 xl:flex-row w-auto relative">
      <TileButton onClick={() => setBackgroundEnabled(false)}>
        <div className="w-auto relative">
          <div className="transition-all shadow hover:shadow-lg rounded-lg">
            <div className="bg-neutral-50 dark:bg-neutral-900 h-24 rounded-lg" />
          </div>
          {!backgroundEnabled && <CheckMark />}
        </div>
      </TileButton>
      <TileButton onClick={() => setBackgroundEnabled(true)}>
        <div className="w-auto relative">
          <div className="transition-all flex shadow hover:shadow-lg rounded-lg bg-neutral-50 dark:bg-neutral-900">
            <div className="bg-pattern dark:bg-pattern-dark h-24 bg-cover w-48 rounded-lg" />
          </div>
          {backgroundEnabled && <CheckMark />}
        </div>
      </TileButton>
    </div>
  );
}
