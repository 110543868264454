import 'ol/ol.css';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import router from 'router/router';
import initializeFaro from 'utils/faro/initialize';
import { registerSW } from 'virtual:pwa-register';
import './css/index.scss';

initializeFaro();

registerSW({ immediate: true });

createRoot(document.getElementById('root')!).render(
  <RouterProvider router={router} />,
);
