import type { Point } from '@visx/brush/lib/types';
import { localPoint } from '@visx/event';
import type { PieArcDatum, ProvidedProps } from '@visx/shape/lib/shapes/Pie';
import { Text } from '@visx/text';
import { motion } from 'framer-motion';

interface AnimatedPieProps<Type> {
  pie: ProvidedProps<Type>;
  getKey: (d: PieArcDatum<Type>) => string;
  getColor: (d: PieArcDatum<Type>) => string;
  onMouseMove?: (coords: Point | null, datum: { data: Type }) => void;
  onMouseOut?: () => void;
  label?: boolean;
}

export default function AnimatedPie<Type>({
  pie,
  getKey,
  getColor,
  onMouseMove,
  onMouseOut,
  label = true,
}: AnimatedPieProps<Type>) {
  return (
    <>
      {pie.arcs.map((arc) => {
        const [centroidX, centroidY] = pie.path.centroid(arc);
        const { index, data, startAngle, endAngle } = arc;
        const hasSpaceForLabel = endAngle - startAngle >= 0.1;
        const path = pie.path(arc) ?? undefined;
        const color = getColor(arc);

        return (
          <g key={index}>
            <motion.path
              stroke={color}
              initial="initial"
              animate="animate"
              d={path}
              variants={{
                initial: {
                  pathLength: 0,
                  strokeWidth: 5,
                },
                animate: {
                  pathLength: 1,
                  strokeWidth: 1,
                  transition: {
                    ease: 'easeInOut',
                    duration: 0.5,
                  },
                },
              }}
            />
            {/* Only used to animate the fill after the outline has been drawn */}
            <motion.path
              initial="initial"
              animate="animate"
              onMouseMove={
                onMouseMove
                  ? (event) => onMouseMove(localPoint(event), { data })
                  : undefined
              }
              onMouseOut={onMouseOut}
              data-test-id={`pie-chart-${getKey(arc)}`}
              d={path}
              variants={{
                initial: {
                  fill: '#fff',
                },
                animate: {
                  fill: color,
                  transition: {
                    ease: 'easeInOut',
                    delay: 0.5,
                    duration: 0.5,
                  },
                },
              }}
            />
            {hasSpaceForLabel && label && (
              <motion.g initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
                <Text
                  fill="white"
                  angle={0}
                  x={centroidX}
                  y={centroidY}
                  fontSize={9}
                  textAnchor="middle"
                  width={100}
                >
                  {getKey(arc)}
                </Text>
              </motion.g>
            )}
          </g>
        );
      })}
    </>
  );
}
