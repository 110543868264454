interface TitleProps {
  value: string;
  className?: string;
  dataTestId?: string;
}

export default function Subtitle({
  value,
  className = '',
  dataTestId,
}: TitleProps): JSX.Element {
  return (
    <div
      className={`md:text-xl text-lg font-extrabold dark:text-white text-neutral-800 ${className}`}
      data-test-id={dataTestId}
    >
      {value}
    </div>
  );
}
