import Subtitle from 'generic/components/Subtitle/Subtitle';
import { useRequireTermsUserMutation } from 'graphql/types';
import { FormattedMessage, useIntl } from 'translations/Intl';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import useToast from 'utils/graphql/useToast';

import { Action } from 'mda2-frontend/src/common/types';
import Modal from 'mda2-frontend/src/generic/components/Modal';
import ModalFooter from 'mda2-frontend/src/generic/components/ModalFooter';

interface TermsModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function TermsModal({
  open,
  setOpen,
}: TermsModalProps): JSX.Element {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const toast = useToast();
  const [{ fetching }, requireNewTerms] = useRequireTermsUserMutation();

  return (
    <Modal
      title={intl.formatMessage({
        id: 'Confirm action',
      })}
      action={Action.UPDATE}
      open={open}
      setShowModal={setOpen}
      footer={
        <ModalFooter
          disabled={false}
          proceed={intl.formatMessage({
            id: 'Confirm',
          })}
          isLoading={fetching}
          action={Action.UPDATE}
          onProceed={() => {
            requireNewTerms({}, hasuraHeader(HasuraPermissions.READ_ALL))
              .then((data) =>
                toast(data, {
                  message: {
                    type: 'success',
                    content: intl.formatMessage(
                      { id: 'Set terms' },
                      {
                        count: data.data?.RequireTermsUser?.length,
                      },
                    ),
                  },
                }),
              )
              .finally(() => {
                setOpen(false);
              });
          }}
          onCancel={() => setOpen(false)}
        />
      }
    >
      <Subtitle
        value={intl.formatMessage({ id: 'Update terms' })}
        className="text-base pb-2"
      />

      <p className="pb-2">
        <FormattedMessage id="Updating the terms will require all users to accept the new terms" />
      </p>
    </Modal>
  );
}
