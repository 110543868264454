import { ClimateType } from 'common/types';
import { FormattedMessage } from 'translations/Intl';
import { formattedDistance } from 'utils/date';
import renderSensorIcon from 'utils/renderSensorIcon';

interface SensorContentProps {
  name: ClimateType;
  valueRating: string;
  value: number;
  unit: string;
  updatedAt?: string | undefined;
}

export default function SensorContent({
  name,
  value,
  valueRating,
  unit,
  updatedAt,
}: SensorContentProps) {
  const getSensibleRange = () => {
    if (valueRating === 'YELLOW') {
      return 'text-yellow-500';
    }

    if (valueRating === 'RED') {
      return 'text-red-500';
    }

    return 'dark:text-white';
  };

  const getValueForSensor = (): number => {
    if (name === ClimateType.TEMPERATURE) {
      return Number.parseFloat(value.toFixed(2));
    }
    return value;
  };

  return (
    <div
      key={name}
      className={`${getSensibleRange()} fill-current flex items-center py-1`}
    >
      <div>{renderSensorIcon(name, 'size-5 ml-2 mr-3')}</div>
      <div className="flex flex-col">
        <div className="flex items-center">
          <div className="font-bold mr-1">
            <FormattedMessage id={name} />
          </div>
          <div>{`${getValueForSensor()} ${unit} `}</div>
        </div>
        {updatedAt && (
          <div className="flex items-center text-sm">
            ({formattedDistance(new Date(updatedAt))})
          </div>
        )}
      </div>
    </div>
  );
}
