import { FormattedMessage } from 'translations/Intl';

export default function Skeleton({ loading }: { loading: boolean }) {
  return loading ? (
    <div className="w-full animate-pulse flex flex-col space-y-2 group-hover:bg-neutral-50 dark:group-hover:bg-neutral-700">
      <div className="flex flex-col space-y-4">
        <div className="flex space-x-4">
          <div className="w-32 bg-neutral-200 h-4 rounded-md" />
          <div className="w-4 bg-neutral-200 h-4 rounded-md" />
        </div>
        <div className="w-20 bg-neutral-200 h-4 rounded-md" />
        <div className="w-36 bg-neutral-200 h-4 rounded-md" />
        <div className="w-36 bg-neutral-200 h-4 rounded-md" />
        <div className="w-36 bg-neutral-200 h-4 rounded-md" />
      </div>
      <div className="flex flex-col md:flex-row space-y-2 space-x-0 md:space-x-2 md:space-y-0 justify-between">
        <div className="w-full md:w-96 bg-neutral-200 h-96 rounded-md" />
        <div className="w-full md:max-w-3xl bg-neutral-200 h-32 md:h-96 rounded-md" />
      </div>
    </div>
  ) : (
    <div className="text-xl">
      <FormattedMessage id="No defective beacons detected" />
    </div>
  );
}
