/* eslint-disable prefer-object-spread */
import de from './de.json';
import en from './en.json';
import errorsDe from './errors/de.json';
import errorsEn from './errors/en.json';

const translations = {
  en: Object.assign({}, en, errorsEn),
  de: Object.assign({}, de, errorsDe),
};

export default translations as any;
