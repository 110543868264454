import Subtitle from 'generic/components/Subtitle/Subtitle';
import { FormattedMessage, useIntl } from 'translations/Intl';

import { Action } from 'mda2-frontend/src/common/types';
import Modal from 'mda2-frontend/src/generic/components/Modal';
import ModalFooter from 'mda2-frontend/src/generic/components/ModalFooter';

interface PrivateBeaconModalProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export default function PrivateBeaconModal({
  open,
  onConfirm,
  onCancel,
}: PrivateBeaconModalProps): JSX.Element {
  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage({
        id: 'Confirm action',
      })}
      setShowModal={onCancel}
      action={Action.REMOVE}
      open={open}
      footer={
        <ModalFooter
          action={Action.REMOVE}
          proceed={intl.formatMessage({
            id: 'Confirm',
          })}
          onProceed={onConfirm}
          onCancel={onCancel}
        />
      }
    >
      <Subtitle
        value={intl.formatMessage({
          id: 'Move beacon outside of a private room warning',
        })}
        className="text-base pb-2"
      />
      <p
        data-test-id="mqttbeacon-sensor-history-warning"
        className="text-base pb-2"
      >
        <FormattedMessage id="The entire history of the beacon will be irrevocably deleted when saved" />
      </p>
    </Modal>
  );
}
