import { FormattedMessage } from 'translations/Intl';

import StyledButton from 'mda2-frontend/src/generic/components/Form/Button/StyledButton';
import { lower, upper } from 'mda2-frontend/src/utils/date';

interface CleaningActionButtonProps {
  cleaningDuration?: string | null;
  onClick: () => void;
}

export const isBeingCleaned = (cleaningDuration?: string | null): boolean => {
  const now = new Date();
  if (!cleaningDuration) {
    return false;
  }
  return lower(cleaningDuration) <= now && now < upper(cleaningDuration);
};

export default function CleaningActionButton({
  cleaningDuration,
  onClick,
}: CleaningActionButtonProps): JSX.Element {
  const isCleaning = isBeingCleaned(cleaningDuration);
  return (
    <StyledButton onClick={() => onClick()} id="action-cleaning">
      <FormattedMessage
        id={isCleaning ? 'Deactivate cleaning mode' : 'Activate cleaning mode'}
      />
    </StyledButton>
  );
}
