import Pill from 'generic/components/Pill/Pill';
import Tooltip from 'generic/components/Tooltip';
import { useSetLatestFirmwarePackageMutation } from 'graphql/types';
import { FormattedMessage } from 'translations/Intl';

import type { Row } from '@tanstack/react-table';
import PrivateWrapper from 'mda2-frontend/src/generic/components/PrivateWrapper';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import { HiOutlinePencil } from 'react-icons/hi2';

import type { Firmwares } from '../AddFirmwaresModal/AddFirmwaresModal';

export function ModulesCell({ row }: { row: Row<Firmwares> }) {
  return (
    <div className="flex flex-col space-y-2">
      {row.original.FirmwarePackageFirmwares.map((o) => (
        <Pill key={o.Firmware.Id}>
          {o.Firmware.Module.Name}: {o.Firmware.Version}
        </Pill>
      ))}
    </div>
  );
}

export function OrganizationsCell({ row }: { row: Row<Firmwares> }) {
  const [, setLatestFirmware] = useSetLatestFirmwarePackageMutation();
  const hasuraHeader = useHasuraHeader();

  return (
    <div className="flex flex-col space-y-2">
      {row.original.FirmwarePackageOrganizations.map((o) =>
        o.Latest ? (
          <Tooltip
            key={o.Organization.Id}
            content={
              <Pill
                className="!bg-green-200 !text-green-500"
                key={o.Organization.Id}
              >
                {o.Organization.Name}
              </Pill>
            }
          >
            <FormattedMessage id="Latest version" />
          </Tooltip>
        ) : (
          <Tooltip
            key={o.Organization.Id}
            content={
              <Pill
                setVisible={() =>
                  setLatestFirmware(
                    {
                      OrganizationId: o.Organization.Id,
                      FirmwarePackageId: row.original.Id,
                    },
                    hasuraHeader(HasuraPermissions.READ_ALL),
                  )
                }
              >
                {o.Organization.Name}
              </Pill>
            }
          >
            <FormattedMessage id="Set latest version" />
          </Tooltip>
        ),
      )}
    </div>
  );
}

export function ActionCell({
  row,
  setSelectedFirmwarePackage,
  setOpenAddFirmwareModal,
}: {
  row: Row<Firmwares>;
  setSelectedFirmwarePackage: (firmware: Firmwares | undefined) => void;
  setOpenAddFirmwareModal: (open: boolean) => void;
}) {
  return (
    <div className="flex space-x-2 space-x-reverse flex-row-reverse items-center">
      <PrivateWrapper roleRequired={HasuraPermissions.READ_ALL}>
        <Tooltip
          content={
            <p>
              <HiOutlinePencil
                className="size-5 cursor-pointer text-primary-500 hover:text-primary-700"
                onClick={() => {
                  setSelectedFirmwarePackage(row.original);
                  setOpenAddFirmwareModal(true);
                }}
              />
            </p>
          }
        >
          <FormattedMessage id="Edit" />
        </Tooltip>
      </PrivateWrapper>
    </div>
  );
}
