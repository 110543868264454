import Accordion from 'generic/components/Accordion';
import format from 'utils/format';
import TimeLineEntry, { type TimelineEntry } from './components/TimeLineEntry';
import TimeLineTitle from './components/TimeLineTitle';

interface TimeLineProps {
  loading: boolean;
  data: {
    now: boolean;
    timestamp: string;
    entries: TimelineEntry[];
  }[];
  setSelectedPosition: (selectedPosition: number | undefined) => void;
}

export default function TimeLine({
  data,
  setSelectedPosition,
  loading,
}: TimeLineProps) {
  return (
    <div className="flex flex-col space-y-4">
      {loading
        ? Array.from({ length: 5 }).map((_, i) => (
            <div
              key={i}
              className="flex p-5 border border-neutral-100 rounded-lg bg-neutral-50 dark:bg-neutral-800 dark:border-neutral-700"
            >
              <div className="animate-pulse bg-neutral-100 dark:bg-neutral-700 size-12 mb-3 me-3 rounded-full sm:mb-0" />
              <div className="space-y-2">
                <div className="animate-pulse bg-neutral-100 dark:bg-neutral-700 h-8 w-36" />
                <div className="animate-pulse bg-neutral-100 dark:bg-neutral-700 h-5 w-24" />
              </div>
            </div>
          ))
        : data.map(({ timestamp, entries, now }) => (
            <div
              key={timestamp}
              className="p-5 border border-neutral-100 rounded-lg bg-neutral-50 dark:bg-neutral-800 dark:border-neutral-700"
            >
              <Accordion
                initialStateOpen={now}
                sticky
                title={
                  <TimeLineTitle>
                    {now ? timestamp : format(new Date(timestamp))}
                  </TimeLineTitle>
                }
              >
                <ol
                  className="mt-3 divide-y divider-neutral-200 dark:divide-neutral-700"
                  data-test-id={now ? timestamp : format(new Date(timestamp))}
                >
                  {entries.map(({ id, title, subtitle, icon, active }) => (
                    <TimeLineEntry
                      key={id}
                      icon={icon}
                      title={title}
                      active={active}
                      subtitle={subtitle}
                      onMouseEnter={() => setSelectedPosition(id)}
                      onMouseLeave={() => setSelectedPosition(undefined)}
                    />
                  ))}
                </ol>
              </Accordion>
            </div>
          ))}
    </div>
  );
}
