import Loader from 'mda2-frontend/src/generic/components/layout/BarLoader';
import {
  type BuildingPartsFragment,
  type FloorPartsFragment,
  type LabelPartsFragment,
  type RoomPartsFragment,
  useBuildingsTopologyQuery,
} from 'mda2-frontend/src/graphql/types';
import useStore from 'mda2-frontend/src/model/store';
import { useMemo, useState } from 'react';

import BuildingSelect from './BuildingSelect';
import FloorSelect from './FloorSelect';
import LabelSelect from './LabelSelect';
import MapHighlight from './MapHighlight/MapHighlight';
import RoomSelect from './RoomSelect';

interface LocationSelectProps {
  hasRoomFilter?: boolean;
  hasFloorFilter?: boolean;
  isDeselectable?: boolean;
  roomFilter?: (r: RoomPartsFragment) => boolean;
  buildingName?: string | null;
  floorNumber?: number | null;
  roomName?: string | null;
  labelNames?: string[] | null;
  onBuildingSelect?: (b: BuildingPartsFragment | null) => void;
  onFloorSelect?: (f: FloorPartsFragment | null) => void;
  onRoomSelect?: (r: RoomPartsFragment | null) => void;
  onLabelSelect?: (l: LabelPartsFragment | LabelPartsFragment[] | null) => void;
}

export default function LocationSelect({
  hasRoomFilter = false,
  hasFloorFilter = true,
  isDeselectable = false,
  roomFilter = () => true,
  buildingName,
  floorNumber,
  roomName,
  labelNames,
  onBuildingSelect,
  onFloorSelect,
  onRoomSelect,
  onLabelSelect,
}: LocationSelectProps): JSX.Element {
  const [hoveredRoomId, setHoveredRoomId] = useState<number | undefined>();

  const building = useStore((state) => state.userSettings.building);
  const floor = useStore((state) => state.userSettings.floor);
  const [{ data, fetching }] = useBuildingsTopologyQuery();

  const buildings = useMemo(
    () => data?.Buildings.slice().sort((a, b) => a.Name.localeCompare(b.Name)),
    [data?.Buildings],
  );

  const floors = useMemo(
    () =>
      buildings
        ?.find((b) =>
          buildingName !== undefined
            ? buildingName === b.Name
            : b.Id === building?.Id,
        )
        ?.Floors.slice()
        .sort((a, b) => a.Number - b.Number),
    [buildings, buildingName, building?.Id],
  );

  const rooms = useMemo(
    () =>
      floors
        ?.find((f) =>
          floorNumber !== undefined
            ? f.Number === floorNumber
            : f.Id === floor?.Id,
        )
        ?.Rooms.slice()
        .sort((a, b) => a.Name.localeCompare(b.Name))
        .filter(roomFilter),
    [floor?.Id, floorNumber, floors, roomFilter],
  );

  return (
    <div className="flex flex-col space-y-2">
      {hasRoomFilter && rooms && floors?.[0] && (
        <div className="relative">
          <MapHighlight
            floor={
              floors.find((f) => f.Number === floorNumber) || floor || floors[0]
            }
            onRoomSelect={onRoomSelect}
            rooms={rooms}
            hoveredRoomId={hoveredRoomId}
          />
        </div>
      )}
      <div className="z-20 flex space-x-2">
        <Loader loading={fetching} />
        <BuildingSelect
          onBuildingSelect={onBuildingSelect}
          buildings={buildings}
          isDeselectable={isDeselectable}
          buildingName={buildingName}
        />
        {(floors?.length ?? 0) > 0 && hasFloorFilter && (
          <FloorSelect
            onFloorSelect={onFloorSelect}
            floors={floors}
            isDeselectable={isDeselectable}
            floorNumber={floorNumber}
          />
        )}
        {hasRoomFilter && (rooms?.length ?? 0) > 0 && (
          <RoomSelect
            onRoomSelect={onRoomSelect}
            rooms={rooms}
            isDeselectable={isDeselectable}
            roomName={roomName}
            onOptionHover={(option, isEntering) => {
              if (isEntering) {
                setHoveredRoomId(option?.Id);
              } else {
                setHoveredRoomId(undefined);
              }
            }}
          />
        )}
        {hasRoomFilter && (
          <LabelSelect
            isDeselectable={isDeselectable}
            onLabelSelect={onLabelSelect}
            labelNames={labelNames}
          />
        )}
      </div>
    </div>
  );
}
