import type BaseLayer from 'generic/layers/BaseLayer';
import type { HoveredFeature } from 'mda2-frontend/src/common/types';
import { BASE_LAYER_NAME } from 'mda2-frontend/src/constants';
import BasicMap from 'mda2-frontend/src/generic/components/BasicMap';
import CanvasExportButton from 'mda2-frontend/src/generic/components/CanvasExportButton';
import ZoomButtons from 'mda2-frontend/src/generic/components/ZoomButtons';
import type Layer from 'mda2-frontend/src/generic/layers/Layer';
import useStore from 'model/store';
import type OLMap from 'ol/Map';
import type MapBrowserEvent from 'ol/MapBrowserEvent';
import { unByKey } from 'ol/Observable';
import type { Color } from 'ol/color';
import type { Coordinate } from 'ol/coordinate';
import type { EventsKey } from 'ol/events';
import { getCenter } from 'ol/extent';
import type { Point } from 'ol/geom';
import { fromExtent } from 'ol/geom/Polygon';
import { Projection } from 'ol/proj';
import { animateBeacon } from 'pages/AdminView/BuildingView/components/FloorList/components/FloorRoomView/components/FloorRoomMap/utils/helpers';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import LoadingSpinner from '../LoadingSpinner';
import Popup from '../Popup';
import Transition from '../Transition';

interface MapWrapperProps<T> {
  layers?: Layer[];
  map: OLMap;
  onFeaturesHover?: (
    hoveredFeatures: HoveredFeature<T>[],
    evt: MapBrowserEvent<PointerEvent>,
  ) => void;
  onFeaturesClick?: (features: T[], evt: MapBrowserEvent<PointerEvent>) => void;
  className?: string;
  isLoadingFeatures?: boolean;
  enableCanvasExport?: boolean;
  canvasExportName?: string;
  enableZoomButton?: boolean;
  highlightedFeatures?: {
    features?: {
      geometry: Point;
      radius?: number;
      color: Color;
    }[];
    layer: Layer;
  };
  additionalActions?: JSX.Element;
  renderTooltip?: (props: {
    top: number;
    left: number;
    className: string;
    'data-test-id': string;
  }) => JSX.Element | undefined;
}

const animationDuration = 3000;

export default function Map<T>({
  map,
  layers = [],
  onFeaturesHover,
  onFeaturesClick,
  className = 'map-height',
  isLoadingFeatures = false,
  enableCanvasExport = false,
  canvasExportName,
  enableZoomButton = true,
  highlightedFeatures,
  additionalActions,
  renderTooltip,
}: MapWrapperProps<T>) {
  const mapRef = useRef(null);
  let timeoutId: NodeJS.Timeout | undefined;
  const isFullScreenOn = useStore((state) => state.isFullScreenOn);
  const listenerRef = useRef<EventsKey>();
  const [hoveredCoords, setHoveredCoords] = useState<Coordinate | undefined>(
    undefined,
  );
  const [extent, setExtent] = useState([0, 0, 0, 0]);
  const [center, setCenter] = useState(getCenter(extent));
  const [zoom, setZoom] = useState(1);
  const [projection, setProjection] = useState(
    new Projection({
      code: 'static-image',
      units: 'pixels',
      extent,
    }),
  );
  const eventsKeys: EventsKey[] = useMemo(() => [], []);

  const setMapInfos = useCallback(
    (imageLayer: BaseLayer) => {
      const newExtent = imageLayer.extent;
      setExtent(newExtent);
      setProjection(imageLayer.projection);
      setCenter(getCenter(newExtent));
      // Sometimes if the window size is quickly changed it wouldn't fit correctly
      timeoutId = setTimeout(() => {
        map.getView().fit(fromExtent(newExtent));
      });
    },
    [map, timeoutId],
  );

  useEffect(() => {
    const imageLayer = layers.find(
      (l) => l.name === BASE_LAYER_NAME,
    ) as BaseLayer;
    if (imageLayer?.extent) {
      setMapInfos(imageLayer);
    }
    return () => {
      unByKey(eventsKeys);
      clearTimeout(timeoutId);
    };
  }, [eventsKeys, layers, setMapInfos, timeoutId]);

  useEffect(() => {
    unByKey(eventsKeys);
    const imageLayer = layers.find((l) => l.name === BASE_LAYER_NAME) as
      | BaseLayer
      | undefined;
    if (imageLayer) {
      const imageLayerLoad = imageLayer.olLayer.on('change:source', () =>
        setMapInfos(imageLayer),
      );
      eventsKeys.push(imageLayerLoad);
      window.removeEventListener('resize', () => setMapInfos(imageLayer));
      window.addEventListener('resize', () => setMapInfos(imageLayer));
    }

    return () => {
      if (imageLayer) {
        window.removeEventListener('resize', () => setMapInfos(imageLayer));
      }
    };
  }, [eventsKeys, layers, setMapInfos]);

  useEffect(() => {
    const animateBeaconFunction = () => {
      if (listenerRef.current) {
        unByKey(listenerRef.current);
      }
      const start = Date.now();
      const listenerKey = highlightedFeatures?.layer.olLayer.on(
        'postrender',
        (event) => {
          if (highlightedFeatures?.features) {
            for (const {
              geometry,
              radius,
              color,
            } of highlightedFeatures.features) {
              if (geometry) {
                animateBeacon(
                  event,
                  map,
                  geometry,
                  start,
                  animationDuration,
                  color,
                  radius,
                );
              }
            }
          }
        },
      );
      listenerRef.current = listenerKey;
    };

    // Animate it immediately
    animateBeaconFunction();

    // Keep animating it in an interval
    const interval = setInterval(
      animateBeaconFunction,
      animationDuration + 1000,
    );

    return () => {
      clearInterval(interval);

      if (listenerRef.current) {
        unByKey(listenerRef.current);
      }
    };
  }, [
    highlightedFeatures?.features,
    highlightedFeatures?.layer.olLayer.on,
    map,
  ]);

  const setCoordinates = useCallback(
    (evt: MapBrowserEvent<PointerEvent>, feats?: T[] | HoveredFeature<T>[]) => {
      if (feats?.length) {
        setHoveredCoords(evt.coordinate);
      } else {
        setHoveredCoords(undefined);
      }
    },
    [],
  );

  return (
    <div
      className={`z-10 w-full relative ${
        isFullScreenOn ? 'h-[90vh]' : className
      }`}
      ref={mapRef}
      data-test-id="map"
    >
      <BasicMap<T>
        map={map}
        layers={layers}
        viewOptions={{
          minZoom: -1,
          maxZoom: 5,
          projection,
        }}
        disableZoom={!enableZoomButton}
        extent={extent}
        zoom={zoom}
        center={center}
        onMapMoved={() => {
          setCenter(map.getView().getCenter() ?? center);
          setZoom(
            Number.isNaN(map.getView().getZoom())
              ? zoom
              : (map.getView().getZoom() ?? zoom),
          );
        }}
        onFeaturesHover={(feats, evt) => {
          onFeaturesHover?.(feats, evt);
          setCoordinates(evt, feats);
        }}
        onFeaturesClick={(feats, evt) => {
          onFeaturesClick?.(feats, evt);
          setCoordinates(evt, feats);
        }}
      />
      <LoadingSpinner loading={isLoadingFeatures} />
      <div className="absolute top-0 left-0 flex flex-col gap-2 p-1">
        <Transition show={enableZoomButton}>
          <ZoomButtons map={map} zoom={zoom} />
        </Transition>
        <Transition show={enableCanvasExport}>
          <CanvasExportButton map={map} zoom={zoom} name={canvasExportName} />
        </Transition>
        <Transition show={!!additionalActions}>{additionalActions}</Transition>
      </div>
      {hoveredCoords && (
        <Popup
          map={map}
          popupCoordinate={hoveredCoords}
          renderTooltip={renderTooltip}
        />
      )}
    </div>
  );
}
