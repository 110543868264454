import GridRows from '@visx/grid/lib/grids/GridRows';
import { Group } from '@visx/group';
import { PatternLines } from '@visx/pattern';
import { ParentSize } from '@visx/responsive';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { BarStack } from '@visx/shape';
import type { SeriesPoint } from '@visx/shape/lib/types';
import { defaultStyles, useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { addDays, differenceInDays } from 'date-fns';
import Card from 'generic/components/Card';
import AnimatedRect from 'generic/components/Chart/AnimatedRect';
import {
  type MarginProps,
  RoomTypes,
  Themes,
} from 'mda2-frontend/src/common/types';
import Axis from 'mda2-frontend/src/generic/components/Chart/Axis';
import Legend from 'mda2-frontend/src/generic/components/Chart/Legend';
import LoadingSpinner from 'mda2-frontend/src/generic/components/LoadingSpinner';
import { useCleaningOccupancyDailyQuery } from 'mda2-frontend/src/graphql/types';
import useStore from 'mda2-frontend/src/model/store';
import localize from 'mda2-frontend/src/utils/format';
import getColor, { primaryColorToRGB } from 'mda2-frontend/src/utils/getColor';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import useRoomDeskFilter from 'mda2-frontend/src/utils/graphql/useRoomDeskFilter';
import { useEffect, useMemo, useState } from 'react';
import {
  FormattedMessage,
  type IntlMessageKeys,
  useIntl,
} from 'translations/Intl';
import useAnalyticsFilter from 'utils/graphql/useAnalyticsFilter';

interface ResponsiveCleaningChartProps {
  margin?: MarginProps;
}

interface CleaningChartProps extends ResponsiveCleaningChartProps {
  height: number;
  width: number;
}

interface ChartDateProps {
  date: Date;
  check: number;
  toClean: number;
  checkRooms: number;
  toCleanRooms: number;
}

const deskOrMeetingRoom = (items: number, isRoom: boolean): JSX.Element => {
  if (items === 1) {
    if (isRoom) {
      return <FormattedMessage id="{room} room" values={{ room: items }} />;
    }
    return <FormattedMessage id="{desk} desk" values={{ desk: items }} />;
  }
  if (isRoom) {
    return <FormattedMessage id="{rooms} rooms" values={{ rooms: items }} />;
  }
  return <FormattedMessage id="{desks} desks" values={{ desks: items }} />;
};

// accessors
const getDate = (d: ChartDateProps) => d.date.toString();

let tooltipTimeout: NodeJS.Timeout;

type State = 'toClean' | 'check' | 'toCleanRooms' | 'checkRooms';

type TooltipData = {
  bar: SeriesPoint<ChartDateProps>;
  key: State;
  index: number;
  height: number;
  width: number;
  x: number;
  y: number;
  color: string;
};

function CleaningChart({
  height,
  width,
  margin = {
    // 768 -> MD breakpoint
    top: width > 768 ? 80 : 100,
    left: 70,
    right: 70,
    bottom: 60,
  },
}: CleaningChartProps) {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const dateRange = useStore((state) => state.userSettings.dateRange);
  const dateFrom = useMemo(() => new Date(dateRange.start), [dateRange.start]);
  const dateTo = useMemo(
    () => (dateRange.end ? new Date(dateRange.end) : null),
    [dateRange.end],
  );
  const cleaningTimes = useStore(
    (state) => state.organizationSettings.cleaningTimes,
  );
  const { check: timeBeforeCheck, clean: timeBeforeClean } = cleaningTimes;
  const [toCleanColor] = useState(primaryColorToRGB(600));
  const [toCheckColor] = useState(primaryColorToRGB(400));
  const [numTicks] = useState(4);
  const [colors] = useState([toCleanColor, toCheckColor]);
  const roomTypes = useStore((state) => state.userSettings.roomTypes);
  const [generatedDays] = useState(
    Array.from(Array(29)).map((_, i) => ({
      date: new Date(addDays(dateFrom, i)),
      check: 0,
      toClean: 0,
      checkRooms: 0,
      toCleanRooms: 0,
    })),
  );

  const theme = useStore((state) => state.userSettings.theme);

  // Just add some days so it doesn't look empty when there is no data yet
  const [data, setData] = useState<ChartDateProps[]>(generatedDays);

  const [{ data: cleaningData, fetching: loading }] =
    useCleaningOccupancyDailyQuery({
      variables: {
        ...useAnalyticsFilter(),
        ...useRoomDeskFilter(),
        TimeBeforeCheck: timeBeforeCheck,
        TimeBeforeClean: timeBeforeClean,
      },
      context: useMemo(
        () => hasuraHeader(HasuraPermissions.VIEW_CLEANING),
        [hasuraHeader],
      ),
      pause:
        !roomTypes.includes(RoomTypes.DESKS) &&
        !roomTypes.includes(RoomTypes.MEETING),
    });

  const numberOfDays = useMemo(
    () => dateTo && differenceInDays(dateTo, dateFrom) + 1,
    [dateTo, dateFrom],
  );

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
  });

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<TooltipData>();

  useEffect(() => {
    if (
      (cleaningData?.f_history_desks_cleaning_daily ||
        cleaningData?.f_history_rooms_cleaning_daily) &&
      (numberOfDays ?? 0) > 0
    ) {
      const newData = Array.from(Array(numberOfDays)).map((_, day) => ({
        date: new Date(addDays(dateFrom, day)),
        check:
          cleaningData.f_history_desks_cleaning_daily?.find(
            (entry) =>
              new Date(entry.Date).toDateString() ===
              addDays(dateFrom, day).toDateString(),
          )?.ToCheck || 0,
        toClean:
          cleaningData.f_history_desks_cleaning_daily?.find(
            (entry) =>
              new Date(entry.Date).toDateString() ===
                addDays(dateFrom, day).toDateString() && entry.ToClean,
          )?.ToClean || 0,
        checkRooms:
          cleaningData.f_history_rooms_cleaning_daily?.find(
            (entry) =>
              new Date(entry.Date).toDateString() ===
                addDays(dateFrom, day).toDateString() && entry.ToCheck,
          )?.ToCheck || 0,
        toCleanRooms:
          cleaningData.f_history_rooms_cleaning_daily?.find(
            (entry) =>
              new Date(entry.Date).toDateString() ===
                addDays(dateFrom, day).toDateString() && entry.ToClean,
          )?.ToClean || 0,
      }));

      setData(newData);
    } else {
      setData(generatedDays);
    }
  }, [cleaningData, dateFrom, numberOfDays, generatedDays]);

  const keys = useMemo(() => {
    if (
      roomTypes.includes(RoomTypes.DESKS) &&
      roomTypes.includes(RoomTypes.MEETING)
    ) {
      return ['toClean', 'toCleanRooms', 'check', 'checkRooms'] as State[];
    }
    // Only desk occupancy
    if (roomTypes.includes(RoomTypes.DESKS)) {
      return ['toClean', 'check'] as State[];
    }
    // Only meeting room occupancy
    return ['toCleanRooms', 'checkRooms'] as State[];
  }, [roomTypes]);

  const axisText = useMemo(() => {
    if (
      roomTypes.includes(RoomTypes.DESKS) &&
      roomTypes.includes(RoomTypes.MEETING)
    ) {
      return 'Number of desks & rooms';
    }
    // Only desk occupancy
    if (roomTypes.includes(RoomTypes.DESKS)) {
      return 'Number of desks';
    }
    // Only meeting room occupancy
    return 'Number of meeting rooms';
  }, [roomTypes]);

  const entries = useMemo(() => {
    // Meeting room and desk occupancy
    if (
      roomTypes.includes(RoomTypes.DESKS) &&
      roomTypes.includes(RoomTypes.MEETING)
    ) {
      return Math.max(
        ...data.flatMap(
          (d) => d.check + d.toClean + d.checkRooms + d.toCleanRooms,
        ),
      );
    }
    // Only desk occupancy
    if (roomTypes.includes(RoomTypes.DESKS)) {
      return Math.max(...data.flatMap((d) => d.check + d.toClean));
    }
    // Only meeting room occupancy
    return Math.max(...data.flatMap((d) => d.checkRooms + d.toCleanRooms));
  }, [data, roomTypes]);

  const colorRange = useMemo(() => {
    // Meeting room and desk occupancy
    if (
      roomTypes.includes(RoomTypes.DESKS) &&
      roomTypes.includes(RoomTypes.MEETING)
    ) {
      return [toCleanColor, toCleanColor, toCheckColor, toCheckColor];
    }
    return [toCleanColor, toCheckColor];
  }, [roomTypes, toCheckColor, toCleanColor]);

  const datesRange = useMemo(() => data.map((d) => d.date.toString()), [data]);

  // bounds
  const xMax = Math.max(width - margin.left - margin.right, 0);
  const yMax = height - margin.top - margin.bottom;

  // scales
  const xScale = scaleBand<string>({
    range: [0, xMax],
    domain: datesRange,
    paddingInner: 0.2,
  });

  const yScale = scaleLinear<number>({
    range: [yMax, 0],
    domain: [0, entries],
    nice: true,
  });

  const colorScale = scaleOrdinal({
    domain: keys,
    range: colorRange,
  });

  const getBarFill = (bar: TooltipData) => {
    if (bar.key === 'check' || bar.key === 'toClean') {
      return bar.color;
    }
    if (bar.key === 'checkRooms') {
      return 'url(#CheckPattern)';
    }

    return 'url(#CleanPattern)';
  };

  const cardTitle = useMemo(() => {
    if (
      roomTypes.includes(RoomTypes.MEETING) &&
      !roomTypes.includes(RoomTypes.DESKS)
    ) {
      return '[Rooms]';
    }
    if (
      !roomTypes.includes(RoomTypes.MEETING) &&
      roomTypes.includes(RoomTypes.DESKS)
    ) {
      return '[Desks]';
    }
    if (
      roomTypes.includes(RoomTypes.MEETING) &&
      roomTypes.includes(RoomTypes.DESKS)
    ) {
      return '[Rooms/Desks]';
    }
    return '';
  }, [roomTypes]);

  return (
    <Card
      noPadding
      className="h-96 relative"
      title={`${cardTitle} Cleaning` as IntlMessageKeys}
    >
      <LoadingSpinner loading={loading} />
      <div className="relative" data-test-id="cleaning-chart">
        <svg ref={containerRef} width={width} height={height}>
          <PatternLines
            id="CleanPattern"
            height={16}
            width={16}
            stroke="white"
            background={toCleanColor}
            strokeWidth={2}
            orientation={['diagonal']}
          />
          <PatternLines
            id="CheckPattern"
            height={16}
            width={16}
            stroke="white"
            background={toCheckColor}
            strokeWidth={2}
            orientation={['diagonal']}
          />
          <Group top={margin.top} left={margin.left}>
            <GridRows
              numTicks={numTicks}
              scale={yScale}
              width={xMax}
              height={yMax}
              strokeDasharray="1,3"
              stroke={getColor('NEUTRAL600')}
              strokeOpacity={0.6}
            />
            <BarStack
              data={data}
              keys={keys}
              x={getDate}
              xScale={xScale}
              yScale={yScale}
              color={colorScale}
            >
              {(barStacks) =>
                barStacks.map((barStack) =>
                  barStack.bars.map((bar) => (
                    <AnimatedRect
                      bar={{ ...bar, color: getBarFill(bar) }}
                      onMouseMove={(event) => {
                        if (tooltipTimeout) clearTimeout(tooltipTimeout);
                        const left = bar.x + bar.width / 2;
                        showTooltip({
                          tooltipData: bar,
                          tooltipTop: event?.y,
                          tooltipLeft: left,
                        });
                      }}
                      onMouseOut={() => {
                        tooltipTimeout = setTimeout(() => {
                          hideTooltip();
                        }, 300);
                      }}
                      dataTestId={
                        ['toCleanRooms', 'checkRooms'].includes(bar.key)
                          ? `cleaning-rooms-${barStack.index}-${bar.index}`
                          : `cleaning-desks-${barStack.index}-${bar.index}`
                      }
                      key={`bar-stack-${barStack.index}-${bar.index}`}
                    />
                  )),
                )
              }
            </BarStack>
            <Axis
              lowLevelChart
              top={yMax}
              scale={xScale}
              numTicks={width > 768 ? 10 : 2}
              tickFormat={(v: string) => localize(new Date(v), 'eeeeee do LLL')}
              orientation="bottom"
            />
            <Axis
              orientation="left"
              lowLevelChart
              numTicks={numTicks}
              tickFormat={(v: string) => (Number.isInteger(v) ? v : '')}
              scale={yScale}
              label={intl.formatMessage({ id: axisText })}
            />
          </Group>
        </svg>
        <div className="flex items-center justify-evenly absolute top-8 w-full space-y-2">
          <div className="flex">
            <Legend
              scaleType="ordinal"
              labelFormat={(d) =>
                intl.formatMessage({
                  id: d,
                })
              }
              scale={scaleOrdinal({
                domain: ['toClean', 'check'],
                range: colors,
              })}
            />
          </div>
        </div>
        {tooltipOpen && tooltipData && (
          <TooltipInPortal
            top={tooltipTop}
            left={tooltipLeft}
            style={{
              ...defaultStyles,
              background:
                theme.color === Themes.LIGHT
                  ? getColor('WHITE')
                  : getColor('NEUTRAL900'),
            }}
          >
            <div style={{ color: colorScale(tooltipData.key) }}>
              <strong>
                <FormattedMessage
                  id={tooltipData.key.replace('Rooms', '') as IntlMessageKeys}
                />
              </strong>
            </div>
            <div
              className="dark:text-neutral-200"
              data-test-id="cleaning-chart-tooltip"
            >
              {deskOrMeetingRoom(
                tooltipData.bar.data[tooltipData.key],
                !['toClean', 'check'].includes(tooltipData.key),
              )}
            </div>
            <div className="dark:text-neutral-200">
              <small>{localize(new Date(tooltipData.bar.data.date))}</small>
            </div>
          </TooltipInPortal>
        )}
      </div>
    </Card>
  );
}

export default function ResponsiveCleaningChart(
  props: ResponsiveCleaningChartProps,
) {
  return (
    <ParentSize>
      {({ height, width }) => (
        <CleaningChart {...props} width={width} height={height} />
      )}
    </ParentSize>
  );
}
