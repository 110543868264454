import type Feature from 'ol/Feature';
import type MapBrowserEvent from 'ol/MapBrowserEvent';
import Event from 'ol/events/Event';
import type Geometry from 'ol/geom/Geometry';

export const RotateEventType = {
  START: 'rotatestart',
  END: 'rotateend',
};

export default class RotateEvent extends Event {
  feature: Feature<Geometry>;

  mapBrowserEvent: MapBrowserEvent<PointerEvent>;

  rotation?: number;

  constructor(
    type: string,
    feature: Feature<Geometry>,
    mapBrowserPointerEvent: MapBrowserEvent<PointerEvent>,
    rotation?: number,
  ) {
    super(type);
    this.feature = feature;
    this.mapBrowserEvent = mapBrowserPointerEvent;
    this.rotation = rotation;
  }
}
