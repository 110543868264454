import * as React from "react";
const SvgSmartmoduleX = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 34.02 34.02", ...props }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("style", null, ".cls-4-s-x{fill:none;stroke-linejoin:round;stroke:#fff;stroke-width:.11px}")), /* @__PURE__ */ React.createElement("g", { id: "Smartmodule_X", "data-name": "Smartmodule X" }, /* @__PURE__ */ React.createElement("path", { d: "M27.8 19.91c0 2.74-4.83 4.96-10.79 4.96S6.22 22.65 6.22 19.91v-.07c.08-2.71 4.88-4.89 10.79-4.89s10.71 2.19 10.79 4.89v.07Z", style: {
  stroke: "#181716",
  strokeWidth: ".09px",
  fill: "#181716",
  strokeLinejoin: "round"
} }), /* @__PURE__ */ React.createElement("path", { d: "m10.09 21.95 10.31-5.69 3.52 1.98-10.26 5.55-3.57-1.84z", className: "cls-4-s-x" }), /* @__PURE__ */ React.createElement("path", { d: "m9.57 19.85 1.89-1.02", style: {
  strokeWidth: ".11px",
  stroke: "#fff",
  strokeLinejoin: "round",
  fill: "none",
  strokeLinecap: "round"
} }), /* @__PURE__ */ React.createElement("ellipse", { cx: 14.84, cy: 16.99, rx: 0.64, ry: 0.36, style: {
  strokeWidth: ".07px",
  fill: "#fff",
  stroke: "#fff",
  strokeLinejoin: "round"
} }), /* @__PURE__ */ React.createElement("circle", { cx: 11.79, cy: 18.64, r: 0.06, style: {
  strokeWidth: ".11px",
  stroke: "#fff",
  strokeLinejoin: "round",
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M12.54 18.21s-.26-.08-.26-.39c0-.23.4-.42.89-.42s.89.19.89.42c0 .31-.26.39-.26.39", style: {
  fill: "#181716",
  strokeWidth: ".11px",
  stroke: "#fff",
  strokeLinejoin: "round"
} }), /* @__PURE__ */ React.createElement("ellipse", { cx: 13.16, cy: 18.21, className: "cls-4-s-x", rx: 0.62, ry: 0.29 }), /* @__PURE__ */ React.createElement("path", { d: "M6.22 19.84c.08-2.71 4.88-4.89 10.79-4.89s10.71 2.19 10.79 4.89l-.13-5.86c-.08-2.68-4.82-4.84-10.66-4.84S6.43 11.3 6.35 13.98l-.13 5.86Z", style: {
  fill: "#fff",
  stroke: "#181716",
  strokeWidth: ".09px",
  strokeLinejoin: "round"
} })), /* @__PURE__ */ React.createElement("path", { id: "Rahmen", d: "M0 0h34.02v34.02H0z", style: {
  fill: "none"
} }));
export default SvgSmartmoduleX;
