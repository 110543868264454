import { useMemo } from 'react';

export enum HasuraPermissions {
  WRITE_MQTTBEACON = 'write:MqttBeacon',
  WRITE_MQTTMAPPING = 'write:MqttMapping',
  WRITE_BUILDING = 'write:Building',
  DELETE_BUILDING = 'delete:Building',
  DELETE_MQTTBEACON = 'delete:MqttBeacon',
  DELETE_SENSOR = 'delete:Sensor',
  READ = 'read',
  READ_ALL = 'read:All',
  WRITE_CLEANING = 'write:Cleaning',
  WRITE_MQTTASSETTRACKER = 'write:MqttAssetTracker',
  WRITE_ORGANIZATION = 'write:Organization',
  DELETE_FLOOR = 'delete:Floor',
  WRITE_FLOOR = 'write:Floor',
  DELETE_ROOM = 'delete:Room',
  WRITE_ROOM = 'write:Room',
  WRITE_DESK = 'write:Desk',
  VIEW_ORGANIZATIONS = 'view:Organizations',
  VIEW_RESERVATION = 'view:Reservation',
  VIEW_FINDMYASSET = 'view:FindMyAsset',
  VIEW_FINDMYPLACE = 'view:FindMyPlace',
  VIEW_ANALYTICS = 'view:Analytics',
  VIEW_REPORTS = 'view:Reports',
  VIEW_CLIMATE = 'view:Climate',
  VIEW_CLEANING = 'view:Cleaning',
  VIEW_ADMIN = 'view:Admin',
  VIEW_STATUS = 'view:Status',
  VIEW_DASHBOARD = 'view:Dashboard',
  VIEW_USERGROUP = 'view:UserGroupManagement',
  FEATURE_PEOPLECOUNT = 'feature:PeopleCount',
  FEATURE_ROOMMODES = 'feature:RoomModes',
  FEATURE_SHARE = 'feature:Share',
  USER = 'user',
  VIEW_GRAPHQL = 'view:Graphql',
  VIEW_MQTT = 'view:Mqtt',
  MDA3 = 'view:MDA3',
}

export const structureRoles = [
  HasuraPermissions.DELETE_BUILDING,
  HasuraPermissions.DELETE_FLOOR,
  HasuraPermissions.WRITE_FLOOR,
  HasuraPermissions.DELETE_ROOM,
  HasuraPermissions.WRITE_ROOM,
  HasuraPermissions.WRITE_DESK,
  HasuraPermissions.WRITE_BUILDING,
];

export default function useHasuraHeader() {
  const generateHasuraHeader = (
    role: HasuraPermissions,
    additionalTypenames?: string[],
  ) => ({
    fetchOptions: {
      headers: {
        'x-hasura-role': role,
      },
    },
    additionalTypenames,
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: just on init
  return useMemo(() => generateHasuraHeader, []);
}
