import GridRows from '@visx/grid/lib/grids/GridRows';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { BarStack } from '@visx/shape';
import AnimatedRect from 'generic/components/Chart/AnimatedRect';
import type { MarginProps } from 'mda2-frontend/src/common/types';
import Axis from 'mda2-frontend/src/generic/components/Chart/Axis';
import Legend from 'mda2-frontend/src/generic/components/Chart/Legend';
import localize from 'mda2-frontend/src/utils/format';
import getColor from 'mda2-frontend/src/utils/getColor';
import { REPORTING_RIGHT_MARGIN } from 'pages/ReportingView/Reports/Reports';
import { useMemo } from 'react';
import { useIntl } from 'translations/Intl';

interface Data {
  date: Date;
  warmPercentage: number;
  hotPercentage: number;
}

interface ResponsiveBarChart {
  margin?: MarginProps;
  data: Data[];
}

interface BarChart extends ResponsiveBarChart {
  height: number;
  width: number;
}

// accessors
const getDate = (d: Data) => d.date.toString();

function BarChart({
  height,
  width,
  margin = {
    top: 60,
    left: 70,
    right: REPORTING_RIGHT_MARGIN,
    bottom: 40,
  },
  data,
}: BarChart) {
  const intl = useIntl();
  const datesRange = useMemo(() => data.map((d) => d.date.toString()), [data]);
  const keys: (keyof Data)[] = ['hotPercentage', 'warmPercentage'];

  // bounds
  const xMax = Math.max(width - margin.left - margin.right, 0);
  const yMax = height - margin.top - margin.bottom;

  // scales
  const xScale = scaleBand<string>({
    range: [0, xMax],
    domain: datesRange,
    paddingInner: 0.2,
  });

  const yScale = scaleLinear<number>({
    range: [yMax, 0],
    domain: [0, 100],
    nice: true,
  });

  const colorScale = scaleOrdinal({
    domain: keys,
    range: [getColor('RED'), getColor('YELLOW')],
  });

  return (
    <div className="relative">
      <svg width={width} height={height}>
        <Group top={margin.top} left={margin.left}>
          <GridRows
            numTicks={10}
            scale={yScale}
            width={xMax}
            height={yMax}
            strokeDasharray="1,3"
            stroke={getColor('NEUTRAL600')}
            strokeOpacity={0.6}
          />
          <BarStack
            data={data}
            keys={keys}
            x={getDate}
            xScale={xScale}
            yScale={yScale}
            color={colorScale}
          >
            {(barStacks) =>
              barStacks.map((barStack) =>
                barStack.bars.map((bar) => (
                  <AnimatedRect
                    bar={bar}
                    key={`bar-stack-${barStack.index}-${bar.index}`}
                  />
                )),
              )
            }
          </BarStack>
          <Axis
            lowLevelChart
            top={yMax}
            scale={xScale}
            numTicks={width > 768 ? 10 : 2}
            tickFormat={(v: string) => localize(new Date(v), 'eeeeee do LLL')}
            orientation="bottom"
          />
          <Axis
            orientation="left"
            lowLevelChart
            tickFormat={(y) => `${y}%`}
            scale={yScale}
            label={intl.formatMessage({ id: 'Occupancy' })}
          />
        </Group>
      </svg>
      <div className="flex items-center justify-evenly absolute top-8 w-full space-y-2">
        <div className="flex">
          <Legend
            scaleType="ordinal"
            labelFormat={(d) =>
              intl.formatMessage({
                id: d,
              })
            }
            scale={colorScale}
          />
        </div>
      </div>
    </div>
  );
}

export default function ResponsiveBarChart(props: ResponsiveBarChart) {
  return (
    <ParentSize>
      {({ height, width }) => (
        <BarChart {...props} width={width} height={height} />
      )}
    </ParentSize>
  );
}
