import Card from 'generic/components/Card';
import { useDesksUsageQuery } from 'graphql/types';
import { useIntl } from 'translations/Intl';
import useAnalyticsFilter from 'utils/graphql/useAnalyticsFilter';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import { uuidv4 } from 'utils/uuid';

import { useMemo, useState } from 'react';

import BarOccupancy from '../BarOccupancy';
import BrushChart from '../BrushCart/BrushChart';
import ChartComparer from '../ChartComparer/ChartComparer';
import type { ChartVariables } from '../ChartComparer/components/LineChart/LineChart';
import HeatmapOccupancy from '../HeatmapOccupancy';
import UtlizationCard from '../UtilizationCard/UtilizationCard';

export default function DeskAnalyticsCharts() {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const [chartVariables, setChartVariables] = useState<ChartVariables[]>([
    {
      Room: null,
      Building: null,
      Floor: null,
      Labels: null,
      Id: uuidv4(),
    },
  ]);
  const [{ data: deskData, fetching }] = useDesksUsageQuery({
    variables: useAnalyticsFilter(),
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.VIEW_ANALYTICS),
      [hasuraHeader],
    ),
  });

  return (
    <div className="grid grid-cols-12 gap-2">
      <div className="col-span-12 lg:col-span-2 flex space-x-2 lg:flex-col lg:space-y-2 lg:space-x-0">
        <UtlizationCard
          dataTestId="desks-avg-occupancy-card"
          title={intl.formatMessage({ id: 'Average Occupancy' })}
          infoText={`${intl.formatMessage({
            id: 'hot',
          })} + ${intl.formatMessage({ id: 'warm' })}`}
          value={
            deskData?.f_history_desks_occupancy_custom[0]?.AvgPercentageUsage ??
            0
          }
          loading={fetching}
        />
        <UtlizationCard
          dataTestId="desks-highest-hourly-occupancy-card"
          title={intl.formatMessage({ id: 'Max hourly occupancy' })}
          infoText={`${intl.formatMessage({
            id: 'hot',
          })} + ${intl.formatMessage({ id: 'warm' })}`}
          value={
            deskData?.f_history_desks_occupancy_custom[0]?.MaxPercentageUsage ??
            0
          }
          loading={fetching}
        />
        <UtlizationCard
          dataTestId="desks-highest-usage-card"
          title={intl.formatMessage({ id: 'PercentageMaxOccupancy' })}
          infoText={intl.formatMessage({
            id: 'Max desk occupancy in the selected period',
          })}
          value={
            deskData?.f_history_desks_occupancy_custom[0]
              ?.PercentageMaxOccupancy ?? 0
          }
          loading={fetching}
        />
      </div>
      <Card
        noPadding
        className="h-96 relative col-span-12 lg:col-span-10"
        title={intl.formatMessage({ id: '[Desks] Daily Occupancy' })}
        data-test-id="desks-daily-occupancy"
      >
        <BrushChart />
      </Card>
      <Card
        noPadding
        className="h-96 relative col-span-12 lg:col-span-6"
        title={intl.formatMessage({ id: '[Desks] Hourly Occupancy' })}
        data-test-id="desks-hourly-occupancy"
      >
        <BarOccupancy />
      </Card>
      <Card
        noPadding
        className="h-96 relative col-span-12 lg:col-span-6"
        title={intl.formatMessage({ id: '[Desks] Hourly Occupancy' })}
      >
        <HeatmapOccupancy />
      </Card>
      <Card
        noPadding
        className="col-span-12"
        title={intl.formatMessage({ id: '[Desks] Daily Occupancy Comparison' })}
      >
        <ChartComparer
          chartVariables={chartVariables}
          setChartVariables={setChartVariables}
        />
      </Card>
    </div>
  );
}
