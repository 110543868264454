import useDecodedLocation from 'mda2-frontend/src/utils/useDecodedLocation';

interface EmbeddedWrapperProps {
  alternative?: JSX.Element;
  children: JSX.Element | null | false;
}

export default function EmbeddedWrapper({
  alternative,
  children,
}: EmbeddedWrapperProps): JSX.Element | null {
  const embedded = useDecodedLocation('embedded');

  if (embedded === 'true') {
    if (alternative) return alternative;
    return null;
  }

  if (!children) return null;

  return children;
}
