import Keycloak, { type KeycloakConfig } from 'keycloak-js';

const jwtUrl = import.meta.env.VITE_JWT_URL;

const keycloakConfig: KeycloakConfig = {
  realm: 'Regent',
  url: jwtUrl,
  clientId: 'mda2',
};
export default new Keycloak(keycloakConfig);
