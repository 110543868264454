import { FormattedMessage, type IntlMessageKeys, useIntl } from 'translations/Intl';

import { Action } from 'mda2-frontend/src/common/types';
import Modal from 'mda2-frontend/src/generic/components/Modal';
import ModalFooter from 'mda2-frontend/src/generic/components/ModalFooter';
import type { ReactNode } from 'react';

interface ActionModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  onProceed: () => void;
  title: IntlMessageKeys;
  icon: JSX.Element;
  children: ReactNode;
  disabled?: boolean;
  onCancel?: () => void;
}

export default function ActionModal({
  isOpen,
  setIsOpen,
  title,
  icon,
  onProceed,
  children,
  disabled,
  onCancel,
}: ActionModalProps) {
  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage({
        id: title,
      })}
      icon={icon}
      action={Action.UPDATE}
      open={isOpen}
      setShowModal={setIsOpen}
      footer={
        <ModalFooter
          proceed={<FormattedMessage id={title} />}
          disabled={disabled ?? false}
          isLoading={disabled ?? false}
          loading={
            disabled ? <FormattedMessage id="Scene is active" /> : undefined
          }
          action={Action.UPDATE}
          onProceed={onProceed}
          onCancel={() => {
            if (onCancel) onCancel();
            setIsOpen(false);
          }}
        />
      }
    >
      {children}
    </Modal>
  );
}
