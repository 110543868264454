import {
  type RoomClimateHistory,
  useRoomClimateHistoryQuery,
} from 'graphql/types';
import { useIntl } from 'translations/Intl';
import useReportFilter from 'utils/graphql/useReportFilter';
import ReportingPage from '../ReportingPage';
import BarChart from './components/BarChart';
import StackedBarChart from './components/StackedBarChart';

export default function ReportClimateValues() {
  const intl = useIntl();
  const reportFilter = useReportFilter();
  const [{ data, fetching }] = useRoomClimateHistoryQuery(reportFilter);

  const typedData = (data?.ReportCharts[0]?.Data ?? { data: [] }) as {
    data: RoomClimateHistory[];
  };

  const environmentData = typedData.data;
  const sensorTypes = [...new Set(environmentData.map((e) => e.SensorType))];

  return (
    <ReportingPage
      loading={fetching}
      reportPageId={data?.ReportCharts[0]?.ReportPageId}
      reportTitle="Room climate conditions"
      kpi={intl.formatMessage({
        id: 'Are the room conditions agreeable?',
      })}
    >
      <div className="flex flex-col space-y-4 w-full">
        <div className="h-96 w-full">
          <StackedBarChart data={environmentData} />
        </div>
        {sensorTypes.map((sensorType, idx) => {
          const data = environmentData.filter(
            (e) => e.SensorType === sensorType,
          );
          const rooms = [
            ...new Set(
              data
                .filter((e) => e.SensorType === sensorType)
                .map((e) => e.Room),
            ),
          ];
          return (
            <div
              className={`h-72 w-full ${idx % 2 === 1 ? 'print:break-after-page' : ''}`}
              key={sensorType}
            >
              <BarChart
                sensorType={sensorType}
                key={sensorType}
                data={rooms.map((d) => ({
                  roomName: d,
                  acceptablePercentage:
                    data.find((e) => e.Room === d)?.AcceptablePercentage ?? 0,
                  goodPercentage:
                    data.find((e) => e.Room === d)?.GoodPercentage ?? 0,
                  poorPercentage:
                    data.find((e) => e.Room === d)?.PoorPercentage ?? 0,
                }))}
              />
            </div>
          );
        })}
      </div>
    </ReportingPage>
  );
}
