import { useIntl } from 'translations/Intl';

import { RoomTypes } from 'mda2-frontend/src/common/types';
import EmbeddedWrapper from 'mda2-frontend/src/generic/components/EmbeddedWrapper';
import LastUpdated from 'mda2-frontend/src/generic/components/LastUpdated';
import Transition from 'mda2-frontend/src/generic/components/Transition';
import Loader from 'mda2-frontend/src/generic/components/layout/BarLoader';
import {
  type 
  BuildingPartsFragment,
  useFloorsTopologyQuery,
  useLiveOccupancyQuery,
} from 'mda2-frontend/src/graphql/types';
import useStore from 'mda2-frontend/src/model/store';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import usePolling from 'mda2-frontend/src/utils/graphql/usePolling';
import useRoomDeskFilter from 'mda2-frontend/src/utils/graphql/useRoomDeskFilter';
import useDecodedLocation from 'mda2-frontend/src/utils/useDecodedLocation';
import { useMemo, useState } from 'react';

import CountInfo from './components/CountInfo';
import FloorOccupancy from './components/FloorOccupancy';
import LiveChart from './components/LiveChart';

interface BuildingOccupancyInterface {
  building?: BuildingPartsFragment;
}

const POLLING_INTERVAL = 1000 * 30;
export default function BuildingOccupancy({
  building,
}: BuildingOccupancyInterface): JSX.Element {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const buildingName = useDecodedLocation('building');
  const roomTypes = useStore((state) => state.userSettings.roomTypes);
  const userRoles = useStore((state) => state.user)?.roles;
  const [{ data: liveData, fetching: loadingLiveData }, reexecuteQuery] =
    useLiveOccupancyQuery({
      variables: {
        Building: buildingName ?? building?.Name,
      },
      requestPolicy: 'network-only',
      context: useMemo(
        () => hasuraHeader(HasuraPermissions.VIEW_DASHBOARD),
        [hasuraHeader],
      ),
      pause:
        (!buildingName && !building?.Name) ||
        (buildingName ?? building?.Name) === '-' ||
        !roomTypes.includes(RoomTypes.DESKS),
    });
  usePolling(loadingLiveData, reexecuteQuery, POLLING_INTERVAL, setLastUpdate);

  const [{ data: locationData, fetching: loading }] = useFloorsTopologyQuery({
    variables: {
      BuildingName: buildingName ?? building?.Name,
      Desks: useRoomDeskFilter().Desks,
    },
    pause:
      (!buildingName && !building?.Name) ||
      (buildingName ?? building?.Name) === '-',
  });

  return (
    <>
      <Loader loading={loading || loadingLiveData} />
      <div className="space-y-2 relative">
        <div className="flex flex-col space-y-4">
          <EmbeddedWrapper>
            <div className="w-full">
              <LastUpdated lastUpdate={lastUpdate} />
            </div>
          </EmbeddedWrapper>
          <Transition show={roomTypes.includes(RoomTypes.DESKS)}>
            <div className="h-64 max:h-fit w-full">
              <LiveChart liveData={liveData} />
            </div>
            <div>
              <div className="w-full space-y-2">
                <div className="flex justify-between space-x-4">
                  <CountInfo
                    dataTestId="free-desks"
                    absolute={
                      liveData?.f_live_desks_occupancy[0]?.ColdCount ?? 0
                    }
                    relative={
                      liveData?.f_live_desks_occupancy[0]?.ColdPercentage ?? 0
                    }
                    color="green"
                  />
                  <CountInfo
                    dataTestId="warm-desks"
                    absolute={
                      liveData?.f_live_desks_occupancy[0]?.WarmCount ?? 0
                    }
                    relative={
                      liveData?.f_live_desks_occupancy[0]?.WarmPercentage ?? 0
                    }
                    color="yellow"
                  />
                </div>
                <div className="flex justify-between space-x-4">
                  <CountInfo
                    dataTestId="hot-desks"
                    absolute={
                      liveData?.f_live_desks_occupancy[0]?.HotCount ?? 0
                    }
                    relative={
                      liveData?.f_live_desks_occupancy[0]?.HotPercentage ?? 0
                    }
                    color="red"
                  />
                  <CountInfo
                    dataTestId="offline-desks"
                    absolute={
                      liveData?.f_live_desks_occupancy[0]?.OfflineCount ?? 0
                    }
                    relative={
                      liveData?.f_live_desks_occupancy[0]?.OfflinePercentage ??
                      0
                    }
                    color="neutral"
                    url={
                      userRoles?.includes(HasuraPermissions.VIEW_STATUS)
                        ? `/status?status=${intl.formatMessage({
                            id: 'Offline',
                          })}&building=${encodeURIComponent(
                            buildingName ?? building?.Name ?? '',
                          )}`
                        : undefined
                    }
                  />
                </div>
              </div>
            </div>
          </Transition>
          {loading ? (
            <div className="flex flex-col gap-2">
              <div className="h-4 w-1/2 bg-neutral-200 animate-pulse rounded-md" />
              <div className="flex gap-2">
                <div className="size-4 bg-neutral-200 animate-pulse rounded-md" />
                <div className="h-4 w-1/2 bg-neutral-200 animate-pulse rounded-md" />
              </div>
            </div>
          ) : (
            <div className="divide-y divide-neutral-200 w-full">
              {locationData?.Floors?.slice()
                .sort((a, b) => b.Number - a.Number)
                .map((floor) => (
                  <FloorOccupancy
                    key={floor.Id}
                    floor={floor}
                    buildingName={buildingName ?? building?.Name ?? '-'}
                    buildingId={building?.Id}
                  />
                ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
