import Button from 'generic/components/Form/Button';

interface TileButtonProps {
  onClick: () => void;
  title?: string | JSX.Element;
  children: JSX.Element;
}

export default function TileButton({
  children,
  onClick,
  title,
}: TileButtonProps) {
  return (
    <Button onClick={onClick} className="w-auto max-w-md xl:w-32 relative">
      {title && <p className="pb-2 text-left">{title}</p>}
      {children}
    </Button>
  );
}
