import ClipboardButton from 'generic/components/ClipboardButton';
import {
  forwardRef,
  useImperativeHandle,
  useRef,
  type InputHTMLAttributes,
} from 'react';

interface TextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  text: string;
  label?: string;
  dataTestId?: string;
}

const TextArea = forwardRef<HTMLTextAreaElement | null, TextAreaProps>(
  (props, ref) => {
    const { text, label, dataTestId, ...rest } = props;

    const innerRef = useRef<HTMLTextAreaElement>(null);
    useImperativeHandle(ref, () => innerRef.current!);

    return (
      <div>
        <div className="flex justify-between items-center">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label
            htmlFor="textarea"
            className="text-base md:text-sm text-neutral-700 dark:text-white"
          >
            {label}
          </label>
          <ClipboardButton elementRef={innerRef} />
        </div>
        <div className="mt-1">
          <textarea
            ref={innerRef}
            data-test-id={dataTestId}
            disabled={props.onChange === undefined}
            id="textarea"
            name="textarea"
            rows={10}
            className="shadow-sm focus:ring-primary-500 text-neutral-800 dark:text-neutral-100 focus:border-primary-500 mt-1 block w-full sm:text-sm border dark:bg-neutral-800 border-neutral-300 dark:border-neutral-700 rounded-md"
            value={text}
            {...rest}
          />
        </div>
      </div>
    );
  },
);

export default TextArea;
