import type { FloorReservationsQuery } from 'graphql/types';
import useStore from 'model/store';

import getColor from 'mda2-frontend/src/utils/getColor';
import renderOfflineIcon from 'mda2-frontend/src/utils/renderOfflineIcon';
import renderReservedIcon from 'mda2-frontend/src/utils/renderReservedIcon';
import type Feature from 'ol/Feature';
import type OLMap from 'ol/Map';
import GeoJSON, { type GeoJSONFeature } from 'ol/format/GeoJSON';
import type Geometry from 'ol/geom/Geometry';
import type Polygon from 'ol/geom/Polygon';
import OLVectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';

import type { LayerOptions } from './Layer';
import type TypedFeature from './TypedFeature';
import VectorLayer from './VectorLayer';

export type RoomReservationFeature = {
  Room:
    | NonNullable<FloorReservationsQuery['Rooms']>[number]
    | NonNullable<FloorReservationsQuery['RoomReservations']>[number]['Room'];
  Duration?: NonNullable<
    FloorReservationsQuery['RoomReservations']
  >[number]['Duration'];
  Reserved: boolean;
  Offline: boolean;
  IsPrivate: boolean;
} & Omit<
  NonNullable<FloorReservationsQuery['RoomReservations']>[number],
  'Duration'
>;

export type RoomReservationFeatureType = TypedFeature<
  RoomReservationFeature,
  Geometry
>;

const styleFunction = (
  feature: RoomReservationFeatureType,
  hoveredFeature: RoomReservationFeatureType | undefined,
  isMobile: boolean,
  map?: OLMap,
) => {
  const isHovered = feature.getGeometry() === hoveredFeature?.getGeometry();
  const {
    Reserved: reserved,
    Offline: offline,
    IsPrivate: isPrivate,
    Room: room,
  } = feature.getProperties();
  const isFavorite = room.Name === useStore.getState().selectedFeature;

  const getStrokeWidth = () => {
    if (isMobile && isHovered) return 3;
    if (isHovered) return 2;
    return 1;
  };

  const getRoomTransparency = () => {
    if (isMobile && isHovered) return '.9';
    if (isHovered) return '.7';
    return '.5';
  };

  const getRoomColor = () => {
    if (offline) return 'NEUTRAL400';
    if (isPrivate) return 'NEUTRAL600';
    if (isFavorite) return 'YELLOW';
    if (reserved) return 'RED';
    return 'GREEN';
  };

  const extraStyle = !reserved
    ? renderOfflineIcon(feature, offline, map)
    : renderReservedIcon(feature, reserved, map);

  return [
    new Style({
      stroke: new Stroke({
        color: getColor(getRoomColor(), isHovered && !offline ? '1' : '.5'),
        width: getStrokeWidth(),
        lineDash: isMobile && isHovered ? [7, 10] : undefined,
      }),
      fill: new Fill({
        color: getColor(getRoomColor(), getRoomTransparency()),
      }),
    }),
    extraStyle,
  ];
};

interface ClimateRoomLayerOptions extends LayerOptions {
  olLayer: OLVectorLayer<VectorSource<Feature<Polygon>>>;
}

export default class RoomReservationLayer extends VectorLayer<Polygon> {
  features?: RoomReservationFeature[];

  hoveredFeature?: RoomReservationFeatureType;

  isMobile: boolean;

  init(map: OLMap): void {
    super.init(map);
    this.map = map;
  }

  constructor() {
    super({
      ...{
        olLayer: new OLVectorLayer({
          source: new VectorSource(),
          style: (feature) =>
            styleFunction(
              feature as RoomReservationFeatureType,
              this.hoveredFeature,
              this.isMobile,
              this.map,
            ),
        }),
      },
    } as ClimateRoomLayerOptions);

    this.hoveredFeature = undefined;
    this.isMobile = false;
  }

  setFeatures(feats: RoomReservationFeature[]): void {
    this.features = feats;
    const source = this.olLayer.getSource() as VectorSource<Feature<Polygon>>;
    const newFeatures = new GeoJSON().readFeatures(
      RoomReservationLayer.toGeoJson(this.features),
    );
    source.clear();
    source.addFeatures(newFeatures as Feature<Polygon>[]);
  }

  static toGeoJson(feats?: RoomReservationFeature[]): GeoJSONFeature {
    const features = feats?.map((feat) => {
      const { Room: geo } = feat;
      return {
        type: 'Feature',
        geometry: geo.Geometry,
        properties: {
          ...feat,
        },
      };
    });
    return {
      type: 'FeatureCollection',
      features,
    };
  }
}
