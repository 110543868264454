import { useCallback, useEffect, useMemo } from 'react';
import type { UseQueryExecute } from 'urql';

export default function usePolling(
  fetching: boolean,
  reexecuteQuery: UseQueryExecute,
  interval = 10000,
  setLastUpdate?: (date: Date) => void,
) {
  const timeWorker = useMemo(
    () => new Worker(new URL('/src/worker-script.ts', import.meta.url)),
    [],
  );

  const executeQuery = useCallback(() => {
    if (!fetching) {
      reexecuteQuery({ requestPolicy: 'cache-and-network' });
      setLastUpdate?.(new Date());
    }
  }, [fetching, reexecuteQuery, setLastUpdate]);

  useEffect(() => {
    // Reexecute query on page focus
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        executeQuery();
      }
    };

    document.addEventListener(
      'visibilitychange',
      handleVisibilityChange,
      false,
    );

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [executeQuery]);

  useEffect(() => {
    timeWorker.postMessage({ on: true, interval });

    return () => {
      timeWorker.postMessage({ on: false });
    };
  }, [interval, timeWorker]);

  useEffect(() => {
    timeWorker.onmessage = () => executeQuery();
  }, [executeQuery, timeWorker]);
}
