import { Reorder, useDragControls } from 'framer-motion';
import Select from 'generic/components/Form/Select';
import type { ModulesQuery } from 'graphql/types';
import { HiBars3 } from 'react-icons/hi2';

export type FirmwareInfo = {
  FirmwareId: number;
  Version: string;
  ModuleId: string;
  Path?: string | null;
  Changelog?: string | null;
};

export default function OrderItem({
  module,
  selectedFirmware,
  setSelectedFirmware,
}: {
  module: ModulesQuery['Modules'][number];
  selectedFirmware: FirmwareInfo[] | undefined;
  setSelectedFirmware: React.Dispatch<
    React.SetStateAction<FirmwareInfo[] | undefined>
  >;
}) {
  const controls = useDragControls();

  const firmware = module.Firmwares.find(
    (m) =>
      selectedFirmware?.find((f) => f.ModuleId === module.Id)?.FirmwareId ===
      m.Id,
  );

  return (
    <Reorder.Item
      key={module.Id}
      value={module}
      dragListener={false}
      dragControls={controls}
    >
      <div className="flex space-x-2 items-center">
        <HiBars3
          className="size-8 cursor-pointer text-neutral-400/50"
          onPointerDown={(e) => controls.start(e)}
        />
        <div className="col-span-1">
          <div>{module.Name}</div>
          <Select
            value={firmware}
            options={module.Firmwares.filter((f) => f.Path)}
            keyParameter="Id"
            renderValue={(m) => m?.Version ?? ''}
            onChangeSelected={(selected) => {
              if (selected) {
                setSelectedFirmware((prev) =>
                  prev
                    ? [
                        ...prev.filter((p) => p.ModuleId !== module.Id),
                        {
                          FirmwareId: selected.Id,
                          ModuleId: module.Id,
                          Path: selected.Path,
                          Changelog: selected.Changelog,
                          Version: selected.Version,
                        },
                      ]
                    : [
                        {
                          FirmwareId: selected.Id,
                          ModuleId: module.Id,
                          Path: selected.Path,
                          Changelog: selected.Changelog,
                          Version: selected.Version,
                        },
                      ],
                );
              } else {
                setSelectedFirmware((prev) =>
                  prev?.filter((p) => p.ModuleId !== module.Id),
                );
              }
            }}
            isDeselectable={false}
          />
        </div>
      </div>
    </Reorder.Item>
  );
}
