import type { ReactNode } from 'react';
import type { IntlShape } from 'react-intl';
import {
  FormattedMessage as ReactIntlFormattedMessage,
  useIntl as useReactIntl,
} from 'react-intl';
import type { Props as ReactIntlFormattedMessageProps } from 'react-intl/src/components/message';

import type enMessages from './en.json';
import type enErrorMessages from './errors/en.json';

// Our new union type of all available message IDs.
export type IntlMessageKeys = keyof typeof enMessages;
export type IntlErrorMessageKeys = keyof typeof enErrorMessages;

// The arguments to the original formatMessage function.
type FormatMessageArgs = Parameters<IntlShape['formatMessage']>;

// Extend the original FormattedMessage props.
type FormattedMessageProps = ReactIntlFormattedMessageProps<
  Record<string, ReactNode>
> & {
  id?: IntlMessageKeys;
};

export function FormattedMessage({ id, ...rest }: FormattedMessageProps) {
  return <ReactIntlFormattedMessage id={id} {...rest} />;
}

export function useIntl() {
  // Pull out the original formatMessage function.
  const { formatMessage, ...rest } = useReactIntl();

  // Re-write the formatMessage function but with a strongly-typed id.
  const typedFormatMessage = (
    descriptor: FormatMessageArgs[0] & {
      id?: IntlMessageKeys;
    },
    values?: FormatMessageArgs[1],
    options?: FormatMessageArgs[2],
  ) => formatMessage(descriptor, values, options)?.toString() ?? '';

  return {
    ...rest,
    formatMessage: typedFormatMessage,
  };
}
