import * as React from "react";
const SvgSmartmoduleOne = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 34.02 34.02", ...props }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("style", null, ".cls-4{fill:none;stroke-linejoin:round;stroke:#181716;stroke-width:.07px}")), /* @__PURE__ */ React.createElement("g", { id: "Smartmodule_One", "data-name": "Smartmodule One" }, /* @__PURE__ */ React.createElement("path", { d: "M27.8 19.91c0 2.74-4.83 4.96-10.79 4.96S6.22 22.65 6.22 19.91v-.07c.08-2.71 4.88-4.89 10.79-4.89s10.71 2.19 10.79 4.89v.07Z", style: {
  stroke: "#181716",
  strokeWidth: ".09px",
  fill: "#181716",
  strokeLinejoin: "round"
} }), /* @__PURE__ */ React.createElement("path", { d: "M27.8 19.91v-.07M6.22 19.84v.07", className: "cls-4" }), /* @__PURE__ */ React.createElement("ellipse", { cx: 14.17, cy: 21.55, rx: 1.21, ry: 0.57, style: {
  fill: "#fff",
  strokeLinejoin: "round",
  stroke: "#fff",
  strokeWidth: ".11px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M16.16 20.72s-.36-.11-.36-.53c0-.31.54-.57 1.21-.57s1.21.25 1.21.57c0 .42-.36.53-.36.53", style: {
  fill: "#181716",
  strokeLinejoin: "round",
  stroke: "#fff",
  strokeWidth: ".11px"
} }), /* @__PURE__ */ React.createElement("path", { d: "m19.21 19.23 4.19-1.93", style: {
  strokeLinejoin: "round",
  stroke: "#fff",
  strokeWidth: ".11px",
  fill: "none",
  strokeLinecap: "round"
} }), /* @__PURE__ */ React.createElement("ellipse", { cx: 17.01, cy: 20.72, rx: 0.85, ry: 0.4, style: {
  strokeLinejoin: "round",
  stroke: "#fff",
  strokeWidth: ".11px",
  fill: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "M6.22 19.84c.08-2.71 4.88-4.89 10.79-4.89s10.71 2.19 10.79 4.89l-.13-5.86c-.08-2.68-4.82-4.84-10.66-4.84S6.43 11.3 6.35 13.98l-.13 5.86Z", style: {
  stroke: "#181716",
  strokeWidth: ".09px",
  fill: "#fff",
  strokeLinejoin: "round"
} })), /* @__PURE__ */ React.createElement("path", { id: "Rahmen", d: "M0 0h34.02v34.02H0z", style: {
  fill: "none"
} }));
export default SvgSmartmoduleOne;
