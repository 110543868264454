import { useUpdateGeometryUnitPerMeterMutation } from 'graphql/types';
import { Action, type Floor } from 'mda2-frontend/src/common/types';
import Input from 'mda2-frontend/src/generic/components/Form/Input';
import ModalFooter from 'mda2-frontend/src/generic/components/ModalFooter';
import Panel from 'mda2-frontend/src/generic/components/Panel';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import useToast from 'mda2-frontend/src/utils/graphql/useToast';
import type LineString from 'ol/geom/LineString';
import { useMemo, useState } from 'react';
import { FaCircleNotch } from 'react-icons/fa';
import { HiOutlineInformationCircle } from 'react-icons/hi2';
import { TfiRulerAlt } from 'react-icons/tfi';
import { FormattedMessage, useIntl } from 'translations/Intl';

interface MeasureDistanceCardProps {
  open: boolean;
  lineGeometry?: LineString;
  onClose: () => void;
  floor: Floor;
}

export default function MeasureDistanceCard({
  open,
  floor,
  onClose,
  lineGeometry,
}: MeasureDistanceCardProps): JSX.Element {
  const intl = useIntl();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();
  const [{ fetching: isUpdating }, useUpdateGeometryUnitPerMeter] =
    useUpdateGeometryUnitPerMeterMutation();
  const [lengthInMeter, setLengthInMeter] = useState(0);
  const [isSaving, setIsSaving] = useState(false);

  const geometryUnitPerMeter = useMemo(() => {
    const distance = lineGeometry?.getLength();
    return distance ? distance / lengthInMeter : null;
  }, [lengthInMeter, lineGeometry]);

  return (
    <>
      <Panel
        open={open}
        setOpen={onClose}
        isRelative
        dataTestId="add-measure-distance-panel"
      >
        <Panel.Content
          className="w-full min-w-fit md:w-[350px]"
          title={
            <div className="flex flex-row items-center space-x-4 flex-wrap gap-4">
              <FormattedMessage id="Measure reference line" />
            </div>
          }
        >
          <div className="flex flex-col gap-2">
            <Input
              type="number"
              label={intl.formatMessage({
                id: 'Reference line length (in meters)',
              })}
              min={0}
              value={lengthInMeter}
              icon={
                isSaving ? (
                  <FaCircleNotch className="animate-spin" />
                ) : (
                  <TfiRulerAlt />
                )
              }
              onChangeValue={(v) => setLengthInMeter(Number.parseFloat(v))}
              required
            />

            <div className="flex flex-row items-center">
              <HiOutlineInformationCircle className="shrink-0 mr-2 h-6 w-6 text-orange-300" />
              <div className="block text-sm  text-neutral-700 dark:text-white">
                <FormattedMessage id="A reference line must be drawn on the map to calibrate the distance (in meters)" />
              </div>
            </div>
          </div>
        </Panel.Content>
        <Panel.Footer>
          <ModalFooter
            action={Action.ADD}
            disabled={!lineGeometry || !lengthInMeter || !geometryUnitPerMeter}
            isLoading={isUpdating}
            onProceed={() => {
              setIsSaving(true);
              useUpdateGeometryUnitPerMeter(
                {
                  Id: floor?.Id,
                  GeometryUnitPerMeter: geometryUnitPerMeter,
                },
                hasuraHeader(HasuraPermissions.WRITE_FLOOR),
              )
                .then((data) => {
                  toast(data, {
                    field: 'Number',
                  });
                  setIsSaving(false);
                })
                .finally(onClose);
            }}
            onCancel={onClose}
          />
        </Panel.Footer>
      </Panel>
    </>
  );
}
