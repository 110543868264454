import Tooltip from 'generic/components/Tooltip';
import Transition from 'generic/components/Transition';
import { type InputHTMLAttributes, forwardRef } from 'react';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean;
  setChecked?: (checked: boolean) => void;
  label?: string;
  tooltip?: string;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { checked, setChecked, label, tooltip, ...rest } = props;

  return (
    <label
      htmlFor={label}
      className="flex items-center justify-start text-left space-x-2 text-base md:text-sm text-neutral-600 dark:text-neutral-200"
    >
      <input
        id={label}
        data-test-id={label}
        ref={ref}
        name="role"
        type="checkbox"
        checked={checked}
        onChange={setChecked ? () => setChecked(!checked) : undefined}
        className="focus:ring-primary-400 size-4 text-primary-500 border-neutral-300 dark:border-neutral-700 rounded cursor-pointer disabled:bg-neutral-200 disabled:hover:bg-neutral-200 disabled:dark:bg-neutral-700 disabled:cursor-default disabled:dark:hover:bg-neutral-700"
        {...rest}
      />
      <div
        // biome-ignore lint/a11y/useSemanticElements: If using button then there will be a button as descendent of another button
        role="button"
        onKeyDown={setChecked ? () => setChecked(!checked) : undefined}
        tabIndex={0}
        className="text-left font-medium text-base md:text-sm"
        onClick={setChecked ? () => setChecked(!checked) : undefined}
      >
        {label}
      </div>
      <Transition as="div" show={!!tooltip}>
        <Tooltip>{tooltip}</Tooltip>
      </Transition>
    </label>
  );
});

export default Checkbox;
