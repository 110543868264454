import { Action } from 'common/types';
import CloseButton from 'generic/components/Form/Button/CloseButton';
import Input from 'generic/components/Form/Input';
import Modal from 'generic/components/Modal';
import { useIntl } from 'translations/Intl';

import ImageModal from 'generic/components/ImageModal';
import { useRef, useState } from 'react';
import {
  HiOutlineClipboard,
  HiOutlineShieldExclamation,
  HiOutlineUserGroup,
  HiOutlineWifi,
} from 'react-icons/hi2';
import QRCode from 'react-qr-code';

interface SetupHotspotModalProps {
  uuid: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function SetupHotspotModal({
  uuid,
  open,
  setOpen,
}: SetupHotspotModalProps) {
  const { VITE_DEFAULT_WIFI_PASSWORD } = import.meta.env;
  const [numberOfOperators, setNumberOfOperators] = useState(1);
  const ssidTextFieldRefs = useRef<(HTMLInputElement | null)[]>([]);
  const passwordTextFieldRef = useRef<HTMLInputElement | null>(null);
  const intl = useIntl();
  const operatorArray = Array.from({ length: numberOfOperators });

  return (
    <Modal
      action={Action.ADD}
      title={intl.formatMessage({
        id: 'Create hotspot',
      })}
      icon={<HiOutlineWifi className="animate-pulse" />}
      open={open}
      setShowModal={setOpen}
      footer={<CloseButton onClick={() => setOpen(false)} />}
    >
      <div className="flex flex-col space-y-2 w-full">
        <Input
          label={intl.formatMessage({
            id: 'Number of operators',
          })}
          icon={<HiOutlineUserGroup />}
          value={numberOfOperators}
          type="number"
          min={1}
          onChangeValue={(val) =>
            setNumberOfOperators(Number.parseInt(val, 10))
          }
          tooltipText={intl.formatMessage({
            id: 'operator-text',
          })}
        />
        {operatorArray.map((_, i) => (
          <div key={i} className="flex space-x-2 items-end">
            <div className="w-full">
              <Input
                label={intl.formatMessage({
                  id: 'SSID',
                })}
                icon={<HiOutlineWifi />}
                disabled
                value={`mda_${uuid}_${i + 1}`}
                type="text"
                onChangeValue={() => null}
                // biome-ignore lint/suspicious/noAssignInExpressions: noAssignInExpressions
                ref={(ref) => (ssidTextFieldRefs.current[i] = ref)}
                tooltipText={intl.formatMessage({ id: 'Copy' })}
                tooltipContent={
                  <div>
                    <HiOutlineClipboard
                      onClick={() => {
                        navigator.clipboard.writeText(
                          ssidTextFieldRefs.current[i]?.value ?? '',
                        );
                      }}
                      className="size-4 cursor-pointer hover:text-primary-500 transition-colors"
                    />
                  </div>
                }
              />
            </div>
            <ImageModal>
              <QRCode
                size={56}
                className="w-full h-full bg-white dark:bg-neutral-800 rounded-md p-2 border dark:border-neutral-700"
                value={`mda_${uuid}_${i + 1}`}
              />
            </ImageModal>
          </div>
        ))}

        <div className="flex space-x-2 items-end">
          <div className="w-full">
            <Input
              label={intl.formatMessage({
                id: 'WiFi password',
              })}
              icon={<HiOutlineShieldExclamation />}
              disabled
              value={VITE_DEFAULT_WIFI_PASSWORD}
              type="text"
              onChangeValue={() => null}
              ref={passwordTextFieldRef}
              tooltipText={intl.formatMessage({ id: 'Copy' })}
              tooltipContent={
                <div>
                  <HiOutlineClipboard
                    onClick={() => {
                      navigator.clipboard.writeText(
                        passwordTextFieldRef.current?.value ?? '',
                      );
                    }}
                    className="size-4 cursor-pointer hover:text-primary-500 transition-colors"
                  />
                </div>
              }
            />
          </div>
          <ImageModal>
            <QRCode
              size={56}
              className="w-full h-full bg-white dark:bg-neutral-800 rounded-md p-2 border dark:border-neutral-700"
              value={VITE_DEFAULT_WIFI_PASSWORD}
            />
          </ImageModal>
        </div>
      </div>
    </Modal>
  );
}
