interface SkeletonLoaderProps {
  rows: number;
  columns: number;
  loading?: boolean;
}

export default function SkeletonLoader({
  rows,
  columns,
  loading,
}: SkeletonLoaderProps) {
  return loading ? (
    <>
      {Array.from({ length: rows }).map((_, i) => (
        <tr key={`${i as number}-row`}>
          {Array.from({ length: columns }).map((__, j) => (
            <td
              key={`${j as number}-column`}
              className="px-6 py-4 whitespace-nowrap"
            >
              <div className="w-full animate-pulse flex">
                <div className="w-full bg-neutral-200 h-4 rounded-md" />
              </div>
            </td>
          ))}
        </tr>
      ))}
    </>
  ) : null;
}
