import { TZDate } from '@date-fns/tz';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { scaleLinear, scaleTime } from '@visx/scale';
import { LinePath } from '@visx/shape';
import { Threshold as VXThreshold } from '@visx/threshold';
import { type MarginProps, ModuleType, Themes } from 'common/types';
import { extent } from 'd3-array';
import Axis from 'generic/components/Chart/Axis';
import LoadingSpinner from 'generic/components/LoadingSpinner';
import type { RoomOccupancySensorCountQuery } from 'graphql/types';
import useStore from 'model/store';
import { FormattedMessage } from 'translations/Intl';
import formatDate from 'utils/format';
import getColor from 'utils/getColor';

type LineData =
  RoomOccupancySensorCountQuery['f_history_room_occupancy_15m'][number];

// Accessors
const date = (d: LineData) => new TZDate(d.Date, 'UTC');
const lineIn = (d: LineData) =>
  d.SensorType === ModuleType.LINECOUNT_IN ||
  d.SensorType === ModuleType.AREACOUNT
    ? d.Value
    : 0;
const lineOut = (d: LineData) =>
  d.SensorType === ModuleType.LINECOUNT_OUT ? d.Value : 0;

interface ThresholdProps extends ResponsiveThresholdProps {
  width: number;
  height: number;
}

interface ResponsiveThresholdProps {
  margin?: MarginProps;
  loading: boolean;
  data?: RoomOccupancySensorCountQuery['f_history_room_occupancy_15m'];
}

function Threshold({
  data = [],
  loading,
  width,
  height,
  margin = { top: 20, right: 60, bottom: 60, left: 30 },
}: ThresholdProps) {
  const theme = useStore((state) => state.userSettings.theme.color);

  // Bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  // Scales
  const timeScale = scaleTime<number>({
    domain: extent(data, date) as [Date, Date],
    range: [0, xMax],
  });
  const countScale = scaleLinear<number>({
    domain: [
      Math.min(...data.map((d) => Math.min(lineIn(d), lineOut(d)))),
      Math.max(...data.map((d) => Math.max(lineIn(d), lineOut(d)))),
    ],
    nice: true,
    range: [yMax, 0],
  });

  return (
    <>
      <LoadingSpinner loading={loading} />
      <div className="relative">
        <svg width={width} height={height}>
          <Group left={margin.left} top={margin.top}>
            <GridRows
              scale={countScale}
              width={xMax}
              height={yMax}
              stroke="#e0e0e0"
            />
            <Axis
              lowLevelChart
              orientation="bottom"
              top={yMax}
              scale={timeScale}
              numTicks={width > 520 ? 10 : 3}
              tickFormat={(v) =>
                v instanceof Date
                  ? v.getHours() === 0
                    ? formatDate(v, 'eee do LLL')
                    : formatDate(v, 'p')
                  : v.toString()
              }
            />
            <Axis
              lowLevelChart
              orientation="left"
              scale={countScale}
              numTicks={width > 520 ? 10 : 3}
            />
            <text
              fill={
                theme === Themes.DARK
                  ? getColor('WHITE')
                  : getColor('NEUTRAL900')
              }
              x="-60"
              y="15"
              transform="rotate(-90)"
              fontSize={10}
            >
              <FormattedMessage id="Total Visitors" />
            </text>
            <VXThreshold<LineData>
              id={`${Math.random()}`}
              data={data}
              x={(d) => timeScale(date(d)) ?? 0}
              y0={(d) => countScale(lineIn(d)) ?? 0}
              y1={(d) => countScale(lineOut(d)) ?? 0}
              clipAboveTo={0}
              clipBelowTo={yMax}
              belowAreaProps={{
                fill: getColor('RED', '0.4'),
              }}
              aboveAreaProps={{
                fill: getColor('GREEN', '0.4'),
              }}
            />
            <LinePath
              data={data}
              x={(d) => timeScale(date(d)) ?? 0}
              y={(d) => countScale(lineIn(d)) ?? 0}
              stroke="#222"
              strokeWidth={1.5}
              strokeOpacity={0.8}
              strokeDasharray="1,2"
            />
            <LinePath
              data={data}
              x={(d) => timeScale(date(d)) ?? 0}
              y={(d) => countScale(lineOut(d)) ?? 0}
              stroke="#222"
              strokeWidth={1.5}
            />
          </Group>
        </svg>
      </div>
    </>
  );
}

export default function ResponsiveThreshold(props: ResponsiveThresholdProps) {
  return (
    <ParentSize>
      {({ width, height }) => (
        <Threshold {...props} height={height} width={width} />
      )}
    </ParentSize>
  );
}
