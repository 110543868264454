import Tooltip from 'generic/components/Tooltip';

import type { GetHandleProps, SliderItem } from 'react-compound-slider';

interface HandleProps {
  domain: number[];
  handle: SliderItem;
  getHandleProps: GetHandleProps;
  unit: string;
  disabled?: boolean;
  hide?: boolean;
}

export default function Handle({
  domain: [min, max],
  handle: { id, value, percent },
  disabled = false,
  getHandleProps,
  unit,
  hide = false,
}: HandleProps) {
  return (
    !hide && (
      <>
        <Tooltip
          content={
            <div
              className="absolute z-20 size-3 cursor-pointer -translate-x-1/2 -translate-y-1/2"
              style={{ left: `${percent}%` }}
              {...getHandleProps(id)}
            />
          }
        >
          {value} {unit}
        </Tooltip>
        <div
          role="slider"
          tabIndex={0}
          aria-valuemin={min}
          aria-valuemax={max}
          aria-valuenow={value}
          className={`absolute -translate-x-1/2 -translate-y-1/2 z-10 size-3 rounded-full shadow-sm ${
            disabled ? 'bg-neutral-200' : 'bg-neutral-500'
          } `}
          style={{ left: `${percent}%` }}
        />
      </>
    )
  );
}
