import CloseButton from 'generic/components/Form/Button/CloseButton';
import { useIntl } from 'translations/Intl';

import Subtitle from 'generic/components/Subtitle';
import { Action } from 'mda2-frontend/src/common/types';
import Modal from 'mda2-frontend/src/generic/components/Modal';
import { useReportPdfsQuery } from 'mda2-frontend/src/graphql/types';
import { HiOutlineDocumentArrowDown } from 'react-icons/hi2';
import { lower } from 'utils/date';
import format from 'utils/format';
import useReportFilter from 'utils/graphql/useReportFilter';
import groupBy from 'utils/groupBy';
import hexToBase64 from 'utils/hexToBase64';

const downloadName = (period: string, building: string) =>
  `${format(lower(period), 'yyyy-MM')}_${building.replaceAll(' ', '_')}.pdf`;

interface DownloadReportModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function DownloadReportModal({
  open,
  setOpen,
}: DownloadReportModalProps) {
  const intl = useIntl();
  const { context } = useReportFilter();
  const [{ data }] = useReportPdfsQuery({ context });

  const groupedData = groupBy(data?.Reports ?? [], (i) => i.Building.Name);

  return (
    <Modal
      action={Action.UPDATE}
      title={intl.formatMessage({
        id: 'Download PDF',
      })}
      icon={<HiOutlineDocumentArrowDown />}
      open={open}
      setShowModal={setOpen}
      footer={<CloseButton onClick={() => setOpen(false)} />}
    >
      {Object.entries(groupedData).map(([building, reports]) => (
        <div key={building}>
          <Subtitle value={building} />
          {reports.map((report) => (
            <div className="flex flex-col" key={`${building} ${report.Period}`}>
              <a
                href={
                  report.File
                    ? `data:application/pdf;base64,${hexToBase64(report.File)}`
                    : undefined
                }
                download={downloadName(report.Period, report.Building.Name)}
              >
                <div className="flex items-center space-x-2">
                  <div>
                    <HiOutlineDocumentArrowDown className="size-5 text-primary-500" />
                  </div>
                  <div>{format(lower(report.Period), 'MMM yyyy')}</div>
                </div>
              </a>
            </div>
          ))}
        </div>
      ))}
    </Modal>
  );
}
