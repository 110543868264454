import type { SnapFeatures } from 'common/types';
import type SnapLayer from 'mda2-frontend/src/generic/layers/SnapLayer';
import type Feature from 'ol/Feature';
import type Map from 'ol/Map';
import type { Coordinate } from 'ol/coordinate';
import { boundingExtent, buffer } from 'ol/extent';
import type Geometry from 'ol/geom/Geometry';
import OLSnap from 'ol/interaction/Snap';
import type { Pixel } from 'ol/pixel';
import VectorSource from 'ol/source/Vector';

interface Result {
  vertex: Coordinate | null;
  vertexPixel: Pixel | null;
  feature: Feature | null;
  segment: Coordinate[] | null;
}

interface SnapOptions {
  // TODO: OL 9.2.5 requires this hack of adding Feature<Geometry> otherwise there are type errors
  source: VectorSource<Feature<Geometry> | SnapFeatures>;
  snapLayer: SnapLayer;
}

class Snap extends OLSnap {
  snapLayer: SnapLayer;

  constructor(options: SnapOptions) {
    super(options);
    this.snapLayer = options.snapLayer;
    window.addEventListener('keydown', (e) => {
      if (e.ctrlKey) {
        this.setActive(false);
        this.snapLayer.setSnappedLineIds(null);
      }
    });
    window.addEventListener('keyup', () => {
      if (!this.getActive()) {
        this.setActive(true);
      }
    });
  }

  snapTo(pixel: Pixel, pixelCoordinate: Coordinate, map: Map): Result | null {
    const snapTo = super.snapTo(pixel, pixelCoordinate, map);

    if (snapTo?.vertex) {
      // Add a small buffer in order to show line when it is nearby
      // https://stackoverflow.com/questions/54108038/getfeaturesatpixel-to-include-decluttered-hidden-features
      const extent = buffer(
        boundingExtent([snapTo.vertex]),
        2 / (map.getView()?.getResolution() ?? 1),
      );

      const snapFeatures = map
        .getLayers()
        .getArray()
        .filter((l) => l.get('source') instanceof VectorSource)
        .flatMap((l) =>
          (l.get('source') as VectorSource)
            .getFeatures()
            .filter(
              (f: Feature): f is SnapFeatures =>
                f.getProperties().snapIdentifier !== undefined &&
                (f.getGeometry()?.intersectsExtent(extent) ?? false),
            )
            .map((f) => f.getProperties().snapIdentifier),
        );

      if (snapFeatures.length > 0) {
        this.snapLayer.setSnappedLineIds(snapFeatures);
      }
    } else if (this.snapLayer.snappedLineIds) {
      this.snapLayer.setSnappedLineIds(null);
    }
    return snapTo;
  }
}

export default Snap;
