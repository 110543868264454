import type { FloorStoredOffsets } from 'mda2-frontend/src/common/types';
import { LS_DESK_OFFSETS, LS_DESK_ROTATION } from 'mda2-frontend/src/constants';
import Point from 'ol/geom/Point';

const getDeskGeom = (
  sensorIdx: number,
  length: number,
  newGeom: Point,
  floorId: number,
  fallbackOffsets?: FloorStoredOffsets,
  rotate = true,
): Point | undefined => {
  const localstorageRotation = localStorage.getItem(
    `${LS_DESK_ROTATION}-${floorId}`,
  );
  const [x, y] = newGeom.getCoordinates();
  let geomToReturn = undefined as Point | undefined;
  // Get localstorage offset or fallback is the most often for this floor.
  let xOffset = 15;
  let yOffset = 30;

  const storedOffsets =
    localStorage.getItem(LS_DESK_OFFSETS) &&
    localStorage.getItem(LS_DESK_OFFSETS) !== 'undefined'
      ? JSON.parse(localStorage.getItem(LS_DESK_OFFSETS) || '{}')
      : {};
  // Specific case where offset is different for 3 head lamp's third sensor (saved at index 0 in localstorage)
  const localStorageOffset =
    storedOffsets?.[floorId]?.[length === 3 && sensorIdx === 3 ? 0 : length];
  // First try to retrieved localstorage offset value
  if (
    localStorageOffset &&
    typeof localStorageOffset[0] === 'number' &&
    typeof localStorageOffset[1] === 'number'
  ) {
    const [localXOffset, localYOffset] = localStorageOffset;
    xOffset = localXOffset;
    yOffset = localYOffset;
  } else {
    // Use fallback value of the most often used offset on the map
    const [offsetX, offsetY] =
      fallbackOffsets?.[
        length === 3 && sensorIdx === 3
          ? 0
          : (length as keyof FloorStoredOffsets)
      ] || [];
    if (typeof offsetX === 'number' && typeof offsetY === 'number') {
      xOffset = offsetX;
      yOffset = offsetY;
    }
  }

  if (typeof x === 'number' && typeof y === 'number') {
    if (length === 1) {
      geomToReturn = new Point([x, y + yOffset]);
    }
    if (length === 2) {
      if (sensorIdx === 1) {
        geomToReturn = new Point([x + xOffset, y + yOffset]);
      }
      if (sensorIdx === 2) {
        geomToReturn = new Point([x - xOffset, y + yOffset]);
      }
    }
    if (length === 3) {
      if (sensorIdx === 1) {
        geomToReturn = new Point([x + xOffset, y + yOffset]);
      }
      if (sensorIdx === 2) {
        geomToReturn = new Point([x - xOffset, y + yOffset]);
      }
      if (sensorIdx === 3) {
        geomToReturn = new Point([x, y - yOffset]);
      }
    }
    if (length === 4) {
      if (sensorIdx === 1) {
        geomToReturn = new Point([x + xOffset, y + yOffset]);
      }
      if (sensorIdx === 2) {
        geomToReturn = new Point([x - xOffset, y + yOffset]);
      }
      if (sensorIdx === 3) {
        geomToReturn = new Point([x - xOffset, y - yOffset]);
      }
      if (sensorIdx === 4) {
        geomToReturn = new Point([x + xOffset, y - yOffset]);
      }
    }
    if (geomToReturn && localstorageRotation && rotate) {
      geomToReturn.rotate(-Number.parseFloat(localstorageRotation), [x, y]);
    }
  }

  return geomToReturn;
};

export default getDeskGeom;
