import { Colors } from 'mda2-frontend/src/common/types';
import type { Color } from 'ol/color';

const getPrimaryColor = (color: number): string =>
  getComputedStyle(document.body)
    .getPropertyValue(`--color-primary-${color}`)
    .trim();

export const convertColorToRGB = (color: Colors) =>
  color
    .split(',')
    .map((c) => Number.parseInt(c, 10))
    .concat(1);

export const primaryColorToRGB = (
  primaryColor: 0 | 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900,
  opacity?: number,
): string => {
  const [r, g, b] = primaryColorToRGBArray(primaryColor, opacity);
  return opacity
    ? `rgba(${+(r ?? 0)},${+(g ?? 0)},${+(b ?? 0)}, ${opacity})`
    : `rgb(${+(r ?? 0)},${+(g ?? 0)},${+(b ?? 0)})`;
};

export const primaryColorToRGBArray = (
  primaryColor: 0 | 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900,
  opacity?: number,
): Color => {
  const [r, g, b] = getPrimaryColor(primaryColor).split(' ');

  if (!r || !g || !b) {
    console.error("Couldn't get primary color");
    return [0, 0, 0, opacity ?? 1];
  }

  return [
    Number.parseInt(r),
    Number.parseInt(g),
    Number.parseInt(b),
    opacity ?? 1,
  ];
};

const getColor = (color: keyof typeof Colors, opacity = '1'): string =>
  `rgba(${Colors[color]},${opacity})`;

export default getColor;
