import { scaleOrdinal } from '@visx/scale';
import { Tooltip } from '@visx/tooltip';
import { GeometryType } from 'common/types';
import { max } from 'date-fns';
import CleaningBeaconLayer, {
  type CleaningBeaconFeatureType,
} from 'generic/layers/CleaningBeaconLayer';
import GeometryLayer from 'generic/layers/GeometryLayer';
import { ModuleType, RoomTypes } from 'mda2-frontend/src/common/types';
import Card from 'mda2-frontend/src/generic/components/Card';
import Legend from 'mda2-frontend/src/generic/components/Chart/Legend';
import Map from 'mda2-frontend/src/generic/components/Map';
import { Types } from 'mda2-frontend/src/generic/components/Overview/OverviewRow';
import PrivateWrapper from 'mda2-frontend/src/generic/components/PrivateWrapper';
import Loader from 'mda2-frontend/src/generic/components/layout/BarLoader';
import BaseLayer from 'mda2-frontend/src/generic/layers/BaseLayer';
import CleaningLayer, {
  type CleaningFeatureType,
} from 'mda2-frontend/src/generic/layers/CleaningLayer';
import {
  type BeaconInfos,
  type CleaningModeActiveQuery,
  type MqttSystems,
  useCleaningModeActiveQuery,
  useCleaningOccupancyMapQuery,
  useFloorQuery,
} from 'mda2-frontend/src/graphql/types';
import defaultFloorplan from 'mda2-frontend/src/img/default_floorplan.jpg';
import getColor, { primaryColorToRGB } from 'mda2-frontend/src/utils/getColor';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import usePolling from 'mda2-frontend/src/utils/graphql/usePolling';
import useRoomDeskFilter from 'mda2-frontend/src/utils/graphql/useRoomDeskFilter';
import parseBluerangeTopic from 'mda2-frontend/src/utils/parseBluerangeTopic';
import useDeviceDetect from 'mda2-frontend/src/utils/useDeviceDetect';
import useStore from 'model/store';
import OLMap from 'ol/Map';
import type MapBrowserEvent from 'ol/MapBrowserEvent';
import { touchOnly } from 'ol/events/condition';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import { useEffect, useMemo, useState } from 'react';
import { createIntl, createIntlCache } from 'react-intl';
import translations from 'translations';
import { useIntl } from 'translations/Intl';
import groupBy from 'utils/groupBy';
import CleaningActionButton from './components/CleaningActionButton';
import { isBeingCleaned } from './components/CleaningActionButton/CleaningActionButton';
import CleaningModal, {
  type CleaningBeaconInfo,
  type DeskSharingRoom,
  type MqttResponse,
} from './components/CleaningModal/CleaningModal';
import CleaningPopup, {
  type RoomCleaningFeatureType,
  isCleaningBeacon,
  isRoomCleaning,
} from './components/CleaningPopup/CleaningPopup';

const cache = createIntlCache();

const translation = createIntl(
  {
    locale: useStore.getState().userSettings.language,
    messages: translations[useStore.getState().userSettings.language],
  },
  cache,
);

export default function CleaningMap(): JSX.Element {
  const { isMobile } = useDeviceDetect();
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const [baseLayer] = useState(new BaseLayer());
  const [cleaningLayer] = useState(new CleaningLayer());
  const [cleaningBeaconLayer] = useState(new CleaningBeaconLayer());
  const [roomLayer] = useState(
    new GeometryLayer<DeskSharingRoom>({
      style: (feat, _, hoveredFeat) => [
        new Style({
          stroke: new Stroke({
            color: getColor(
              isBeingCleaned(feat.getProperties().CleaningDuration)
                ? 'YELLOW'
                : 'NEUTRAL600',
              feat.getGeometry() === hoveredFeat?.getGeometry() ? '.9' : '.8',
            ),
            lineDash:
              feat.getGeometry() === hoveredFeat?.getGeometry()
                ? [5, 5]
                : undefined,
            width: feat.getGeometry() === hoveredFeat?.getGeometry() ? 3 : 2,
          }),
          fill: new Fill({
            color: getColor(
              isBeingCleaned(feat.getProperties().CleaningDuration)
                ? 'YELLOW'
                : 'NEUTRAL200',
              feat.getGeometry() === hoveredFeat?.getGeometry() ? '.9' : '.3',
            ),
          }),
          text: isBeingCleaned(feat.getProperties().CleaningDuration)
            ? new Text({
                font: 'bold 14px sans-serif',
                fill: new Fill({
                  color: 'white',
                }),
                text: translation.formatMessage({ id: 'Cleaning in progress' }),
              })
            : undefined,
        }),
      ],
    }),
  );
  const [cleaningModalOpen, setCleaningModalOpen] = useState(false);
  const [roomCleaningModalOpen, setRoomCleaningModalOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [cleaningResponse, setCleaningResponse] = useState<{
    cleaning: MqttResponse;
    info: BeaconInfos[];
  } | null>(null);
  const [mqttCleaningInfos, setMqttCleaningInfos] = useState<
    CleaningBeaconInfo[] | null
  >(null);
  const [mqttSystem, setMqttSystem] = useState<MqttSystems | null>(null);
  const building = useStore((state) => state.userSettings.building);
  const floor = useStore((state) => state.userSettings.floor);
  const cleaningTimes = useStore(
    (state) => state.organizationSettings.cleaningTimes,
  );
  const userRoles = useStore((state) => state.user)?.roles;
  const { check: timeBeforeCheck, clean: timeBeforeClean } = cleaningTimes;
  const [layers] = useState([
    baseLayer,
    roomLayer,
    cleaningLayer,
    cleaningBeaconLayer,
  ]);
  const [map] = useState(new OLMap({}));
  const [hoveredFeature, setHoveredFeature] = useState<
    | CleaningFeatureType
    | CleaningBeaconFeatureType
    | RoomCleaningFeatureType
    | null
  >(null);

  const [
    { data: cleaningData, fetching: loadingCleaning },
    reexecuteCleaningQuery,
  ] = useCleaningOccupancyMapQuery({
    variables: {
      Building: building?.Name,
      Floor: floor?.Number,
      ...useRoomDeskFilter(),
      ModuleTypes: Object.values(ModuleType),
    },
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.VIEW_CLEANING),
      [hasuraHeader],
    ),
    pause: typeof floor?.Number !== 'number' || !building?.Name,
  });

  const [{ data: cleaningBeacons, fetching: loadingCleaningBeacons }] =
    useCleaningModeActiveQuery({
      variables: {
        SuccessIds: cleaningResponse?.cleaning?.successResponses.map(
          (s) => s?.topic ?? '',
        ),
        FailureIds: cleaningResponse?.cleaning?.failedResponses.map(
          (s) => s?.topic ?? '',
        ),
      },
      pause: !cleaningResponse,
    });

  // Materialized view is being refreshed every 10 minutes
  // use 5 minutes polling so the user has at most 10 minute old data
  usePolling(loadingCleaning, reexecuteCleaningQuery, 1000 * 60 * 5);

  const [{ data: floorImageData, fetching: imageLoading }] = useFloorQuery({
    variables: {
      BuildingName: building?.Name,
      FloorNumber: floor?.Number,
    },
    pause: typeof floor?.Number !== 'number' || !building?.Name,
  });

  const isCleaningFloor = useMemo(() => {
    const fl = cleaningData?.Floors[0];
    return !!fl && isBeingCleaned(fl.CleaningDuration);
  }, [cleaningData?.Floors[0]]);

  useEffect(() => {
    cleaningLayer.setColors({
      toCleanColor: primaryColorToRGB(600),
      toCheckColor: primaryColorToRGB(400),
    });
  }, [cleaningLayer]);

  useEffect(() => {
    if (floorImageData?.Floors[0]?.Image) {
      baseLayer.setImage(floorImageData.Floors[0]?.Image ?? '');
    }
    // Set a dummy room if there are no floors added yet
    if (floorImageData && floorImageData.Floors.length === 0) {
      baseLayer.setDefault(defaultFloorplan);
    }
  }, [baseLayer, floorImageData, floorImageData?.Floors[0]?.Image]);

  useEffect(() => {
    cleaningLayer.timeBeforeCheck = timeBeforeCheck;
    cleaningLayer.timeBeforeClean = timeBeforeClean;

    cleaningLayer.olLayer.changed();
  }, [cleaningLayer, timeBeforeCheck, timeBeforeClean]);

  useEffect(() => {
    if (!loadingCleaning && !imageLoading && !loadingCleaningBeacons) {
      setDataLoaded(true);
    } else {
      // Polling is only every five minutes so seeing the loading bar
      // again should be ok
      setDataLoaded(false);
    }
  }, [imageLoading, loadingCleaning, loadingCleaningBeacons]);

  useEffect(() => {
    if (cleaningData && floorImageData?.Floors[0]?.Image) {
      const cleanDeskFeatIds = cleaningData.f_live_desks_cleaning?.map(
        (c) => c.Id,
      );
      const cleanRoomFeatNames = cleaningData.f_live_rooms_cleaning?.map(
        (c) => c.Desk,
      );
      const cleaningDesksFeats = [
        ...(cleaningData.f_live_desks_cleaning?.map((d) => ({
          ...d,
          Radius: d.Radius ?? 10,
        })) ?? []),
        // Add desk and rooms not yet used
        ...(cleaningData.Desks?.filter(
          (d) => !cleanDeskFeatIds?.includes(d.Id),
        ).map((d) => ({
          Id: d.Id,
          // If it is a private sensor then use the hot minutes of the corresponding room
          HotMinutes: d.Sensor?.IsPrivate
            ? (cleaningData.f_live_desks_cleaning?.find(
                (deskToClean) =>
                  deskToClean.Id === d.Sensor?.RoomSensors[0]?.RoomsId,
              )?.HotMinutes ?? 0)
            : 0,
          Geometry: d.Geometry,
          Desk: d.Name,
          IsOffline: !!d.Sensor?.MqttBeacon.IsOffline,
          LastHeartbeat: new Date(d.Sensor?.MqttBeacon.LastHeartbeat),
          LastCleaned: d.LastCleaned,
          Radius: d.Radius,
        })) ?? []),
      ];

      const cleaningRoomSensorsFeats = [
        ...(cleaningData.f_live_rooms_cleaning ?? []),
        ...(cleaningData.Rooms?.filter(
          (d) =>
            !cleanRoomFeatNames?.includes(d.Name) &&
            d.RoomType.Name === RoomTypes.MEETING &&
            // Hide rooms that don't have any sensors as we do not know their state
            d.RoomSensors.length,
        ).map((r) => ({
          Desk: r.Name,
          Geometry: r.Geometry,
          HotMinutes: 0,
          LastCleaned: r.LastCleaned,
          LastHeartbeat: max(
            r.RoomSensors.map(
              (rS) => new Date(rS.Sensor.MqttBeacon.LastHeartbeat),
            ),
          ),
          IsOffline: r.RoomSensors.every(
            (rS) => rS.Sensor.MqttBeacon.IsOffline,
          ),
        })) ?? []),
      ];

      const cleaningRoomsFeats = Object.values<typeof cleaningRoomSensorsFeats>(
        groupBy(cleaningRoomSensorsFeats, (i) => i.Desk),
      ).map((arr) =>
        arr.reduce((acc, curr) => ({
          // biome-ignore lint/performance/noAccumulatingSpread: noAccumulatingSpread
          ...acc,
          HotMinutes: acc.HotMinutes + curr.HotMinutes,
        })),
      );

      // Beacons infos to set cleaning Scene with mqtt setter function.
      const newMqttCleaningInfos = cleaningData.MqttBeacons.map((mB) => {
        const { MqttTopic } = mB;
        const relatedDesks = mB.Sensors.map((s) =>
          cleaningData.Desks?.find((d) => d.Sensor?.Id === s.Id),
        )
          .filter((item) => item)
          .map((d) => {
            const deskUsage = cleaningData.f_live_desks_cleaning?.find(
              (deskToClean) => deskToClean.Id === d?.Id,
            );
            return {
              id: d?.Id ?? 0,
              index: d?.Sensor?.Index ?? 0,
              hotMinutes: deskUsage?.HotMinutes ?? 0,
              lastCleaned: d?.LastCleaned ?? new Date().toString(),
            };
          });

        // Get all private desks that have the same name/id as a room from a beacon and hotminutes
        // and find corresponding beacon
        const privateDesks = cleaningData?.privateDesks
          ?.filter((d) => d.Sensor?.MqttBeacon.Id === mB.Id)
          .map((privateDesk) => ({
            id: privateDesk.Id,
            index: privateDesk.Sensor?.Index ?? -1,
            hotMinutes:
              cleaningData.f_live_desks_cleaning?.find(
                (deskToClean) =>
                  deskToClean.Id ===
                  privateDesk.Sensor?.RoomSensors[0]?.Room.Id,
              )?.HotMinutes ?? 0,
            lastCleaned: privateDesk.LastCleaned,
          }));

        return {
          beaconTopic: parseBluerangeTopic(MqttTopic),
          data: [4, 3, 2, 1] // bytes order is backward
            .map((index) =>
              ([...relatedDesks, ...(privateDesks ?? [])].find(
                (rD) => rD.index === index,
              )?.hotMinutes ?? 0) >= cleaningTimes.clean
                ? '1'
                : '0',
            )
            .join(''),
          relatedDesks: [...relatedDesks, ...(privateDesks ?? [])],
        };
      });
      setMqttCleaningInfos(newMqttCleaningInfos);
      const mqttInfos = cleaningData.MqttBeacons.find(
        (mB) => mB.MqttTopic && mB.MqttBeaconSource.Name,
      );
      if (mqttInfos) {
        setMqttSystem(mqttInfos.MqttBeaconSource.Name as MqttSystems);
      }
      cleaningLayer.setFeatures([...cleaningDesksFeats, ...cleaningRoomsFeats]);
    } else {
      setMqttSystem(null);
      setMqttCleaningInfos(null);
      cleaningLayer.setFeatures([]);
    }
  }, [
    cleaningData,
    cleaningLayer,
    cleaningTimes.clean,
    floorImageData?.Floors[0]?.Image,
  ]);

  useEffect(() => {
    // First get the data of the beacon in order to get the correct desk index
    // With desk index we can get the desk geometry and name
    const getDeskIndexForBeacon = (
      beacon: CleaningModeActiveQuery['success'][number],
    ) =>
      Number.parseInt(
        cleaningResponse?.info
          .filter((c) => !c.data.includes('0000'))
          .find((m) => m.beaconTopic.includes(beacon.UniqueIdentifier))
          ?.data[0] ?? '0',
        2,
      );

    const getDesk = (beacon: CleaningModeActiveQuery['success'][number]) =>
      beacon.Sensors.find((s) => s.Index === getDeskIndexForBeacon(beacon));

    if (cleaningBeacons) {
      const cleaningBeaconData = [
        ...cleaningBeacons.success.map((s) => ({
          Name: getDesk(s)?.Desk?.Name ?? '',
          Geometry: getDesk(s)?.Desk?.Geometry ?? {
            type: GeometryType.POLYGON,
            coordinates: [[[0, 0]]],
          },
          Radius: getDesk(s)?.Desk?.Radius ?? 1,
          Success: true,
        })),
        ...cleaningBeacons.failure.map((s) => ({
          Name: getDesk(s)?.Desk?.Name ?? '',
          Geometry: getDesk(s)?.Desk?.Geometry ?? {
            type: GeometryType.POLYGON,
            coordinates: [[[0, 0]]],
          },
          Radius: getDesk(s)?.Desk?.Radius ?? 1,
          Success: false,
        })),
      ];
      cleaningBeaconLayer.setFeatures(cleaningBeaconData);
    }
    // When setting the scene "NONE" then the cleaningResponse will be null
    // and the map shouldn't display the beacons anymore
    if (!cleaningResponse) {
      cleaningBeaconLayer.setFeatures([]);
    }
  }, [cleaningBeaconLayer, cleaningBeacons, cleaningResponse]);

  useEffect(() => {
    if (cleaningData?.desksharingRooms) {
      roomLayer.setFeatures(cleaningData.desksharingRooms);
    } else {
      roomLayer.setFeatures([]);
    }
  }, [cleaningData, cleaningData?.desksharingRooms, roomLayer]);

  const showFeatureInfo = (
    evt: MapBrowserEvent<PointerEvent>,
    feat?:
      | CleaningFeatureType
      | CleaningBeaconFeatureType
      | RoomCleaningFeatureType,
  ) => {
    if (feat) {
      setHoveredFeature(feat);
      if (isCleaningBeacon(feat)) {
        cleaningBeaconLayer.hoveredFeature = feat;
        cleaningBeaconLayer.olLayer.changed();
      } else if (isRoomCleaning(feat)) {
        roomLayer.hoveredFeature = feat;
        roomLayer.olLayer.changed();
      } else {
        cleaningLayer.hoveredFeature = feat;
        cleaningLayer.olLayer.changed();
      }
    } else {
      setHoveredFeature(null);
      cleaningLayer.hoveredFeature = undefined;
      cleaningBeaconLayer.hoveredFeature = undefined;
      roomLayer.hoveredFeature = undefined;
      cleaningLayer.olLayer.changed();
      cleaningBeaconLayer.olLayer.changed();
      roomLayer.olLayer.changed();
    }

    evt.map.getTargetElement().style.cursor =
      feat &&
      isRoomCleaning(feat) &&
      userRoles?.includes(HasuraPermissions.WRITE_CLEANING)
        ? 'pointer'
        : '';
  };

  return (
    <Card className="relative" fullScreenButton>
      <Loader loading={loadingCleaning || imageLoading} />
      <div className="flex justify-between flex-col md:flex-row gap-2 md:gap-0 pb-2">
        <div className="flex text-xs">
          <Legend
            scaleType="ordinal"
            labelFormat={(d) =>
              intl.formatMessage({
                id: d,
              })
            }
            scale={scaleOrdinal({
              domain: [Types.CHECK, Types.TOCLEAN, Types.CLEAN],
              range: [
                primaryColorToRGB(400),
                primaryColorToRGB(600),
                getColor('NEUTRAL300'),
              ],
            })}
          />
        </div>
        {floor && cleaningData?.Floors && cleaningData.Floors.length > 0 && (
          <PrivateWrapper roleRequired={HasuraPermissions.WRITE_CLEANING}>
            <>
              <CleaningActionButton
                cleaningDuration={cleaningData.Floors[0]?.CleaningDuration}
                onClick={() => setCleaningModalOpen(!cleaningModalOpen)}
              />
              <CleaningModal
                cleaningItem={cleaningData.Floors[0] ?? { Id: 0, Number: 0 }}
                open={cleaningModalOpen}
                setOpen={setCleaningModalOpen}
                isCleaningActive={isCleaningFloor}
                mqttCleaningInfos={mqttCleaningInfos}
                mqttSystem={mqttSystem}
                setCleaningResponse={setCleaningResponse}
              />
            </>
          </PrivateWrapper>
        )}
      </div>
      <Map<
        | CleaningFeatureType
        | CleaningBeaconFeatureType
        | RoomCleaningFeatureType
      >
        map={map}
        className="h-96"
        layers={layers}
        isLoadingFeatures={!dataLoaded}
        onFeaturesClick={(features, evt) => {
          if (touchOnly(evt)) {
            showFeatureInfo(evt, features[0]);
          }

          if (
            userRoles?.includes(HasuraPermissions.WRITE_CLEANING) &&
            features[0] &&
            isRoomCleaning(features[0])
          ) {
            setRoomCleaningModalOpen(!roomCleaningModalOpen);
          }
        }}
        onFeaturesHover={(hoveredFeatures, evt) => {
          const [feat] = hoveredFeatures.map((hF) => hF.feature);
          showFeatureInfo(evt, feat);
        }}
        renderTooltip={(props) => {
          if (!hoveredFeature) return undefined;

          if (isMobile) {
            return (
              <Card className="absolute bottom-0 right-0 left-0 z-20">
                <CleaningPopup
                  hoveredFeature={hoveredFeature}
                  timeBeforeClean={timeBeforeClean}
                  timeBeforeCheck={timeBeforeCheck}
                />
              </Card>
            );
          }
          return (
            <Tooltip {...props}>
              <CleaningPopup
                hoveredFeature={hoveredFeature}
                timeBeforeClean={timeBeforeClean}
                timeBeforeCheck={timeBeforeCheck}
              />
            </Tooltip>
          );
        }}
      />

      {hoveredFeature &&
        isRoomCleaning(hoveredFeature) &&
        cleaningData?.desksharingRooms &&
        cleaningData.desksharingRooms.length > 0 && (
          <PrivateWrapper roleRequired={HasuraPermissions.WRITE_CLEANING}>
            <CleaningModal
              cleaningItem={hoveredFeature.getProperties()}
              isCleaningActive={isBeingCleaned(
                hoveredFeature.getProperties().CleaningDuration,
              )}
              open={roomCleaningModalOpen}
              setOpen={setRoomCleaningModalOpen}
              mqttCleaningInfos={
                mqttCleaningInfos?.filter((m) =>
                  cleaningData.desksharingRooms?.filter((d) =>
                    d.RoomSensors.filter(
                      (rs) => m.beaconTopic === rs.Sensor.MqttBeacon.MqttTopic,
                    ),
                  ),
                ) ?? null
              }
              mqttSystem={mqttSystem}
              setCleaningResponse={setCleaningResponse}
            />
          </PrivateWrapper>
        )}
    </Card>
  );
}
