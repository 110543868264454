import type { SliderItem } from 'react-compound-slider';
import { ClimateStatus } from 'utils/getClimateStatus';

interface TrackProps {
  source: SliderItem;
  target: SliderItem;
  state: ClimateStatus;
  disabled?: boolean;
}

export default function Track({
  source,
  target,
  state,
  disabled = false,
}: TrackProps) {
  return (
    <div
      className={`absolute -translate-y-1/2 h-1 z-0 ${
        disabled ? 'bg-neutral-600' : ''
      } ${state === ClimateStatus.GOOD ? 'bg-green-500' : ''} ${
        state === ClimateStatus.ACCEPTABLE ? 'bg-yellow-500' : ''
      } ${
        state === ClimateStatus.POOR ? 'bg-red-500' : ''
      } rounded-md cursor-pointer`}
      style={{
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
    />
  );
}
