import type { ReactNode } from 'react';
import { HiOutlineBellAlert, HiOutlineXMark } from 'react-icons/hi2';

interface BannerProps {
  setOpen?: (open: boolean) => void;
  children: ReactNode;
  className?: string;
}

export default function Banner({ setOpen, children, className }: BannerProps) {
  return (
    <div className={`bg-primary-500 dark:bg-primary-600 ${className}`}>
      <div className="py-3 px-3 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-0 flex-1 flex items-center space-x-2">
            <span className="flex p-2 rounded-lg bg-primary-800">
              <HiOutlineBellAlert
                className="size-6 text-white"
                aria-hidden="true"
              />
            </span>
            <div>{children}</div>
          </div>
          {setOpen && (
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
              <button
                type="button"
                className="-mr-1 flex p-2 rounded-md hover:bg-primary-600 hover:dark:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
              >
                <HiOutlineXMark
                  className="size-6 text-white"
                  onClick={() => setOpen(false)}
                />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
