import Card from 'generic/components/Card';
import AboutView from 'pages/AboutView/AboutView';
import AdminView from 'pages/AdminView/AdminView';
import BuildingView from 'pages/AdminView/BuildingView';
import AnalyticsView from 'pages/AnalyticsView/AnalyticsView';
import CleaningChart from 'pages/AnalyticsView/components/CleaningChart';
import ClimateChart from 'pages/AnalyticsView/components/ClimateChart';
import DeskAnalyticsCharts from 'pages/AnalyticsView/components/DeskAnalyticsCharts/DeskAnalyticsCharts';
import RoomsAnalyticsCharts from 'pages/AnalyticsView/components/RoomsAnalyticsCharts/RoomsAnalyticsCharts';
import CleaningView from 'pages/CleaningView/CleaningView';
import ClimateView from 'pages/ClimateView/ClimateView';
import DashboardView from 'pages/DashboardView/DashboardView';
import BuildingOccupancy from 'pages/DashboardView/components/LiveOccupancy/BuildingOccupancy';
import RoomOccupancy from 'pages/DashboardView/components/LiveOccupancy/BuildingOccupancy/components/FloorOccupancy/components/RoomOccupancy';
import FindMyAssetView from 'pages/FindMyAssetView/FindMyAssetView';
import FindMyPlaceView from 'pages/FindMyPlaceView/FindMyPlaceView';
import OccupancyMap from 'pages/FindMyPlaceView/components/OccupancyMap';
import ReservationList from 'pages/FindMyPlaceView/components/ReservationList';
import ReservationOverview from 'pages/FindMyPlaceView/components/ReservationOverview';
import DefectiveBeacons from 'pages/ReportingView/DefectiveBeacons';
import ReportingView from 'pages/ReportingView/ReportingView';
import SettingsView from 'pages/SettingsView/SettingsView';
import FirmwaresView from 'pages/SettingsView/components/FirmwaresView';
import GroupManagementView from 'pages/SettingsView/components/GroupManagementView';
import OrganizationManagementView from 'pages/SettingsView/components/OrganizationManagementView';
import OrganizationView from 'pages/SettingsView/components/OrganizationView/OrganizationView';
import SensorPolicyView from 'pages/SettingsView/components/SensorPolicyView/SensorPolicyView';
import UserManagementView from 'pages/SettingsView/components/UserManagementView';
import UserView from 'pages/SettingsView/components/UserView';
import StatusView from 'pages/StatusView/StatusView';
import type { IntlMessageKeys } from 'translations/Intl';

import { HasuraPermissions } from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import DeviceMappingView from 'pages/DeviceMappingView';
import Reports from 'pages/ReportingView/Reports';
import {
  HiBars4,
  HiGlobeEuropeAfrica,
  HiMap,
  HiOutlineDocumentMagnifyingGlass,
  HiOutlineSparkles,
  HiTableCells,
} from 'react-icons/hi2';
import type { IconType } from 'react-icons/lib';

type RouteString =
  | '' // Hack for index routes
  | 'admin/:buildingName'
  | 'admin/:buildingName/:floorNumber'
  | 'admin/:buildingName/:floorNumber/:roomName'
  | 'management'
  | 'group'
  | 'management/group'
  | 'status'
  | 'findmyasset'
  | 'findmyplace'
  | 'reservations/new'
  | 'findmyplace/reservations/new'
  | 'reservations/list'
  | 'findmyplace/reservations/list'
  | 'dashboard'
  | 'analytics'
  | 'analytics/rooms'
  | 'cleaning'
  | 'analytics/cleaning'
  | 'climate'
  | 'analytics/climate'
  | 'offline'
  | 'cleaning'
  | 'myclimate'
  | 'user'
  | 'about'
  | 'reporting'
  | 'reporting/reports'
  | 'reports'
  | 'firmwares'
  | 'offline'
  | 'settings'
  | 'sensors'
  | 'organization'
  | 'organizations'
  | 'mapping'
  | 'buildingoccupancy'
  | 'rooms'
  | 'occupancymap'
  | 'admin';

interface SubRoute extends Route {
  icon?: IconType;
  description?: IntlMessageKeys;
}

export interface Route {
  path: RouteString;
  component: JSX.Element;
  end?: boolean; // for react-router NavLink
  userRole: HasuraPermissions | HasuraPermissions[];
  name?: IntlMessageKeys;
  subMenu?: SubRoute[];
  dataTestId?: string;
}

export const ADMIN_ROUTES: Route[] = [
  {
    path: 'admin/:buildingName',
    userRole: HasuraPermissions.VIEW_ADMIN,
    component: <BuildingView />,
  },
  {
    path: 'admin/:buildingName/:floorNumber',
    userRole: HasuraPermissions.VIEW_ADMIN,
    component: <BuildingView />,
  },
  {
    path: 'admin/:buildingName/:floorNumber/:roomName',
    userRole: HasuraPermissions.VIEW_ADMIN,
    component: <BuildingView />,
  },
];

export const BUBBLE_ROUTES: Route[] = [
  {
    name: 'Settings',
    path: 'settings',
    userRole: HasuraPermissions.READ,
    component: <SettingsView />,
    subMenu: [
      {
        path: '',
        end: true,
        component: <UserView />,
        userRole: HasuraPermissions.READ,
      },
      {
        path: 'organization',
        component: <OrganizationView />,
        userRole: HasuraPermissions.WRITE_ORGANIZATION,
      },
      {
        path: 'mapping',
        component: <DeviceMappingView />,
        userRole: HasuraPermissions.WRITE_MQTTMAPPING,
      },
      {
        path: 'sensors',
        component: <SensorPolicyView />,
        userRole: HasuraPermissions.WRITE_ORGANIZATION,
      },
      {
        path: 'firmwares',
        component: <FirmwaresView />,
        userRole: HasuraPermissions.READ_ALL,
      },
      {
        path: 'user',
        component: <UserManagementView />,
        userRole: HasuraPermissions.VIEW_USERGROUP,
      },

      {
        path: 'group',
        component: <GroupManagementView />,
        userRole: HasuraPermissions.VIEW_USERGROUP,
      },
      {
        path: 'organizations',
        component: <OrganizationManagementView />,
        userRole: HasuraPermissions.VIEW_ORGANIZATIONS,
      },
    ],
  },
  {
    name: 'Status',
    path: 'status',
    userRole: HasuraPermissions.VIEW_STATUS,
    dataTestId: 'status-button',
    component: <StatusView />,
  },
  {
    name: 'Reporting',
    path: 'reporting',
    userRole: HasuraPermissions.VIEW_ADMIN,
    component: <ReportingView />,
    subMenu: [
      {
        path: '',
        end: true,
        component: <DefectiveBeacons />,
        userRole: HasuraPermissions.VIEW_ADMIN,
      },
      {
        path: 'reports',
        component: <Reports />,
        // TODO: Use VIEW_ANALYTICS when ready
        userRole: HasuraPermissions.VIEW_REPORTS,
      },
    ],
  },
  {
    name: 'About',
    path: 'about',
    userRole: HasuraPermissions.READ,
    component: <AboutView />,
  },
];

export const SERVICES_ROUTES: Route[] = [
  {
    name: 'Dashboard',
    path: 'dashboard',
    userRole: HasuraPermissions.VIEW_DASHBOARD,
    component: <DashboardView />,
  },
  {
    name: 'Find My Place',
    path: 'findmyplace',
    userRole: HasuraPermissions.VIEW_FINDMYPLACE,
    component: <FindMyPlaceView />,
    subMenu: [
      {
        icon: HiMap,
        name: 'Live Map',
        path: '',
        end: true,
        userRole: HasuraPermissions.VIEW_FINDMYPLACE,
        description: 'View open spaces',
        component: (
          <Card className="relative" fullScreenButton>
            <OccupancyMap />
          </Card>
        ),
      },
      {
        icon: HiTableCells,
        name: 'New Reservation',
        path: 'reservations/new',
        userRole: HasuraPermissions.VIEW_RESERVATION,
        description: 'Reserve a space',
        component: <ReservationOverview />,
      },
      {
        icon: HiBars4,
        name: 'Reservations',
        path: 'reservations/list',
        userRole: HasuraPermissions.VIEW_RESERVATION,
        description: 'List all reservations',
        component: (
          <Card>
            <ReservationList />
          </Card>
        ),
      },
    ],
  },
  {
    name: 'Find My Asset',
    path: 'findmyasset',
    userRole: HasuraPermissions.VIEW_FINDMYASSET,
    component: <FindMyAssetView />,
  },
  {
    name: 'Analytics',
    path: 'analytics',
    userRole: HasuraPermissions.VIEW_ANALYTICS,
    component: <AnalyticsView />,
    subMenu: [
      {
        icon: HiOutlineDocumentMagnifyingGlass,
        name: 'Desk Occupancy',
        path: '',
        end: true,
        userRole: HasuraPermissions.VIEW_ANALYTICS,
        description: 'Show desk occupancy over time',
        component: <DeskAnalyticsCharts />,
      },
      {
        icon: HiOutlineDocumentMagnifyingGlass,
        name: 'Meeting Room Occupancy',
        path: 'rooms',
        userRole: HasuraPermissions.VIEW_ANALYTICS,
        description: 'Show room occupancy over time',
        component: <RoomsAnalyticsCharts />,
      },
      {
        icon: HiOutlineSparkles,
        name: 'Cleaning',
        path: 'cleaning',
        userRole: HasuraPermissions.VIEW_CLEANING,
        description: 'Analyze the cleaning needs',
        component: <CleaningChart />,
      },
      {
        icon: HiGlobeEuropeAfrica,
        name: 'Climate',
        path: 'climate',
        userRole: HasuraPermissions.VIEW_CLIMATE,
        description: 'See how the room climate was',
        component: <ClimateChart />,
      },
    ],
  },
  {
    name: 'Cleaning',
    path: 'cleaning',
    userRole: HasuraPermissions.VIEW_CLEANING,
    component: <CleaningView />,
  },
  {
    name: 'My Climate',
    path: 'myclimate',
    userRole: HasuraPermissions.VIEW_CLIMATE,
    component: <ClimateView />,
  },
  {
    name: 'Admin',
    path: 'admin',
    userRole: HasuraPermissions.VIEW_ADMIN,
    dataTestId: 'admin-button',
    component: <AdminView />,
  },
];

export const COMPONENT_ROUTES: Route[] = [
  {
    path: 'buildingoccupancy',
    userRole: HasuraPermissions.READ,
    component: <BuildingOccupancy />,
  },
  {
    path: 'occupancymap',
    userRole: HasuraPermissions.READ,
    component: <OccupancyMap />,
  },
  {
    path: 'rooms',
    userRole: HasuraPermissions.READ,
    component: <RoomOccupancy />,
  },
];
