import ReportClimateValues from './components/ReportClimateValues';
import ReportDescription from './components/ReportDescription';
import ReportDeskLabelOccupancy from './components/ReportDeskLabelOccupancy';
import ReportDownload from './components/ReportDownload';
import ReportFixOccupancy from './components/ReportFixOccupancy';
import ReportFlexOccupancy from './components/ReportFlexOccupancy';
import ReportGlossary from './components/ReportGlossary';
import ReportRoomLabelOccupancy from './components/ReportRoomLabelOccupancy';
import ReportRoomSize from './components/ReportRoomSize';
import ReportRoomSizeOccupancy from './components/ReportRoomSizeOccupancy';
import ReportSettings from './components/ReportSettings';
import ReportSummary from './components/ReportSummary';

export const REPORTING_RIGHT_MARGIN = 64;

export default function Reports() {
  return (
    <div className="space-y-4 print:space-y-0 w-full">
      <ReportSettings />
      <ReportDescription />
      <ReportSummary />
      <ReportGlossary />
      <ReportFlexOccupancy />
      <ReportFixOccupancy />
      <ReportRoomSizeOccupancy />
      <ReportRoomSize />
      <ReportClimateValues />
      <ReportRoomLabelOccupancy />
      <ReportDeskLabelOccupancy />
      <div className="flex w-full justify-end print:hidden">
        <ReportDownload />
      </div>
    </div>
  );
}
