import type { Point } from '@visx/brush/lib/types';
import { Group } from '@visx/group';
import { ParentSize } from '@visx/responsive';
import { scaleOrdinal } from '@visx/scale';
import { Pie } from '@visx/shape';
import { defaultStyles, useTooltip, useTooltipInPortal } from '@visx/tooltip';
import AnimatedPie from 'generic/components/Chart/AnimatedPie';
import { ModuleType, Themes } from 'mda2-frontend/src/common/types';
import { RAINBOW } from 'mda2-frontend/src/constants';
import LoadingSpinner from 'mda2-frontend/src/generic/components/LoadingSpinner';
import type { RoomOccupancySensorCountQuery } from 'mda2-frontend/src/graphql/types';
import useStore from 'model/store';
import { useMemo } from 'react';
import { useIntl } from 'translations/Intl';
import getColor from 'utils/getColor';

interface ResponsiveRoomOccupancyDistributionProps {
  margin?: { top: number; right: number; bottom: number; left: number };
  roomOccupancySensorCount?: RoomOccupancySensorCountQuery['f_history_room_occupancy_15m'];
  loadingRoomOccupancySensorCount: boolean;
}

interface RoomOccupancyDistributionProps
  extends ResponsiveRoomOccupancyDistributionProps {
  width: number;
  height: number;
}

interface Duration {
  duration: number;
  value: number;
}

interface Duration {
  duration: number;
  value: number;
}

interface Data {
  occupancy: number;
  percentage: number;
}

function RoomOccupancyDistribution({
  width,
  height,
  margin = {
    top: 60,
    left: 0,
    bottom: 0,
    right: 0,
  },
  roomOccupancySensorCount,
  loadingRoomOccupancySensorCount,
}: RoomOccupancyDistributionProps) {
  const intl = useIntl();
  const theme = useStore((state) => state.userSettings.theme);

  const data: Data[] = useMemo(() => {
    if (roomOccupancySensorCount && roomOccupancySensorCount.length > 0) {
      const sortedData = roomOccupancySensorCount
        .map((sensorData) => ({
          ...sensorData,
          Date: new Date(sensorData.Date).getTime(),
        }))
        .sort((a, b) => a.Date - b.Date);

      const aggretatedOnDistinctDates = [
        ...new Set(sortedData.map((sD) => sD.Date)),
      ].map((date) => {
        const areaData = sortedData
          .filter(
            (sD) => sD.Date === date && sD.SensorType === ModuleType.AREACOUNT,
          )
          .reduce((acc, curr) => acc + curr.Value, 0);

        const lineData = sortedData
          .filter(
            (sD) => sD.Date === date && sD.SensorType !== ModuleType.AREACOUNT,
          )
          .reduce(
            (acc, curr) =>
              acc +
              curr.Value *
                (curr.Sign *
                  (curr.SensorType === ModuleType.LINECOUNT_IN ? 1 : -1)),
            0,
          );

        return {
          Date: date,
          // clc_areacount is more reliable, so prefer that data
          Value: areaData ? areaData : lineData,
        };
      });

      const timeSlots = [] as Duration[];
      // Create time slots with corresponding occupancy
      for (let i = 0; i < aggretatedOnDistinctDates.length - 1; i++) {
        const start = aggretatedOnDistinctDates[i]?.Date ?? 0;
        const end = aggretatedOnDistinctDates[i + 1]?.Date ?? 0;
        const duration = start && end ? end - start : 0;
        timeSlots.push({
          duration,
          value: aggretatedOnDistinctDates[i]?.Value ?? 0,
        });
      }
      // Should calculate the total occupied duration
      const totalOccupiedDuration = timeSlots
        .filter((d) => d.value !== 0)
        .reduce((acc, curr) => acc + curr.duration, 0);

      const occupancyDistinctValues = [
        ...new Set(aggretatedOnDistinctDates.map((d) => d.Value)),
      ].filter((v) => v !== 0);

      return occupancyDistinctValues
        .map((occupancy) => ({
          occupancy,
          percentage:
            timeSlots
              .filter((d) => d.value === occupancy)
              .reduce((acc, curr) => acc + curr.duration, 0) /
            totalOccupiedDuration,
        }))
        .sort((a, b) => b.percentage - a.percentage);
    }
    return [{ occupancy: 0, percentage: 0 }];
  }, [roomOccupancySensorCount]);

  // Bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<Data>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
  });

  const handleMouseMove = (coords: Point | null, datum: { data: Data }) => {
    showTooltip({
      tooltipLeft: coords?.x,
      tooltipTop: coords?.y,
      tooltipData: datum.data,
    });
  };

  const getUsageColor = scaleOrdinal({
    domain: data.map((d) => d.occupancy),
    range: RAINBOW,
  });

  return (
    <>
      <LoadingSpinner loading={loadingRoomOccupancySensorCount} />
      <div className="relative" data-test-id="room-occupancy-distribution">
        <svg ref={containerRef} width={width} height={height}>
          <Group top={yMax / 2 + margin.top} left={xMax / 2 + margin.left}>
            <Pie
              data={data}
              pieValue={(d) => d.percentage}
              outerRadius={radius}
              cornerRadius={3}
              pieSort={() => 1}
              padAngle={0.005}
            >
              {(pie) => (
                <AnimatedPie<Data>
                  pie={pie}
                  onMouseMove={handleMouseMove}
                  onMouseOut={hideTooltip}
                  getKey={(arc) => arc.data.occupancy.toFixed(0)}
                  getColor={(arc) => getUsageColor(arc.data.percentage)}
                />
              )}
            </Pie>
          </Group>
        </svg>
        {tooltipOpen && tooltipData && (
          <TooltipInPortal
            top={tooltipTop}
            left={tooltipLeft}
            style={{
              ...defaultStyles,
              background:
                theme.color === Themes.LIGHT
                  ? getColor('WHITE')
                  : getColor('NEUTRAL900'),
            }}
          >
            <div
              className="dark:text-neutral-200"
              data-test-id="room-occupancy-distribution-tooltip"
            >
              <div className="flex gap-2">
                <span className="font-bold">
                  {intl.formatMessage({ id: 'Occupancy' })}
                </span>
                <span data-test-id="room-occupancy-distribution-tooltip-occupancy">
                  {tooltipData.occupancy}
                </span>
              </div>
              <div className="flex gap-2">
                <span className="font-bold">
                  {intl.formatMessage({ id: 'Percentage' })}
                </span>
                <span data-test-id="room-occupancy-distribution-tooltip-percentage">
                  {Math.round(tooltipData.percentage * 100 * 100) / 100} %
                </span>
              </div>
            </div>
          </TooltipInPortal>
        )}
      </div>
    </>
  );
}

export default function ResponsiveRoomOccupancyDistribution(
  props: ResponsiveRoomOccupancyDistributionProps,
) {
  return (
    <ParentSize>
      {({ width, height }) => (
        <RoomOccupancyDistribution {...props} width={width} height={height} />
      )}
    </ParentSize>
  );
}
