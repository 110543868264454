import { getGridColsClass } from './OverviewRow';

interface OverviewSkeletonProps {
  loading: boolean;
  numberOfColumns: number;
  noCard?: boolean;
}

export default function OverviewSkeleton({
  loading,
  numberOfColumns,
  noCard,
}: OverviewSkeletonProps) {
  return loading ? (
    <div className="w-full h-16">
      <div className="animate-pulse h-full grid grid-cols-12 gap-2 justify-center items-center px-2 md:px-0">
        <div
          className={`${
            noCard ? 'col-span-1' : 'col-span-2'
          } h-16 justify-center items-center hidden md:flex`}
        >
          <div className="w-12 bg-neutral-200 h-12 rounded-full " />
        </div>
        <div
          className={`md:col-span-10 ${
            noCard ? 'md:col-start-3' : ''
          } col-span-12 items-center grid grid-cols-5 px-2 md:px-0`}
        >
          <div className="flex col-span-2 items-center">
            <div className="w-3/4 bg-neutral-200 h-4 rounded-md" />
          </div>
          <div
            className={`col-span-3 grid ${getGridColsClass(
              numberOfColumns,
            )} justify-items-center`}
          >
            {Array.from(Array(numberOfColumns)).map((_, index) => (
              <div
                key={index}
                className="items-center col-span-1 bg-neutral-200 size-6 rounded-md"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  ) : null;
}
