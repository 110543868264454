import * as React from "react";
const SvgSoloSlimTest = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 34.02 34.02", id: "svg6", "sodipodi:docname": "Solo_Slim copy.svg", "inkscape:version": "1.3 (0e150ed, 2023-07-21)", "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape", "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd", xmlns: "http://www.w3.org/2000/svg", "xmlns:svg": "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("sodipodi:namedview", { id: "namedview6", pagecolor: "#ffffff", bordercolor: "#000000", borderopacity: 0.25, "inkscape:showpageshadow": 2, "inkscape:pageopacity": 0, "inkscape:pagecheckerboard": 0, "inkscape:deskcolor": "#d1d1d1", "inkscape:zoom": 19.62107, "inkscape:cx": 19.519833, "inkscape:cy": 14.830996, "inkscape:window-width": 1920, "inkscape:window-height": 1027, "inkscape:window-x": 0, "inkscape:window-y": 25, "inkscape:window-maximized": 1, "inkscape:current-layer": "svg6" }), /* @__PURE__ */ React.createElement("defs", { id: "defs1" }, /* @__PURE__ */ React.createElement("style", { id: "style1" }, ".cls-3{fill:#181716}")), /* @__PURE__ */ React.createElement("g", { id: "Solo_Slim", "data-name": "Solo Slim", style: {
  strokeWidth: 0.2,
  strokeDasharray: "none",
  stroke: "#000000",
  strokeOpacity: 1
} }, /* @__PURE__ */ React.createElement("path", { d: "M31.26 6.77c0 .94-6.38 1.71-14.25 1.71S2.76 7.72 2.76 6.77s6.38-1.71 14.25-1.71 14.25.76 14.25 1.71Z", style: {
  fill: "#fff",
  strokeWidth: 0.2,
  strokeDasharray: "none",
  stroke: "#000000",
  strokeOpacity: 1
}, shapeRendering: "geometricPrecision", id: "path1" }), /* @__PURE__ */ React.createElement("path", { d: "M17.01 8.54C9.98 8.54 2.7 7.88 2.7 6.78s7.28-1.76 14.31-1.76 14.31.66 14.31 1.76-7.28 1.76-14.31 1.76Zm0-3.41c-8.49 0-14.2.85-14.2 1.65s5.7 1.65 14.2 1.65 14.2-.85 14.2-1.65-5.71-1.65-14.2-1.65Z", className: "cls-3", id: "path2", shapeRendering: "geometricPrecision", style: {
  strokeWidth: 0.2,
  strokeDasharray: "none",
  stroke: "#000000",
  strokeOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M31.26 5.06v1.71c0-.94-6.38-1.71-14.25-1.71S2.76 5.82 2.76 6.77V5.06s0-1.91 14.25-1.91 14.25 1.91 14.25 1.91Z", className: "cls-3", id: "path3", shapeRendering: "geometricPrecision", style: {
  strokeWidth: 0.2,
  strokeDasharray: "none",
  stroke: "#000000",
  strokeOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M31.26 6.83s-.06-.03-.06-.06c0-.8-5.71-1.65-14.2-1.65S2.8 5.97 2.8 6.77c0 .03-.03.06-.06.06s-.06-.03-.06-.06V5.06c0-.08.16-1.97 14.31-1.97S31.3 4.98 31.3 5.06v1.71s-.02.06-.06.06ZM17.01 3.21c-13.9 0-14.2 1.84-14.2 1.86v1.47c.94-.96 7.67-1.53 14.2-1.53s13.25.57 14.2 1.53V5.07s-.37-1.86-14.2-1.86Z", className: "cls-3", id: "path4", shapeRendering: "geometricPrecision", style: {
  strokeWidth: 0.2,
  strokeDasharray: "none",
  stroke: "#000000",
  strokeOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M17.81 6.86c0-.11-.36-.19-.81-.19s-.81.09-.81.19v23.92s.25.14.81.14.81-.14.81-.14V6.86Z", className: "cls-3", id: "path5", shapeRendering: "geometricPrecision", style: {
  strokeWidth: 0.2,
  strokeDasharray: "none",
  stroke: "#000000",
  strokeOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M17.01 30.97c-.56 0-.82-.14-.83-.15-.02 0-.03-.03-.03-.05V6.86c0-.19.54-.25.86-.25s.86.05.86.25v23.92s-.01.04-.03.05c-.01 0-.27.15-.83.15Zm-.75-.23c.08.03.32.12.75.12s.67-.09.75-.12V6.87s-.3-.14-.75-.14-.73.09-.75.14v23.87Z", className: "cls-3", id: "path6", shapeRendering: "geometricPrecision", style: {
  strokeWidth: 0.2,
  strokeDasharray: "none",
  stroke: "#000000",
  strokeOpacity: 1
} })), /* @__PURE__ */ React.createElement("path", { id: "Rahmen", d: "M0 0h34.02v34.02H0z", style: {
  fill: "none"
} }));
export default SvgSoloSlimTest;
