import { getMinutes } from 'date-fns';

import type { Destination } from '../Track/Track';

interface TickProps {
  tick: Destination;
  count: number;
  format: (val: number) => string;
}

export default function Tick({ tick, count, format }: TickProps) {
  const isFullHour = !getMinutes(tick.value);

  const tickLabelStyle = {
    marginLeft: `${-(100 / count) / 2}%`,
    width: `${100 / count}%`,
    left: `${tick.percent}%`,
  };

  return (
    <>
      <div
        className={`react_time_range__tick_marker${
          isFullHour ? '__large' : ''
        }`}
        style={{ left: `${tick.percent}%` }}
      />
      {isFullHour && (
        <div className="react_time_range__tick_label" style={tickLabelStyle}>
          {format(tick.value)}
        </div>
      )}
    </>
  );
}
