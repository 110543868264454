import * as React from "react";
const SvgGeneric = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 34.02 34.02", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M6.22 19.84c.08-2.71 4.88-4.89 10.79-4.89s10.71 2.19 10.79 4.89l-.13-5.86c-.08-2.68-4.82-4.84-10.66-4.84S6.43 11.3 6.35 13.98l-.13 5.86Z", style: {
  stroke: "#181716",
  strokeLinejoin: "round",
  strokeWidth: ".09px",
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M27.8 19.91c0 2.74-4.83 4.96-10.79 4.96S6.22 22.65 6.22 19.91v-.07c.08-2.71 4.88-4.89 10.79-4.89s10.71 2.19 10.79 4.89v.07Z", style: {
  fill: "#181716",
  stroke: "#181716",
  strokeLinejoin: "round",
  strokeWidth: ".09px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M18.26 19.99c.06.05.17.11.33.19l-.86.39c-.34-.17-.56-.3-.64-.39-.08-.09-.1-.23-.05-.41l.05-.19c.02-.06.02-.12 0-.17-.03-.09-.12-.17-.26-.23-.16-.07-.35-.12-.58-.14-.23-.02-.46.03-.71.15s-.34.23-.28.35c.06.12.18.22.35.3l-.92.42c-.58-.29-.79-.58-.65-.88.09-.19.32-.37.68-.53.48-.22.99-.35 1.53-.39.54-.04 1.04.04 1.49.25.28.13.44.26.49.41.03.09.03.23-.03.41l-.05.18c-.03.1 0 .19.09.27Zm1.75.78-.95.43-.92-.42.95-.43.92.42Z", style: {
  fill: "#fff"
} }), /* @__PURE__ */ React.createElement("path", { d: "M0 0h34.02v34.02H0z", style: {
  fill: "none"
} }));
export default SvgGeneric;
