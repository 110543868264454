function hex(buffer: ArrayBuffer) {
  return [...new Uint8Array(buffer)]
    .map((x) => x.toString(16).padStart(2, '0'))
    .join('');
}

export default async function hexDump(file: Blob) {
  // gotten from https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#Example

  return new Promise<string>((res) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result !== 'string' && reader.result) {
        res(hex(reader.result));
      }
    };
    reader.readAsArrayBuffer(file);
  });
}
