import keycloak from 'keycloak';
import { SERVICES_ROUTES } from 'router/routes';

import { Navigate } from 'react-router-dom';

export default function Home() {
  return (
    <Navigate
      to={`/${
        SERVICES_ROUTES.find((service) =>
          Array.isArray(service.userRole)
            ? service.userRole.every((role) => keycloak.hasResourceRole(role))
            : keycloak.hasResourceRole(service.userRole),
        )?.path ?? 'settings'
      }`}
      replace
    />
  );
}
