import Tooltip from 'generic/components/Tooltip';
import { FormattedMessage } from 'translations/Intl';
import { TfiRulerAlt } from 'react-icons/tfi';
import { HiCheck, HiOutlineExclamationTriangle } from 'react-icons/hi2';

import { useMemo } from 'react';
import type { Floor } from 'common/types';

interface MeasureDistanceTogglerProps {
  floor: Floor;
  isActive: boolean;
  setIsActive: (checked: boolean) => void;
}

export default function MeasureDistanceToggler({
  floor,
  isActive,
  setIsActive,
}: MeasureDistanceTogglerProps): JSX.Element {
  const isMissing = useMemo(() => floor.GeometryUnitPerMeter === null, [floor]);

  return (
    <div className="flex gap-2 justify-center items-center relative">
      <div className="relative">
        <Tooltip
          content={
            <div
              className={`flex p-2 justify-center items-center mr-1 rounded-full ${
                isActive ? 'bg-primary-200 text-primary-500' : ''
              }`}
            >
              <TfiRulerAlt
                className="w-5 h-5 cursor-pointer hover:text-primary-500 transition-colors"
                id="measure-floor-ratio"
                onClick={() => setIsActive(!isActive)}
              />
            </div>
          }
        >
          <FormattedMessage
            id={isMissing ? 'Missing-Measure' : 'Measure reference line'}
          />
        </Tooltip>
        {!isActive &&
          (isMissing ? (
            <HiOutlineExclamationTriangle
              className="absolute top-0 right-0 bg-red-200 rounded-full w-5 h-5 p-1 text-red-500 bg-opacity-70"
              id="measure-floor-ratio"
            />
          ) : (
            <HiCheck
              className="absolute top-0 right-0 bg-green-200 rounded-full w-5 h-5 p-1 text-green-500 bg-opacity-70"
              id="measure-floor-ratio"
            />
          ))}
      </div>
    </div>
  );
}
