import { FormattedMessage } from 'translations/Intl';

import {
  MqttSystems,
  useSetSceneIdentifyMutation,
  useSetSceneNoneMutation,
} from 'mda2-frontend/src/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import parseBluerangeTopic from 'mda2-frontend/src/utils/parseBluerangeTopic';
import { HiOutlineLightBulb } from 'react-icons/hi2';

import SetSceneButton from '../SetSceneButton';

interface SetSceneIdentifyButtonProps {
  mqttTopic: string;
}

export default function SetSceneIdentifyButton({
  mqttTopic,
}: SetSceneIdentifyButtonProps): JSX.Element {
  const hasuraHeader = useHasuraHeader();
  const [, setSceneIdentifyMutation] = useSetSceneIdentifyMutation();
  const [, setSceneNoneMutation] = useSetSceneNoneMutation();

  return (
    <SetSceneButton
      icon={<HiOutlineLightBulb />}
      title="Set scene identify"
      timeout
      onProceed={() =>
        setSceneIdentifyMutation(
          {
            Topics: [parseBluerangeTopic(mqttTopic)],
            MqttSystem: MqttSystems.Bluerange,
          },
          hasuraHeader(HasuraPermissions.VIEW_ADMIN),
        )
      }
      onCancel={() =>
        setSceneNoneMutation(
          {
            Topics: [parseBluerangeTopic(mqttTopic)],
            MqttSystem: MqttSystems.Bluerange,
          },
          hasuraHeader(HasuraPermissions.VIEW_ADMIN),
        )
      }
    >
      <FormattedMessage id="Identify scene" />
    </SetSceneButton>
  );
}
