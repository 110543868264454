import {
  type 
  DeskUsageComparisonOccupancyQuery,
  type 
  DeskUsageComparisonUsageQuery,
  type 
  RoomUsageComparisonQuery,
  useDeskUsageComparisonOccupancyQuery,
  useDeskUsageComparisonUsageQuery,
  useRoomUsageComparisonQuery,
} from 'graphql/types';
import useStore from 'model/store';
import { endOfDayUTC, startOfDayUTC } from 'utils/date';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';

import { curveBasis } from '@visx/curve';
import { AnimatedLineSeries } from '@visx/xychart';
import { useEffect, useMemo } from 'react';

export type ChartVariables = {
  Id: string;
  Floor?: number | null;
  Room?: string | null;
  Building?: string | null;
  Labels?: string[] | null;
};

export enum UsageType {
  OCCUPANCY = 'Occupancy',
  USAGE = 'Usage',
}

interface LineChartProps {
  setLoading: (loading: boolean) => void;
  chartVariables: ChartVariables;
  meetingRoomOccupancy?: boolean;
  usageType?: UsageType;
}

export type ChartData =
  | DeskUsageComparisonOccupancyQuery['f_history_desks_occupancy_daily'][number]
  | DeskUsageComparisonUsageQuery['f_history_desks_occupancy_daily'][number]
  | RoomUsageComparisonQuery['f_history_rooms_occupancy_daily'][number];

export function isRoom(
  feature: ChartData,
): feature is RoomUsageComparisonQuery['f_history_rooms_occupancy_daily'][number] {
  if (
    typeof (
      feature as RoomUsageComparisonQuery['f_history_rooms_occupancy_daily'][number]
    ).PercentageUsedMeetingRooms === 'number'
  ) {
    return true;
  }
  return false;
}

export function isDeskUsage(
  feature: ChartData,
): feature is DeskUsageComparisonUsageQuery['f_history_desks_occupancy_daily'][number] {
  if (
    typeof (
      feature as DeskUsageComparisonUsageQuery['f_history_desks_occupancy_daily'][number]
    ).PercentageMaxOccupancy === 'number'
  ) {
    return true;
  }
  return false;
}

export default function LineChart({
  setLoading,
  chartVariables,
  usageType,
  meetingRoomOccupancy = false,
}: LineChartProps) {
  const hasuraHeader = useHasuraHeader();
  const dateRange = useStore((state) => state.userSettings.dateRange);
  const dateFrom = useMemo(() => new Date(dateRange.start), [dateRange.start]);
  const dateTo = useMemo(
    () => (dateRange.end ? new Date(dateRange.end) : null),
    [dateRange.end],
  );

  const { Room, Floor, Building, Id, Labels } = chartVariables;

  const labels =
    (Labels?.length ?? 0) > 0
      ? JSON.stringify(Labels).replace('[', '{').replace(']', '}')
      : null;

  const [{ data: deskUsageData, fetching: loadingDeskUsageData }] =
    useDeskUsageComparisonUsageQuery({
      variables: {
        Start: startOfDayUTC(dateFrom),
        End: dateTo && endOfDayUTC(dateTo),
        Room,
        Floor,
        Building,
        Labels: labels,
      },
      context: useMemo(
        () => hasuraHeader(HasuraPermissions.VIEW_ANALYTICS),
        [hasuraHeader],
      ),
      pause: meetingRoomOccupancy || usageType !== UsageType.USAGE,
    });

  const [{ data: deskOccupancyData, fetching: loadingDeskOccupancyData }] =
    useDeskUsageComparisonOccupancyQuery({
      variables: {
        Start: dateFrom,
        End: dateTo,
        Room,
        Floor,
        Building,
        Labels: labels,
      },
      context: useMemo(
        () => hasuraHeader(HasuraPermissions.VIEW_ANALYTICS),
        [hasuraHeader],
      ),
      pause: meetingRoomOccupancy || usageType !== UsageType.OCCUPANCY,
    });

  const [{ data: roomData, fetching: loadingRoomData }] =
    useRoomUsageComparisonQuery({
      variables: {
        Start: dateFrom,
        End: dateTo,
        Room,
        Floor,
        Building,
        Labels: labels,
      },
      context: useMemo(
        () => hasuraHeader(HasuraPermissions.VIEW_ANALYTICS),
        [hasuraHeader],
      ),
      pause: !meetingRoomOccupancy,
    });

  useEffect(() => {
    const timer = setTimeout(
      () =>
        setLoading(
          loadingDeskOccupancyData || loadingDeskUsageData || loadingRoomData,
        ),
      200,
    );

    return () => clearTimeout(timer);
  }, [
    loadingDeskOccupancyData,
    loadingDeskUsageData,
    loadingRoomData,
    setLoading,
  ]);

  return meetingRoomOccupancy ? (
    <AnimatedLineSeries
      dataKey={Id}
      data={
        roomData?.f_history_rooms_occupancy_daily.map((r) => ({
          PercentageUsedMeetingRooms: r.PercentageUsedMeetingRooms,
          Date: new Date(r.Date),
        })) ?? []
      }
      xAccessor={(d) => d.Date}
      yAccessor={(d) => d.PercentageUsedMeetingRooms}
      curve={curveBasis}
    />
  ) : usageType === UsageType.OCCUPANCY ? (
    <AnimatedLineSeries
      dataKey={Id}
      data={
        deskOccupancyData?.f_history_desks_occupancy_daily.map((d) => ({
          PercentageHotMinutes:
            d.PercentageHotMinutes + d.PercentageWarmMinutes,
          Date: new Date(d.Date),
        })) ?? []
      }
      xAccessor={(d) => d.Date}
      yAccessor={(d) => d.PercentageHotMinutes}
      curve={curveBasis}
    />
  ) : (
    <AnimatedLineSeries
      dataKey={Id}
      data={
        deskUsageData?.f_history_desks_occupancy_daily.map((d) => ({
          PercentageMaxOccupancy: d.PercentageMaxOccupancy,
          Date: new Date(d.Date),
        })) ?? []
      }
      xAccessor={(d) => d.Date}
      yAccessor={(d) => d.PercentageMaxOccupancy}
      curve={curveBasis}
    />
  );
}
