import keycloak from 'mda2-frontend/src/keycloak';
import useStore from 'model/store';
import { createContext, useEffect, useState } from 'react';
import LoadingScreen from '../LoadingScreen';

interface AuthenticationProviderProps {
  children: JSX.Element[];
}

export const AuthenticationContext = createContext<string | undefined>(
  keycloak.token,
);

export default function AuthenticationProvider({
  children,
}: AuthenticationProviderProps) {
  const [initialized, setInitialized] = useState(false);
  const token = useStore((state) => state.token);
  const setToken = useStore((state) => state.setToken);

  keycloak.onTokenExpired = () =>
    keycloak.updateToken(30).then(() => setToken(keycloak.token));

  keycloak.onAuthRefreshSuccess = () => setToken(keycloak.token);

  const initializeKeycloak = async () =>
    keycloak
      .init({
        onLoad: 'login-required',
        checkLoginIframe: false,
        enableLogging: true,
        pkceMethod: 'S256',
        flow: 'standard',
      })
      .then(() => {
        setInitialized(true);
      })
      .catch((e) => console.error(e));

  // biome-ignore lint/correctness/useExhaustiveDependencies: don't need in deps array (initializeKeycloak)
  useEffect(() => {
    async function checkKeycloak() {
      await initializeKeycloak();
    }
    if (!initialized && !keycloak.token) {
      checkKeycloak();
    }
    if (keycloak.token) {
      setToken(keycloak.token);
    }
  }, [setToken, initialized]);

  if (token) {
    return (
      <AuthenticationContext.Provider value={token}>
        {children}
      </AuthenticationContext.Provider>
    );
  }

  return <LoadingScreen />;
}
