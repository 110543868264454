/* eslint-disable jsx-a11y/label-has-associated-control */
import Checkbox from 'generic/components/Form/Checkbox';
import useStore from 'model/store';
import { FormattedMessage } from 'translations/Intl';

import { endOfDay, startOfDay } from 'date-fns';
import { useFirmwareUpdateHoursQuery } from 'mda2-frontend/src/graphql/types';
import {
  getAllWeekdays,
  getInterval,
  lower,
  serializeRange,
  upper,
} from 'mda2-frontend/src/utils/date';
import localize from 'mda2-frontend/src/utils/format';
import { useEffect, useState } from 'react';
import TimeRange from '../WorkdaySelect/components/TimeRange';

export interface CheckedFirmwareUpdateDays {
  DayOfTheWeek: number;
  ExcludedHours: string;
  Id?: string;
  OrganizationId: string;
}

interface FirmwareDaySelectProps {
  deletedDays: string[];
  setDeletedDays: (val: string[]) => void;
  setTimeObjects: (value: CheckedFirmwareUpdateDays[]) => void;
  setValidated: (val: boolean) => void;
}

export default function FirmwareDaySelect({
  deletedDays,
  setDeletedDays,
  setTimeObjects,
  setValidated,
}: FirmwareDaySelectProps): JSX.Element {
  const organizationId = useStore(
    (state) => state.organizationSettings.organizationUuid,
  );
  const [{ data }] = useFirmwareUpdateHoursQuery();

  const [selectedInterval, setSelectedInterval] = useState([
    data?.FirmwareUpdateHours[0]?.ExcludedHours
      ? lower(data.FirmwareUpdateHours[0]?.ExcludedHours)
      : getInterval('04:00:00'),
    data?.FirmwareUpdateHours[0]?.ExcludedHours
      ? upper(data.FirmwareUpdateHours[0]?.ExcludedHours)
      : getInterval('22:00:00'),
  ]);
  const [checkedDays, setCheckedFirmwareUpdateDays] = useState<
    CheckedFirmwareUpdateDays[]
  >([]);

  useEffect(() => {
    if (checkedDays.length !== 0) {
      setValidated(true);
    } else {
      setValidated(false);
    }

    if (
      selectedInterval[0] &&
      selectedInterval[1] &&
      checkedDays.length === 0
    ) {
      setValidated(false);
    }
  }, [
    checkedDays.length,
    selectedInterval?.[0],
    selectedInterval?.[1],
    setValidated,
  ]);

  useEffect(() => {
    setTimeObjects(
      checkedDays.map((item) => ({
        Id: item.Id,
        DayOfTheWeek: item.DayOfTheWeek,
        ExcludedHours: serializeRange(
          {
            start: {
              value: selectedInterval[0] ? selectedInterval[0] : new Date(),
              inclusive: true,
            },
            end: {
              value: selectedInterval[1] ? selectedInterval[1] : new Date(),
              inclusive: false,
            },
          },
          true,
        ),
        OrganizationId: organizationId,
      })) as CheckedFirmwareUpdateDays[],
    );
  }, [
    checkedDays,
    selectedInterval?.[0],
    selectedInterval?.[1],
    organizationId,
    setTimeObjects,
  ]);

  useEffect(() => {
    if (data?.FirmwareUpdateHours[0]?.ExcludedHours) {
      setSelectedInterval([
        lower(data.FirmwareUpdateHours[0].ExcludedHours),
        upper(data.FirmwareUpdateHours[0].ExcludedHours),
      ]);
      setCheckedFirmwareUpdateDays(data.FirmwareUpdateHours);
    }
  }, [data?.FirmwareUpdateHours[0]?.ExcludedHours, data?.FirmwareUpdateHours]);

  const handleOnChange = (position: number) => {
    const elementAdded = checkedDays.find(
      (day) => day.DayOfTheWeek === position,
    );

    if (elementAdded) {
      // Remove the element from the list as it was already added
      setDeletedDays([
        ...deletedDays,
        checkedDays
          .filter(
            (b: CheckedFirmwareUpdateDays) => b.DayOfTheWeek === position,
          )[0]
          ?.Id?.toString() ?? '',
      ]);
      setCheckedFirmwareUpdateDays(
        checkedDays.filter(
          (b: CheckedFirmwareUpdateDays) => b.DayOfTheWeek !== position,
        ),
      );
    } else {
      setCheckedFirmwareUpdateDays([
        ...checkedDays,
        {
          DayOfTheWeek: position,
          ExcludedHours: `[${selectedInterval[0]}, ${selectedInterval[1]})`,
          OrganizationId: '',
        },
      ]);
    }
  };

  return (
    <>
      <label
        htmlFor="weekDayFirmware"
        className="block text-base md:text-sm text-left text-neutral-700 dark:text-white"
      >
        <FormattedMessage id="Firmware update hours" />
      </label>

      <div id="weekDayFirmware" className="space-y-2">
        <div className="flex text-base md:text-sm">
          <FormattedMessage
            id="Allowed from to"
            values={{
              start: localize(
                startOfDay(
                  selectedInterval[0] ? selectedInterval[0] : new Date(),
                ),
                'HH:mm',
              ),
              end: localize(
                selectedInterval[0] ? selectedInterval[0] : new Date(),
                'HH:mm',
              ),
              start2: localize(
                selectedInterval[1] ? selectedInterval[1] : new Date(),
                'HH:mm',
              ),
              end2: localize(
                endOfDay(
                  selectedInterval[1] ? selectedInterval[1] : new Date(),
                ),
                'HH:mm',
              ),
            }}
          />
        </div>

        <TimeRange
          error
          ticksNumber={12}
          selectedInterval={selectedInterval}
          onUpdateCallback={() => {}}
          onChangeCallback={setSelectedInterval}
          disabledIntervals={
            selectedInterval[0] && selectedInterval[1]
              ? [{ start: selectedInterval[0], end: selectedInterval[1] }]
              : []
          }
          trackClassName="!bg-red-300"
          sliderClassName="!bg-[#62CB66]/50"
        />

        <div className="flex space-x-4 pb-2 flex-wrap justify-center md:justify-start">
          <div className="text-sm">
            <FormattedMessage id="Allowed days" />:
          </div>
          {getAllWeekdays().map((day, index) => (
            <Checkbox
              key={day}
              checked={
                checkedDays.filter(
                  (d: CheckedFirmwareUpdateDays) =>
                    d.DayOfTheWeek === index + 1,
                ).length > 0
              }
              setChecked={() => handleOnChange(index + 1)}
              label={day}
            />
          ))}
        </div>
      </div>
    </>
  );
}
