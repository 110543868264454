import Checkbox from 'generic/components/Form/Checkbox';

import { type InputHTMLAttributes, useEffect, useRef } from 'react';

interface IndeterminateCheckboxProps
  extends InputHTMLAttributes<HTMLInputElement> {
  indeterminate?: boolean;
  header?: boolean;
}

export default function IndeterminateCheckbox({
  indeterminate,
  header,
  disabled,
  ...rest
}: IndeterminateCheckboxProps) {
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (typeof indeterminate === 'boolean' && ref.current) {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [indeterminate, rest.checked]);

  return (
    <Checkbox
      disabled={disabled}
      ref={ref}
      data-test-id={header ? 'header-select' : 'row-select'}
      {...rest}
    />
  );
}
