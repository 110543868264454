import type { DeviceTypes } from 'common/types';
import Accordion from 'generic/components/Accordion';
import Card from 'generic/components/Card';
import StyledButton from 'generic/components/Form/Button/StyledButton';
import Table from 'generic/components/Table';
import Tooltip from 'generic/components/Tooltip';
import Loader from 'generic/components/layout/BarLoader';
import { useDefectiveBeaconsQuery } from 'graphql/types';
import { FormattedMessage, useIntl } from 'translations/Intl';

import type { CellContext, ColumnDef } from '@tanstack/react-table';
import { HiOutlinePrinter } from 'react-icons/hi2';

import ReportingMap, {
  getStatus,
  type DefectiveBeacons as DefectiveBeaconsType,
} from './components/ReportingMap';
import Skeleton from './components/Skeleton';

function IdCell(props: CellContext<DefectiveBeaconsType, unknown>) {
  const { row } = props;
  return (
    <div
      className={`${
        row.original.StatusCode !== 0 ? 'bg-red-500' : 'bg-yellow-400'
      } size-5 rounded-full text-white flex justify-center items-center`}
    >
      {row.original.Index}
    </div>
  );
}

function StatusCell(props: CellContext<DefectiveBeaconsType, unknown>) {
  const { row } = props;
  return (
    <div className="break-words whitespace-pre max-w-sm">
      {getStatus(
        row.original.StatusCode,
        row.original.DeviceType?.Name as DeviceTypes,
      )}
    </div>
  );
}

export default function DefectiveBeacons() {
  const intl = useIntl();
  const [{ data: defectiveBeacons, fetching }] = useDefectiveBeaconsQuery();

  const defaultColumns: ColumnDef<DefectiveBeaconsType>[] = [
    {
      id: 'identifier',
      header: intl.formatMessage({ id: 'id' }),
      accessorFn: (row) => row.Index.toString(),
      cell: IdCell,
    },
    {
      id: 'name',
      header: intl.formatMessage({ id: 'BeaconName' }),
      accessorKey: 'Name',
    },
    {
      id: 'deviceType',
      header: intl.formatMessage({ id: 'Device Type' }),
      accessorFn: (row) =>
        row.DeviceType?.Name ?? intl.formatMessage({ id: 'Unknown' }),
    },
    {
      id: 'statusCode',
      header: intl.formatMessage({ id: 'Last status' }),
      accessorFn: (row) =>
        getStatus(row.StatusCode, row.DeviceType?.Name as DeviceTypes),
      cell: StatusCell,
    },
    {
      id: 'offline',
      header: intl.formatMessage({ id: 'Online' }),
      accessorFn: (row) =>
        row.IsOffline
          ? intl.formatMessage({ id: 'Offline' })
          : intl.formatMessage({ id: 'Online' }),
    },
  ];

  return (
    <>
      <Loader loading={fetching} />
      <Card className="print:!bg-transparent relative print:border-none print:!px-0 print:!py-0">
        <Tooltip
          content={
            <StyledButton
              className="print:hidden absolute right-6 top-6"
              onClick={() => window.print()}
            >
              <HiOutlinePrinter className="size-5" />
            </StyledButton>
          }
        >
          <FormattedMessage id="Print" />
        </Tooltip>
        <div className="space-y-4">
          {defectiveBeacons?.Buildings.length ? (
            defectiveBeacons.Buildings.map((building) => (
              <Accordion
                key={building.Name}
                title={building.Name}
                initialStateOpen
              >
                <div
                  data-test-id={`${building.Name}-defective-beacons`}
                  className="space-y-4 divide-y-2 divide-dashed print:divide-none divide-neutral-200 dark:divide-neutral-700"
                >
                  {building.Floors.map((floor) => (
                    <div
                      key={floor.Number}
                      className="flex flex-col [&:not(:first-child)]:pt-4 print:break-after-page"
                    >
                      <div className="font-bold print:hidden">
                        <FormattedMessage
                          id="{number} Floor"
                          values={{ number: floor.Number }}
                        />
                      </div>
                      <div className="font-bold hidden print:block">
                        <FormattedMessage
                          id="Building Floor"
                          values={{
                            building: building.Name,
                            number: floor.Number,
                          }}
                        />
                      </div>
                      <div className="flex flex-col">
                        <div
                          data-test-id={`${building.Name}-defective-beacons-count`}
                        >
                          <FormattedMessage id="Defective beacons" />:{' '}
                          {floor.defectiveBeaconCount.aggregate?.count}
                        </div>
                        <div
                          data-test-id={`${building.Name}-offline-beacons-count`}
                        >
                          <FormattedMessage id="Offline beacons" />:{' '}
                          {floor.offlineBeaconCount.aggregate?.count}
                        </div>
                        <div
                          data-test-id={`${building.Name}-total-beacons-count`}
                        >
                          <FormattedMessage id="Total beacons" />:{' '}
                          {floor.beaconCount.aggregate?.count}
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 print:!flex-col md:space-x-2 print:space-y-2 print:space-x-0 justify-around">
                        <div className="w-full h-96 md:w-96 print:w-full relative">
                          <ReportingMap
                            key={floor.Number}
                            image={floor.Image!}
                            beacons={floor.MqttBeacons.map((beacon, key) => ({
                              ...beacon,
                              Index: key + 1,
                            }))}
                          />
                        </div>
                        <div className="w-full md:max-w-3xl print:w-full">
                          <Table<DefectiveBeaconsType>
                            id="defective-beacons"
                            columns={defaultColumns}
                            data={floor.MqttBeacons.map((beacon, key) => ({
                              ...beacon,
                              Index: key + 1,
                            }))}
                            initialState={{
                              pagination: {
                                // Do not paginate as it won't be visible on the PDF
                                pageSize: floor.MqttBeacons.length,
                                pageIndex: 0,
                              },
                            }}
                            enabledFeatures={{
                              enableColumnSelection: false,
                              enableCsvExport: false,
                              enableGlobalFilter: false,
                              enablePageSize: false,
                              enableRowSelection: false,
                              enablePagination: false,
                              enableColumnFilter: false,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Accordion>
            ))
          ) : (
            <Skeleton loading={fetching} />
          )}
        </div>
      </Card>
    </>
  );
}
