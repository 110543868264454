import { useEffect, useState } from 'react';

const useDeviceDetect = ({ resolution } = { resolution: 767.98 }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < resolution);

  useEffect(() => {
    const handlePageResized = () => {
      setIsMobile(window.innerWidth < resolution);
    };

    window.addEventListener('resize', handlePageResized);
    window.addEventListener('orientationchange', handlePageResized);
    window.addEventListener('load', handlePageResized);
    window.addEventListener('reload', handlePageResized);

    return () => {
      window.removeEventListener('resize', handlePageResized);
      window.removeEventListener('orientationchange', handlePageResized);
      window.removeEventListener('load', handlePageResized);
      window.removeEventListener('reload', handlePageResized);
    };
  }, [resolution]);

  return {
    isMobile,
  };
};

export default useDeviceDetect;
