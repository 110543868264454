import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItems,
} from '@headlessui/react';
import { ClimateType } from 'common/types';
import ImageModal from 'generic/components/ImageModal';
import Panel from 'generic/components/Panel';
import PrivateWrapper from 'generic/components/PrivateWrapper/PrivateWrapper';
import Transition from 'generic/components/Transition';
import occupancyCalculation from 'img/occupancy_calculation.png';
import useStore from 'model/store';
import ClimateValues from 'pages/ClimateView/components/ClimateValues';
import { useState } from 'react';
import {
  HiChevronUp,
  HiExclamationCircle,
  HiOutlineQuestionMarkCircle,
} from 'react-icons/hi2';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'translations/Intl';
import { HasuraPermissions } from 'utils/graphql/useHasuraHeaders';
import useDeviceDetect from 'utils/useDeviceDetect';

function DocItem({
  title,
  content,
}: {
  title: JSX.Element;
  content: JSX.Element;
}) {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <DisclosureButton className="group flex w-full justify-between rounded-lg bg-white dark:bg-neutral-900 text-neutral-700 dark:text-neutral-200 border dark:border-neutral-700 dark:bg px-4 py-2 text-left text-sm font-medium hover:bg-neutral-50 dark:hover:bg-neutral-800 focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
            {title}
            <HiChevronUp
              className={`${
                open ? 'rotate-180 transform' : ''
              } transition-transform size-4 text-neutral-700 dark:text-neutral-200 group-hover:text-neutral-800 group-hover:dark:text-neutral-300`}
            />
          </DisclosureButton>
          <Transition>
            <DisclosurePanel className="px-4 pt-4 pb-2 text-sm text-neutral-500 dark:text-white">
              {content}
            </DisclosurePanel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}

function Content({ setPanelOpen }: { setPanelOpen?: (open: boolean) => void }) {
  const userRoles = useStore((state) => state.user)?.roles;

  return (
    <div className="flex flex-col space-y-2">
      <DocItem
        title={<FormattedMessage id="Occupancy states" />}
        content={
          <div className="space-y-2">
            <DocItem
              title={
                <div className="flex items-center space-x-2">
                  <div className="bg-red-600 border rounded-full size-3" />
                  <div>
                    <FormattedMessage id="hot" />
                  </div>
                </div>
              }
              content={<FormattedMessage id="hot-description" />}
            />
            <DocItem
              title={
                <div className="flex items-center space-x-2">
                  <div className="bg-yellow-500 border rounded-full size-3" />
                  <div>
                    <FormattedMessage id="warm" />
                  </div>
                </div>
              }
              content={
                <FormattedMessage
                  id="warm-description"
                  values={
                    userRoles?.includes(HasuraPermissions.WRITE_ORGANIZATION)
                      ? {
                          here: (
                            <NavLink
                              className="text-primary-500"
                              to="/settings/organization"
                              onClick={() => setPanelOpen?.(false)}
                            >
                              <FormattedMessage id="here" />
                            </NavLink>
                          ),
                        }
                      : {
                          here: (
                            <FormattedMessage id="in the organization settings" />
                          ),
                        }
                  }
                />
              }
            />
            <DocItem
              title={
                <div className="flex items-center space-x-2">
                  <div className="bg-green-500 border rounded-full size-3" />
                  <div>
                    <FormattedMessage id="free" />
                  </div>
                </div>
              }
              content={<FormattedMessage id="free-description" />}
            />
            <DocItem
              title={
                <div className="flex items-center space-x-2">
                  <HiExclamationCircle className="size-3 text-neutral-400" />
                  <div>
                    <FormattedMessage id="offline" />
                  </div>
                </div>
              }
              content={
                <FormattedMessage
                  id="offline-description"
                  values={
                    userRoles?.includes(HasuraPermissions.WRITE_ORGANIZATION)
                      ? {
                          here: (
                            <NavLink
                              className="text-primary-500"
                              to="/settings/organization"
                              onClick={() => setPanelOpen?.(false)}
                            >
                              <FormattedMessage id="here" />
                            </NavLink>
                          ),
                        }
                      : {
                          here: (
                            <FormattedMessage id="in the organization settings" />
                          ),
                        }
                  }
                />
              }
            />
            <DocItem
              title={
                <div className="flex items-center space-x-2">
                  <div className="bg-neutral-600 border rounded-full size-3" />
                  <div>
                    <FormattedMessage id="private" />
                  </div>
                </div>
              }
              content={<FormattedMessage id="private-description" />}
            />
          </div>
        }
      />
      <DocItem
        title={<FormattedMessage id="Occupancy calculation" />}
        content={
          <>
            <FormattedMessage
              id="occupancy-daily-description"
              values={
                userRoles?.includes(HasuraPermissions.WRITE_ORGANIZATION)
                  ? {
                      here: (
                        <NavLink
                          className="text-primary-500"
                          to="/settings/organization"
                          onClick={() => setPanelOpen?.(false)}
                        >
                          <FormattedMessage id="here" />
                        </NavLink>
                      ),
                    }
                  : {
                      here: (
                        <FormattedMessage id="in the organization settings" />
                      ),
                    }
              }
            />
            <ImageModal large>
              <img
                src={occupancyCalculation}
                alt="Occupancy description"
                className="my-2"
              />
            </ImageModal>
            <div className="space-y-2">
              <DocItem
                title={<FormattedMessage id="Desks" />}
                content={<FormattedMessage id="occupancy-desk-description" />}
              />
              <DocItem
                title={<FormattedMessage id="Rooms" />}
                content={<FormattedMessage id="occupancy-room-description" />}
              />
              <DocItem
                title={<FormattedMessage id="Usage vs. Occupancy" />}
                content={<FormattedMessage id="usage-occupancy-description" />}
              />
            </div>
          </>
        }
      />
      <PrivateWrapper roleRequired={HasuraPermissions.VIEW_CLIMATE}>
        <DocItem
          title={<FormattedMessage id="Climate" />}
          content={
            <div className="space-y-2">
              <DocItem
                title={<FormattedMessage id="Limits" />}
                content={<ClimateValues />}
              />
              <DocItem
                title={<FormattedMessage id={ClimateType.TVOC} />}
                content={
                  <>
                    <FormattedMessage id="etvoc-description" />
                    <iframe
                      className="w-full h-72"
                      title="mox"
                      src="https://sensirion.com/media/documents/D27F03C0/6294E00F/Info_Note_Output_MOX_Sensor.pdf"
                    />
                  </>
                }
              />
            </div>
          }
        />
      </PrivateWrapper>
      <PrivateWrapper roleRequired={HasuraPermissions.VIEW_CLEANING}>
        <DocItem
          title={<FormattedMessage id="Cleaning" />}
          content={<FormattedMessage id="cleaning-description" />}
        />
      </PrivateWrapper>
      <PrivateWrapper roleRequired={HasuraPermissions.VIEW_ADMIN}>
        <DocItem
          title={<FormattedMessage id="Floor plan admin actions" />}
          content={<FormattedMessage id="admin-actions-description" />}
        />
      </PrivateWrapper>
    </div>
  );
}

export default function DocsBubble() {
  // Use a bigger resolution as the docs panel won't open inside the mobile nav layout
  const { isMobile } = useDeviceDetect({ resolution: 1200 });
  const [panelOpen, setPanelOpen] = useState(false);

  return (
    <>
      <Menu as="div" className="xl:inline-block text-left">
        <MenuButton
          onClick={isMobile ? undefined : () => setPanelOpen(!panelOpen)}
          className="flex text-left text-base font-medium hover:text-primary-500 xl:hover:text-black xl:dark:hover:text-white xl:w-10 xl:h-10 items-center justify-center xl:bg-neutral-100 xl:dark:bg-neutral-700  xl:hover:bg-neutral-200 transition duration-150 xl:rounded-full"
        >
          <HiOutlineQuestionMarkCircle className="size-5 hidden xl:block" />
          <div className="block xl:hidden mr-2 font-medium">
            <FormattedMessage id="Docs" />
          </div>
        </MenuButton>
        <MenuItems
          modal={false}
          transition
          className="max-h-48 overflow-y-auto block xl:hidden duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
        >
          <Content />
        </MenuItems>
      </Menu>

      <Panel open={panelOpen} setOpen={setPanelOpen}>
        <Panel.Content title={<FormattedMessage id="Docs" />}>
          <Content setPanelOpen={setPanelOpen} />
        </Panel.Content>
      </Panel>
    </>
  );
}
