import { FormattedMessage, type IntlMessageKeys } from 'translations/Intl';

import { Menu, MenuButton, MenuItems } from '@headlessui/react';
import ReportDatePicker from 'generic/components/Form/ReportDatePicker';
import { RoomTypes } from 'mda2-frontend/src/common/types';
import ClimateSensorSelection from 'mda2-frontend/src/generic/components/ClimateSensorSelection';
import CleaningInput from 'mda2-frontend/src/generic/components/Form/CleaningInput';
import DatePicker from 'mda2-frontend/src/generic/components/Form/DatePicker';
import LocationSelect from 'mda2-frontend/src/generic/components/LocationSelect';
import LocationWrapper from 'mda2-frontend/src/generic/components/LocationWrapper';
import useStore from 'mda2-frontend/src/model/store';
import Filter from 'mda2-frontend/src/pages/AnalyticsView/components/Filter';
import type { ReactNode } from 'react';
import { HiOutlineBeaker } from 'react-icons/hi2';
import { useLocation } from 'react-router-dom';

function ListElement({
  title,
  children,
}: {
  title: IntlMessageKeys;
  children: ReactNode;
}) {
  return (
    <div className="flex flex-col space-y-1">
      <div className="text-base font-bold">
        <FormattedMessage id={title} />
      </div>
      {children}
    </div>
  );
}

export function LocationFilter(): JSX.Element {
  const location = useLocation();
  const roomTypes = useStore((state) => state.userSettings.roomTypes);

  if (location.pathname.includes('analytics')) {
    return (
      <ListElement title="Location">
        <LocationSelect
          hasRoomFilter
          roomFilter={(r) => {
            if (location.pathname === '/analytics') {
              return r.RoomType.Name === RoomTypes.DESKS;
            }

            if (location.pathname === '/analytics/rooms') {
              return r.RoomType.Name === RoomTypes.MEETING;
            }

            if (
              roomTypes.includes(RoomTypes.MEETING) &&
              !roomTypes.includes(RoomTypes.DESKS)
            ) {
              return r.RoomType.Name === RoomTypes.MEETING;
            }
            if (
              !roomTypes.includes(RoomTypes.MEETING) &&
              roomTypes.includes(RoomTypes.DESKS)
            ) {
              return r.RoomType.Name === RoomTypes.DESKS;
            }
            return true;
          }}
          isDeselectable
        />
      </ListElement>
    );
  }

  if (location.pathname.includes('reporting/reports')) {
    return (
      <LocationWrapper allowedLocations={['reporting/reports']}>
        <ListElement title="Location">
          <LocationSelect hasFloorFilter={false} />
        </ListElement>
      </LocationWrapper>
    );
  }

  return (
    <LocationWrapper allowedLocations={['findmyplace', 'myclimate']}>
      <ListElement title="Location">
        <LocationSelect />
      </ListElement>
    </LocationWrapper>
  );
}

export function DateFilter() {
  return (
    <LocationWrapper
      allowedLocations={[
        'analytics',
        'analytics/rooms',
        'analytics/cleaning',
        'analytics/climate',
      ]}
    >
      <ListElement title="Dates">
        <DatePicker />
      </ListElement>
    </LocationWrapper>
  );
}

export function CleaningTimesFilter() {
  return (
    <LocationWrapper allowedLocations={['cleaning', 'analytics/cleaning']}>
      <ListElement title="Cleaning Thresholds">
        <CleaningInput />
      </ListElement>
    </LocationWrapper>
  );
}

export function ReportDatesFilter() {
  return (
    <LocationWrapper allowedLocations={['reporting/reports']}>
      <ListElement title="Dates">
        <ReportDatePicker />
      </ListElement>
    </LocationWrapper>
  );
}

export function ClimateSensorFilter() {
  return (
    <LocationWrapper allowedLocations={['myclimate', 'analytics/climate']}>
      <ListElement title="Sensor types">
        <ClimateSensorSelection />
      </ListElement>
    </LocationWrapper>
  );
}

export function RoomTypesFilter() {
  return (
    <LocationWrapper
      allowedLocations={[
        'dashboard',
        'findmyplace',
        'findmyplace/reservations/new',
        'findmyplace/reservations/list',
        'analytics/cleaning',
        'analytics/climate',
        'cleaning',
      ]}
    >
      <ListElement title="Room Types">
        <Filter />
      </ListElement>
    </LocationWrapper>
  );
}

export default function FilterBubble(): JSX.Element {
  return (
    <Menu as="div" className="relative lg:inline-block text-left">
      <MenuButton
        data-test-id="filter-bubble"
        className="flex lg:w-5 lg:h-5 items-center justify-center"
      >
        <HiOutlineBeaker className="size-5 text-neutral-400 hover:text-neutral-500 transition-colors" />
      </MenuButton>
      <MenuItems
        transition
        className="z-40 lg:absolute lg:max-h-fit -left-10 lg:left-0 w-full lg:w-max mt-2 origin-top-right bg-white dark:bg-neutral-800  dark:text-white divide-y divide-neutral-100 dark:divide-black rounded-md lg:shadow-lg ring-1 ring-black dark:ring-neutral-700 ring-opacity-5 focus:outline-none text-sm transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0"
      >
        <div className="lg:max-w-sm w-full">
          <div className="justify-between items-center p-4 border-b dark:border-neutral-700 gap-4 hidden lg:flex">
            <div className="text-base font-bold">
              <FormattedMessage id="Filters" />
            </div>
          </div>
          <div className="flex flex-col relative overscroll-contain py-2 px-4 space-y-2">
            <RoomTypesFilter />
            <LocationFilter />
            <DateFilter />
            <CleaningTimesFilter />
            <ClimateSensorFilter />
            <ReportDatesFilter />
          </div>
        </div>
      </MenuItems>
    </Menu>
  );
}
