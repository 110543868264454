import useStore from 'model/store';
import mqtt, { type MqttClient } from 'mqtt';
import { useEffect, useState } from 'react';

interface MqttMessageProps {
  mqttTopic: string;
  skip?: boolean;
}

export default function useMqttMessage({ mqttTopic, skip }: MqttMessageProps) {
  const user = useStore((state) => state.user);
  const token = useStore((state) => state.token);
  const organizationUuid = useStore(
    (state) => state.organizationSettings.organizationUuid,
  );
  const [client, setClient] = useState<MqttClient | undefined>();
  const [mqttUser] = useState(user?.email ?? '');
  const [mqttPassword, setMqttPassword] = useState(token ?? '');
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [topic, setTopic] = useState<string | undefined>(undefined);
  const [timestamp, setTimestamp] = useState<Date | undefined>(undefined);

  const broker = `wss://${import.meta.env.VITE_MQTT_BROKER}:${
    import.meta.env.VITE_MQTT_WEBSOCKET_PORT
  }/mqtt`;

  useEffect(() => {
    if (client) {
      client.on('connect', () =>
        client.subscribe(`mda/1/${organizationUuid}/${mqttTopic}`, { qos: 0 }),
      );
      client.on('error', (error) => {
        console.error(error);
        client.end();
      });
      client.on('message', (topic: string, message: Buffer) => {
        setMessage(message.toString());
        setTopic(topic);
        setTimestamp(new Date());
      });
    }

    return () => {
      client?.end();
    };
  }, [client, mqttTopic, organizationUuid]);

  useEffect(() => {
    // Check if user has ACLs, otherwise he won't be able to subscribe
    // Do not subscribe if "skip" is true as Bluerange doesn't support all features
    if (!client && mqttTopic && user?.acls?.sub && !skip) {
      setClient(
        mqtt.connect(broker, {
          username: mqttUser,
          password: mqttPassword,
          clientId: `mda2-${mqttUser}-${Math.random()
            .toString(16)
            .substring(2, 8)}`,
        }),
      );
    }
  }, [mqttPassword, mqttUser, mqttTopic, client, user?.acls?.sub, skip]);

  useEffect(() => {
    if (token && client) {
      client.options.password = token;
      setMqttPassword(token);
    }
  }, [client, token]);

  return { message, topic, timestamp };
}
