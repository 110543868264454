import { forwardRef, type ButtonHTMLAttributes } from 'react';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    id,
    onClick,
    onMouseOver,
    onFocus,
    className,
    title,
    disabled,
    children,
    ...rest
  } = props;
  return (
    <button
      id={id}
      ref={ref}
      type="button"
      onClick={onClick}
      onMouseOver={onMouseOver}
      onFocus={onFocus}
      className={`${className} transition-all disabled:opacity-50 disabled:cursor-default`}
      title={title}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
});

export default Button;
