import { DatePickerInput } from 'generic/components/Form/DatePicker';
import Subtitle from 'generic/components/Subtitle/Subtitle';
import Tooltip from 'generic/components/Tooltip/Tooltip';
import Transition from 'generic/components/Transition/Transition';
import {
  useDeleteMaintenanceMessageMutation,
  useEnterMaintenanceMutation,
  useMaintenanceMessagesQuery,
} from 'graphql/types';
import { FormattedMessage, useIntl } from 'translations/Intl';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import useToast from 'utils/graphql/useToast';

import { addDays } from 'date-fns';
import { Action } from 'mda2-frontend/src/common/types';
import Modal from 'mda2-frontend/src/generic/components/Modal';
import ModalFooter from 'mda2-frontend/src/generic/components/ModalFooter';
import { serializeRange } from 'mda2-frontend/src/utils/date';
import format from 'mda2-frontend/src/utils/format';
import { useMemo, useState } from 'react';
import { HiOutlineTrash } from 'react-icons/hi2';

interface MaintenanceModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function MaintenanceModal({
  open,
  setOpen,
}: MaintenanceModalProps): JSX.Element {
  const intl = useIntl();
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(addDays(new Date(), 1));
  const hasuraHeader = useHasuraHeader();
  const toast = useToast();
  const [{ fetching }, enterMaintenanceMode] = useEnterMaintenanceMutation();
  const [, deleteMaintenanceMessage] = useDeleteMaintenanceMessageMutation();
  const [{ data: maintenanceMessages }] = useMaintenanceMessagesQuery({
    context: useMemo(
      () => ({ additionalTypenames: ['MaintenanceMessages'] }),
      [],
    ),
  });

  return (
    <Modal
      title={intl.formatMessage({
        id: 'Confirm action',
      })}
      action={Action.REMOVE}
      open={open}
      setShowModal={setOpen}
      footer={
        <ModalFooter
          disabled={false}
          proceed={intl.formatMessage({
            id: 'Enter maintenance mode',
          })}
          isLoading={fetching}
          action={Action.REMOVE}
          onProceed={() => {
            enterMaintenanceMode(
              {
                Duration: serializeRange({
                  start: {
                    value: start,
                    inclusive: true,
                  },
                  end: {
                    value: end,
                    inclusive: false,
                  },
                }),
              },
              hasuraHeader(HasuraPermissions.READ_ALL),
            ).then((data) => {
              toast(data, {
                message: {
                  type: 'success',
                  content: intl.formatMessage(
                    { id: 'Maintenance mode activated' },
                    {
                      from: format(new Date(start), 'PPp'),
                      to: format(new Date(end), 'PPp'),
                    },
                  ),
                },
              });
              if (!data.error) {
                setOpen(false);
              }
            });
          }}
          onCancel={() => setOpen(false)}
        />
      }
    >
      <div>
        <Transition show={!!maintenanceMessages?.MaintenanceMessages.length}>
          <Subtitle
            value={intl.formatMessage({
              id: 'Maintenance periods',
            })}
          />
        </Transition>
        {maintenanceMessages?.MaintenanceMessages.map(
          ({
            DurationLower: startDuration,
            DurationUpper: endDuration,
            Id: id,
          }) => (
            <div key={id} className="flex space-x-2 items-center">
              <div>
                {format(new Date(startDuration), 'PPp')} -{' '}
                {format(new Date(endDuration), 'PPp')}
              </div>
              <Tooltip
                content={
                  <p>
                    <HiOutlineTrash
                      onClick={() =>
                        deleteMaintenanceMessage(
                          { Id: id },
                          hasuraHeader(HasuraPermissions.READ_ALL),
                        ).then((data) =>
                          toast(data, {
                            message: {
                              type: 'success',
                              content: intl.formatMessage({
                                id: 'Maintenance mode deactivated',
                              }),
                            },
                          }),
                        )
                      }
                      className="size-6 p-1 bg-primary-300 rounded-full cursor-pointer"
                    />
                  </p>
                }
              >
                <FormattedMessage id="Delete" />
              </Tooltip>
            </div>
          ),
        )}
        <Subtitle
          value={intl.formatMessage({
            id: 'Add period',
          })}
        />
        <div className="flex md:block flex-wrap space-x-2 md:space-x-0">
          <DatePickerInput
            label="Date from"
            options={{
              enableTime: true,
              defaultDate: [start],
              dateFormat: 'M j H:i',
              minDate: 'today',
              maxDate: end,
              onChange: ([selectedDate]) =>
                setStart(selectedDate ?? new Date()),
            }}
          />
          <DatePickerInput
            label="Date to"
            options={{
              enableTime: true,
              defaultDate: [end],
              dateFormat: 'M j H:i',
              minDate: 'today',
              onChange: ([selectedDate]) => setEnd(selectedDate ?? new Date()),
            }}
          />
        </div>
      </div>
    </Modal>
  );
}
