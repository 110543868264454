import Accordion from 'generic/components/Accordion';
import { FormattedMessage, type IntlMessageKeys } from 'translations/Intl';

import {
  type 
  GetCurrentSceneQuery,
  type 
  MqttSystems,
  useGetCurrentSceneQuery,
} from 'mda2-frontend/src/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import { useMemo } from 'react';

interface MqttStatusProps {
  mqttTopic: string;
  mqttSystem: MqttSystems;
}

const sceneIds: { [idx: number]: IntlMessageKeys } = {
  0: 'No scene',
  1: 'Identify',
  2: 'Forced',
  3: 'Reset',
  4: 'Cleaning',
  5: 'Reserved',
};
type SceneKeys = keyof typeof sceneIds;

const renderCurrentScene = (
  sceneInfo: GetCurrentSceneQuery['GetCurrentScene'],
  error: string | undefined,
) => {
  if (
    sceneInfo?.successResponses &&
    typeof sceneInfo.successResponses[0]?.sceneId === 'number'
  ) {
    const sceneId = sceneInfo.successResponses[0]?.sceneId;
    return Object.keys(sceneIds)
      .map((k) => Number.parseInt(k, 10))
      .includes(sceneId) ? (
      <>
        <FormattedMessage id={sceneIds[sceneId as SceneKeys]} />
        {sceneId !== 0 && <span>{sceneInfo.successResponses[0]?.timeout}</span>}
      </>
    ) : (
      <FormattedMessage id="Unknown" />
    );
  }
  return error ?? <FormattedMessage id="No information available" />;
};

export default function MqttStatus({
  mqttTopic,
  mqttSystem,
}: MqttStatusProps): JSX.Element {
  const hasuraHeader = useHasuraHeader();
  const [
    {
      data: currentSceneResponse,
      fetching: currentSceneLoading,
      error: errorCurrentScene,
    },
  ] = useGetCurrentSceneQuery({
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.READ_ALL),
      [hasuraHeader],
    ),
    variables: {
      Topics: [mqttTopic],
      MqttSystem: mqttSystem,
    },
  });

  return (
    <Accordion
      initialStateOpen
      title={
        <FormattedMessage id="System info" values={{ system: mqttSystem }} />
      }
    >
      <div className="text-xs flex space-x-2 items-center">
        <div>
          <FormattedMessage id="Scene" />
        </div>
        {': '}
        {currentSceneLoading ? (
          <div className="w-20 animate-pulse bg-neutral-200 h-4 rounded-md" />
        ) : (
          renderCurrentScene(
            currentSceneResponse?.GetCurrentScene,
            errorCurrentScene?.message,
          )
        )}
      </div>
    </Accordion>
  );
}
