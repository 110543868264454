import Subtitle from 'generic/components/Subtitle/Subtitle';
import { Action, type Floor } from 'mda2-frontend/src/common/types';
import Input from 'mda2-frontend/src/generic/components/Form/Input';
import Modal from 'mda2-frontend/src/generic/components/Modal';
import ModalFooter from 'mda2-frontend/src/generic/components/ModalFooter';
import { useDeleteFloorMutation } from 'mda2-frontend/src/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import useToast from 'mda2-frontend/src/utils/graphql/useToast';
import useStore from 'model/store';
import { useState } from 'react';
import { HiOutlineTrash } from 'react-icons/hi2';
import { FormattedMessage, useIntl } from 'translations/Intl';

interface RemoveFloorModalProps {
  toRemove?: Floor;
  setToRemove: (floorToRemove: Floor | undefined) => void;
}

export default function RemoveFloorModal({
  toRemove = undefined,
  setToRemove,
}: RemoveFloorModalProps): JSX.Element {
  const intl = useIntl();
  const toast = useToast();
  const AAWActivated = useStore((state) => state.AAWActivated);
  const hasuraHeader = useHasuraHeader();
  const [confirmDelete, setConfirmDelete] = useState<number | string>('');
  const [{ fetching: isRemoving }, removeFloor] = useDeleteFloorMutation();

  const privateRooms = toRemove?.Rooms?.filter((r) =>
    r.RoomSensors.find((rS) => rS.Sensor.IsPrivate),
  )?.map((r) => r.Name);

  return (
    <Modal
      title={intl.formatMessage({
        id: 'Confirm action',
      })}
      action={Action.REMOVE}
      open={!!toRemove}
      setShowModal={() => setToRemove(undefined)}
      footer={
        <ModalFooter
          disabled={confirmDelete !== toRemove?.Number}
          action={Action.REMOVE}
          proceed={intl.formatMessage({
            id: 'Confirm',
          })}
          isLoading={isRemoving}
          onProceed={() => {
            if (confirmDelete === toRemove?.Number) {
              removeFloor(
                {
                  FloorId: toRemove.Id,
                  Input: {
                    floorId: toRemove.Id,
                  },
                  AAWActivated,
                },
                hasuraHeader(HasuraPermissions.DELETE_FLOOR, ['MqttBeacons']),
              )
                .then((data) => {
                  toast(data, { field: 'Number' });
                })
                .finally(() => {
                  setToRemove(undefined);
                  setConfirmDelete('');
                });
            }
          }}
          onCancel={() => {
            setToRemove(undefined);
            setConfirmDelete('');
          }}
        />
      }
    >
      <Subtitle
        value={intl.formatMessage({ id: 'Are you sure to remove this floor?' })}
        className="text-base pb-2"
      />

      <p className="pb-2">
        <FormattedMessage
          id="Deleting the floor will delete all associated rooms and desks. Enter the floor number {number} in order to delete it"
          values={{
            number: <b>{toRemove?.Number}</b>,
          }}
        />
      </p>

      {(privateRooms ?? []).length > 0 ? (
        <div className="flex flex-col pb-2 gap-1 font-bold">
          <span>
            {privateRooms?.length === 1 ? (
              <FormattedMessage id="There is private room on this floor:" />
            ) : (
              <FormattedMessage id="There are private rooms on this floor:" />
            )}
          </span>
          <ul className="px-4">
            {privateRooms?.slice(0, 4).map((privateRoomName) => (
              <li key={privateRoomName}>- {privateRoomName}</li>
            ))}
            {(privateRooms?.length ?? 0) > 4 ? <li>- ...</li> : null}
          </ul>
          <span>
            <FormattedMessage id="By removing this floor, the entire history of the related beacons will be irrevocably deleted." />
          </span>
        </div>
      ) : null}

      <Input
        type="number"
        value={confirmDelete}
        icon={<HiOutlineTrash />}
        id="Remove floor"
        data-test-id="remove-floor"
        onChangeValue={(input) => setConfirmDelete(Number.parseInt(input, 10))}
        placeholder={toRemove?.Number.toString()}
        required
      />
    </Modal>
  );
}
