import type { Colors, RoomFeatures } from 'mda2-frontend/src/common/types';
import getColor from 'mda2-frontend/src/utils/getColor';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';

import AdminLayer, { type AdminLayerOptions, type Options } from './AdminLayer';

type StyleFunction = (
  feat: RoomFeatures,
  _: number,
  color: keyof typeof Colors,
  selected: RoomFeatures,
  hidden: boolean,
  highlighted: RoomFeatures | null,
) => Style | Style[] | null;

const styleFunction = (
  feat: RoomFeatures,
  _: number,
  color: keyof typeof Colors,
  selected: RoomFeatures,
) => [
  new Style({
    stroke: new Stroke({
      color: getColor(color, '.5'),
      width: 3,
    }),
    fill: new Fill({
      color: getColor(color, feat === selected ? '.9' : '.5'),
    }),
  }),
];

interface AdminPolygonOptions extends Options {
  styleFunction?: StyleFunction;
}

interface AdminPolygonLayerOptions extends AdminLayerOptions {
  styleFunction?: StyleFunction;
}

class AdminPolygonLayer extends AdminLayer {
  constructor(options?: AdminPolygonOptions) {
    super({
      styleFunction,
      ...options,
    } as AdminPolygonLayerOptions);
  }
}

export default AdminPolygonLayer;
