import type Feature from 'ol/Feature';
import type MapBrowserEvent from 'ol/MapBrowserEvent';
import Event from 'ol/events/Event';
import type Geometry from 'ol/geom/Geometry';

export enum MoveEventType {
  START = 'movestart',
  END = 'moveend',
}

export default class MoveEvent extends Event {
  feature: Feature<Geometry>;

  mapBrowserEvent: MapBrowserEvent<PointerEvent>;

  constructor(
    type: MoveEventType,
    feature: Feature<Geometry>,
    mapBrowserPointerEvent: MapBrowserEvent<PointerEvent>,
  ) {
    super(type);
    this.feature = feature;
    this.mapBrowserEvent = mapBrowserPointerEvent;
  }
}
