import type { Feature } from 'ol';
import type OLMap from 'ol/Map';
import { unByKey } from 'ol/Observable';
import type { Coordinate } from 'ol/coordinate';
import type Geometry from 'ol/geom/Geometry';
import type Point from 'ol/geom/Point';
import type Polygon from 'ol/geom/Polygon';
import type OLLayer from 'ol/layer/Layer';
import type OLVectorLayer from 'ol/layer/Vector';
import type LayerRenderer from 'ol/renderer/Layer';
import type Source from 'ol/source/Source';
import type VectorSource from 'ol/source/Vector';
import type Style from 'ol/style/Style';

import type { EventsKey } from 'ol/events';
import Layer, { type LayerOptions } from './Layer';

export interface VectorLayerOptions<T> extends LayerOptions {
  olLayer: OLVectorLayer<
    VectorSource<Feature<T extends Geometry ? T : Geometry>>
  >;
  hitTolerance?: number;
  style?: (feat: Feature<Geometry>) => Style[];
}

/**
 * A class use to display vector data.
 *
 * @extends {Layer}
 */
class VectorLayer<T> extends Layer {
  hitTolerance: number;

  singleClickRef?: EventsKey;

  selected: Feature<Geometry> | null;

  /**
   * Constructor.
   *
   * @param {Object} [options]
   * @param {number} [options.hitTolerance=5] Pixel value of the click hitTolerance of clicks.
   */
  constructor(options: VectorLayerOptions<T>) {
    super(options);

    /** @ignore */
    this.hitTolerance = options.hitTolerance || 5;

    this.selected = null;
  }

  /**
   * Request feature information for a given coordinate.
   * @param {ol/coordinate~Coordinate} coordinate the coordinate to request the information at.
   * @returns {Promise<Object>} Promise with features, layer and coordinate
   *  or null if no feature was hit.
   * eslint-disable-next-line class-methods-use-this
   */
  getFeatureInfoAtCoordinate(coordinate: Coordinate): Promise<{
    features: Feature<Polygon>[];
    layer: VectorLayer<T>;
    coordinate?: Coordinate;
  }> {
    let features: Feature<Polygon>[] = [];

    if (this.map) {
      const pixel = this.map.getPixelFromCoordinate(coordinate);
      features = this.map.getFeaturesAtPixel(pixel, {
        layerFilter: (l: OLLayer<Source, LayerRenderer<any>>) =>
          l === this.olLayer,
        hitTolerance: this.hitTolerance,
      }) as Feature<Polygon>[];
    }

    return Promise.resolve({
      features,
      layer: this,
      coordinate,
    });
  }

  /**
   * Initialize the layer and listen to feature clicks.
   * @param {ol/Map~Map} map
   */
  init(map: OLMap): void {
    super.init(map);

    if (!this.map) {
      return;
    }

    /**
     * ol click events key, returned by map.on('singleclick')
     * @type {ol/events~EventsKey}
     * @private
     */
    this.singleClickRef = this.map.on('singleclick', (e) => {
      if (!this.clickCallbacks?.length) {
        return;
      }

      this.getFeatureInfoAtCoordinate(e.coordinate)
        .then((d) =>
          this.callClickCallbacks(d.features, d.layer, d.coordinate!),
        )
        .catch(() => this.callClickCallbacks([], this, e.coordinate));
    });
  }

  /**
   * Call click callbacks with given parameters.
   * This is done in a separate function for being able to modify the response.
   * @param {Array<ol/Feature~Feature>} features
   * @param {ol/layer/Layer~Layer} layer
   * @param {ol/coordinate~Coordinate} coordinate
   * @private
   */
  callClickCallbacks(
    features: Feature<Polygon | Point>[],
    layer: Layer | VectorLayer<T>,
    coordinate: number[],
  ): void {
    if (this.clickCallbacks?.length) {
      for (const c of this.clickCallbacks) {
        c(features, layer, coordinate);
      }
    }
  }

  /**
   * Terminate what was initialized in init function. Remove layer, events...
   */
  terminate(): void {
    super.terminate();
    if (this.singleClickRef) {
      unByKey(this.singleClickRef);
    }
  }
}

export default VectorLayer;
