interface SliderRailProps {
  getRailProps: () => any;
  className?: string;
}

export default function SliderRail({
  getRailProps,
  className = '',
}: SliderRailProps) {
  return (
    <>
      <div className="react_time_range__rail__outer" {...getRailProps()} />
      <div className={`react_time_range__rail__inner ${className}`} />
    </>
  );
}
