import Subtitle from 'generic/components/Subtitle';
import { useBuildingsTopologyQuery } from 'graphql/types';
import useStore from 'model/store';
import ClimateValues from 'pages/ClimateView/components/ClimateValues';
import { useMemo } from 'react';
import { useIntl } from 'translations/Intl';
import { lower, upper } from 'utils/date';
import format from 'utils/format';
import ReportingPage from '../ReportingPage';
import ReportMap from './components/ReportMap';

export default function ReportDescription() {
  const intl = useIntl();
  const building = useStore((state) => state.userSettings.building);
  const organizationName = useStore(
    (state) => state.organizationSettings.organizationName,
  );
  const { businessHours, warmMinutesPolicy } = useStore(
    (state) => state.organizationSettings,
  );
  const dateRange = useStore((state) => state.userSettings.dateRange);
  const dateFrom = useMemo(() => new Date(dateRange.start), [dateRange.start]);
  const dateTo = useMemo(
    () => (dateRange.end ? new Date(dateRange.end) : new Date()),
    [dateRange.end],
  );

  const businessHoursWithoutLunch = useMemo(
    () =>
      businessHours.BusinessHours &&
      `${format(lower(businessHours.BusinessHours), 'HH:mm')}-${format(
        upper(businessHours.BusinessHours),
        'HH:mm',
      )}`,
    [businessHours],
  );

  const businessHoursWithLunch = useMemo(
    () =>
      businessHours.LunchHours &&
      `${format(lower(businessHours.BusinessHours), 'HH:mm')}-${format(
        lower(businessHours.LunchHours),
        'HH:mm',
      )} / ${format(upper(businessHours.LunchHours), 'HH:mm')}-${format(
        upper(businessHours.BusinessHours),
        'HH:mm',
      )}`,
    [businessHours],
  );

  const [{ data, fetching }] = useBuildingsTopologyQuery();

  const floors = useMemo(
    () =>
      data?.Buildings?.find((b) => b.Name === building?.Name)
        ?.Floors.slice()
        .sort((a, b) => a.Number - b.Number),
    [data?.Buildings, building?.Name],
  );

  return (
    <ReportingPage
      loading={fetching}
      key={organizationName}
      reportTitle="Overview"
    >
      <div className="flex flex-col">
        <Subtitle
          value={intl.formatMessage(
            { id: 'Duration from to' },
            { from: format(dateFrom, 'PP'), to: format(dateTo, 'PP') },
          )}
        />
        <Subtitle
          value={`${intl.formatMessage({ id: 'Building' })}: ${building?.Name}`}
        />
        <div className="flex flex-wrap space-x-2">
          {floors?.map((f) => (
            <div className="w-48" key={f.Number}>
              <ReportMap floor={f} />
            </div>
          ))}
        </div>
        <Subtitle
          value={`${intl.formatMessage({ id: 'Business hours' })}: ${businessHoursWithLunch ?? businessHoursWithoutLunch}`}
        />
        <Subtitle
          value={`${intl.formatMessage({ id: 'Desksharing policy (in minutes)' })}: ${warmMinutesPolicy}`}
        />
        <Subtitle value={intl.formatMessage({ id: 'Climate limits' })} />
        <ClimateValues hideRecommended />
      </div>
    </ReportingPage>
  );
}
