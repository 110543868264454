import type { Row, TableState } from '@tanstack/react-table';

import IndeterminateCheckbox from '../IndeterminateCheckbox';

type DisabledItem = Record<string, unknown> & { disabled: boolean };

export function isDisableableItem(
  item: unknown | DisabledItem,
): item is DisabledItem {
  return !!(item as DisabledItem).disabled;
}

interface CheckboxCellProps<T> {
  row: Row<T>;
  onSelectedChange?: (row: Row<T>) => void;
  initialState?: Partial<TableState>;
}

export default function CheckboxCell<T>({
  row,
  onSelectedChange,
  initialState,
}: CheckboxCellProps<T>) {
  return (
    <div className="px-1">
      <IndeterminateCheckbox
        disabled={
          isDisableableItem(row.original) ? row.original.disabled : false
        }
        // Checked if
        // 1. Row is not disabled
        // 2. Row is disabled, but data was already preselected
        checked={
          (row.getIsSelected() &&
            (isDisableableItem(row.original)
              ? !row.original.disabled
              : true)) ||
          ((isDisableableItem(row.original) ? row.original.disabled : false) &&
            initialState?.rowSelection &&
            initialState.rowSelection[row.index])
        }
        indeterminate={row.getIsSomeSelected()}
        onChange={() => {
          row.toggleSelected();
          if (onSelectedChange) {
            onSelectedChange(row);
          }
        }}
      />
    </div>
  );
}
