import useStore from 'model/store';
import { useIntl } from 'translations/Intl';

import Input from '../Input';

export default function CleaningInput() {
  const intl = useIntl();
  const cleaningTimes = useStore(
    (state) => state.organizationSettings.cleaningTimes,
  );
  const setCleaningTimes = useStore(
    (state) => state.organizationApi.setCleaningTimes,
  );
  const { check: timeBeforeCheck, clean: timeBeforeClean } = cleaningTimes;

  return (
    <div className="flex space-x-4 items-center">
      <Input
        type="number"
        value={timeBeforeCheck}
        min={0}
        label={intl.formatMessage({ id: 'Time before checking (in minutes)' })}
        onChangeValue={(v) =>
          setCleaningTimes({
            check: Number.parseInt(v, 10),
            clean: timeBeforeCheck,
          })
        }
      />
      <Input
        type="number"
        value={timeBeforeClean}
        min={timeBeforeCheck}
        label={intl.formatMessage({ id: 'Time before cleaning (in minutes)' })}
        onChangeValue={(v) =>
          setCleaningTimes({
            check: timeBeforeCheck,
            clean: Number.parseInt(v, 10),
          })
        }
      />
    </div>
  );
}
