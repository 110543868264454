import type { FloorRoomMapFeatures } from 'common/types';
import { HiOutlineArrowUturnLeft } from 'react-icons/hi2';
import { FormattedMessage } from 'translations/Intl';
import { select } from '../../../../mapElements';
import { resetFeatureGeometry } from '../../../../utils/helpers';
import CanvasButton from '../../../CanvasButton';

interface ResetGeometryButtonProps {
  features: FloorRoomMapFeatures[];
  onGeometryClose: (isSaving: boolean) => void;
}

export default function ResetGeometryButton({
  features,
  onGeometryClose,
}: ResetGeometryButtonProps) {
  return (
    <CanvasButton
      tooltip={<FormattedMessage id="Reset" />}
      data-test-id="reset-geometry-button"
      onClick={() => {
        for (const f of features) {
          resetFeatureGeometry(f);
        }
        select.getFeatures().clear();
        onGeometryClose(false);
      }}
    >
      <HiOutlineArrowUturnLeft className="size-5" />
    </CanvasButton>
  );
}
