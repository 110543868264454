import type { NumberArray2 } from 'mda2-frontend/src/common/types';
import Feature from 'ol/Feature';
import type MapBrowserEvent from 'ol/MapBrowserEvent';
import { mouseOnly } from 'ol/events/condition';
import type Geometry from 'ol/geom/Geometry';
import LineString from 'ol/geom/LineString';
import type Polygon from 'ol/geom/Polygon';
import Draw from 'ol/interaction/Draw';
import type VectorSource from 'ol/source/Vector';
import { getDestination } from './moveDesk';

enum Directions {
  X = 'x',
  Y = 'y',
}

interface DrawRoomOptions {
  // TODO: OL 9.2.5 requires this hack of adding Feature<Geometry> otherwise there are type errors
  source: VectorSource<Feature<Geometry | Polygon>>;
  snapSource: VectorSource<Feature<Geometry | LineString>>;
}

class DrawRoom extends Draw {
  // TODO: OL 9.2.5 requires this hack of adding Feature<Geometry> otherwise there are type errors
  source: VectorSource<Feature<Geometry | Polygon>>;

  snapSource: VectorSource<Feature<Geometry | LineString>>;

  rightAngled: boolean;

  firstCoordinates?: NumberArray2;

  previousCoordinates?: NumberArray2;

  newCoordinates?: NumberArray2;

  direction?: Directions;

  mapExtent?: number[];

  constructor(options: DrawRoomOptions) {
    super({
      ...options,
      type: 'Polygon',
      condition: mouseOnly,
    });
    this.source = options.source;
    this.snapSource = options.snapSource;
    this.rightAngled = false;
    this.newCoordinates = undefined;
    this.firstCoordinates = undefined;
    this.previousCoordinates = undefined;

    window.addEventListener('keydown', (e) => {
      if (e.ctrlKey) {
        this.rightAngled = true;
      }
    });
    window.addEventListener('keyup', () => {
      if (this.rightAngled) {
        this.rightAngled = false;
      }
    });
  }

  handleDownEvent(evt: MapBrowserEvent<PointerEvent>): boolean {
    if (evt.type === 'pointerdown') {
      if (!this.firstCoordinates) {
        this.firstCoordinates =
          evt.coordinate[0] && evt.coordinate[1]
            ? [evt.coordinate[0], evt.coordinate[1]]
            : undefined;
        if (this.firstCoordinates && this.mapExtent) {
          // Add snap lines based on this first click to be able to finish drawing with right angles
          const distance = Math.max(...this.mapExtent);
          const destinations = [-90, 0, 90, 180].map((bearing) =>
            getDestination(
              this.firstCoordinates as NumberArray2,
              distance * 2,
              bearing,
            ),
          );
          const [destination1, destination2, destination3, destination4] =
            destinations;
          if (destination1 && destination2 && destination3 && destination4) {
            const snapLine1 = new LineString([destination1, destination3]);
            const snapLine2 = new LineString([destination2, destination4]);
            const snapLineFeatures = [snapLine1, snapLine2].map(
              (geometry) =>
                new Feature({
                  geometry,
                }),
            );
            this.snapSource.clear();
            this.snapSource.addFeatures(snapLineFeatures);
          }
        }
      }
      if (this.newCoordinates && this.rightAngled) {
        this.previousCoordinates =
          this.newCoordinates[0] && this.newCoordinates[1]
            ? [this.newCoordinates[0], this.newCoordinates[1]]
            : undefined;
      } else {
        this.previousCoordinates =
          evt.coordinate[0] && evt.coordinate[1]
            ? [evt.coordinate[0], evt.coordinate[1]]
            : undefined;
      }
    }
    return super.handleDownEvent(evt);
  }

  handleEvent(evt: MapBrowserEvent<PointerEvent>): boolean {
    if (
      evt.type === 'pointermove' &&
      this.rightAngled &&
      typeof this.previousCoordinates?.[0] === 'number' &&
      typeof evt.coordinate[0] === 'number' &&
      typeof this.previousCoordinates[1] === 'number' &&
      typeof evt.coordinate[1] === 'number'
    ) {
      const xDistance = Math.abs(
        this.previousCoordinates[0] - evt.coordinate[0],
      );
      const yDistance = Math.abs(
        this.previousCoordinates[1] - evt.coordinate[1],
      );
      this.direction = xDistance >= yDistance ? Directions.X : Directions.Y;
      this.newCoordinates =
        this.direction === Directions.X
          ? [evt.coordinate[0], this.previousCoordinates[1]]
          : [this.previousCoordinates[0], evt.coordinate[1]];
      // Reassign evt coordinate to be able to force line direction on Ctrl Hold
      evt.coordinate = [...this.newCoordinates];
    }
    return super.handleEvent(evt);
  }

  handleUpEvent(evt: MapBrowserEvent<PointerEvent>): boolean {
    if (this.rightAngled && this.newCoordinates) {
      evt.coordinate = [...this.newCoordinates];
    }

    return super.handleUpEvent(evt);
  }

  cleanUpVariables() {
    // Clean up variables on drawend
    this.snapSource.clear();
    this.firstCoordinates = undefined;
    this.previousCoordinates = undefined;
    this.newCoordinates = undefined;
  }

  setMapExtent(newExtent: number[]) {
    this.mapExtent = newExtent;
  }

  finishDrawing() {
    this.cleanUpVariables();
    return super.finishDrawing();
  }
}

export default DrawRoom;
