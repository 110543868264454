import useStore from 'model/store';
import { FormattedMessage, type IntlMessageKeys } from 'translations/Intl';
import type { HasuraPermissions } from 'utils/graphql/useHasuraHeaders';

import Switch from 'mda2-frontend/src/generic/components/Form/Switch';
import type Layer from 'mda2-frontend/src/generic/layers/Layer';
import { type EventTypes, unByKey } from 'ol/Observable';
import type { EventsKey } from 'ol/events';
import { useCallback, useEffect, useState } from 'react';

export interface Layers {
  name: IntlMessageKeys;
  layers: Layer[];
  permissions: HasuraPermissions;
  image?: JSX.Element;
  onSetEnable: (enabled: boolean) => void;
}
interface LayerTogglerProps {
  layers: Layers[];
}

const olListenersKeys: EventsKey[] = [];

export default function LayerToggler({
  layers,
}: LayerTogglerProps): JSX.Element {
  const userRoles = useStore((state) => state.user)?.roles;
  const [visibleLayers, setVisibleLayers] = useState(
    layers.filter((l) => l.layers[0]?.visible),
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Do not need to reset listener on layers change
  const setListener = useCallback(() => {
    unByKey(olListenersKeys);
    for (const lay of layers) {
      lay.layers.map((layer) => {
        olListenersKeys.push(
          layer.on('change:visible' as EventTypes, () => {
            setVisibleLayers(layers.filter((l) => l.layers[0]?.visible));
          }),
        );
      });
    }
  }, []);

  useEffect(() => {
    setListener();
    return () => {
      unByKey(olListenersKeys);
    };
  }, [setListener]);

  return (
    <div className="flex flex-col gap-2">
      {layers
        .filter(
          (l) =>
            (l.name as string) !== 'base-layer' &&
            userRoles?.includes(l.permissions),
        )
        .map((l) => (
          <Switch
            key={l.name}
            isEnabled={!!visibleLayers.find((layer) => layer.name === l.name)}
            onSetEnable={() => {
              const newVisibility = !l.layers[0]?.visible;
              l.onSetEnable(newVisibility);
              for (const layer of l.layers) {
                layer.setVisible(newVisibility);
              }
            }}
            label={
              <div className="flex items-center space-x-2">
                {l.image}
                <div>
                  <FormattedMessage id={l.name} />
                </div>
              </div>
            }
            labelClassName="justify-between"
            labelPosition="left"
          />
        ))}
    </div>
  );
}
