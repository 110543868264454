import StyledButton from 'generic/components/Form/Button/StyledButton';
import {
  ReportPageMetric_Constraint,
  ReportPageMetric_Update_Column,
  useInsertMetricLimitsMutation,
  useReportMetricsQuery,
} from 'graphql/types';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'translations/Intl';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import useReportFilter from 'utils/graphql/useReportFilter';
import useToast from 'utils/graphql/useToast';
import ReportingPage from '../ReportingPage';
import ReportEntry from './ReportEntry';

export default function ReportLimits() {
  const intl = useIntl();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();
  const reportFilter = useReportFilter(['MetricLimits']);

  const [trackedReportPageMetricIds, setTrackedReportPageMetricIds] = useState(
    new Map<string, boolean>(),
  );
  const [data, setData] = useState(
    new Map<
      string,
      {
        targetValue: number;
        divideByTargetValue: boolean;
        weight: number;
      }
    >(),
  );
  const [{ fetching }, insertMetricLimits] = useInsertMetricLimitsMutation();

  const [{ data: reportMetrics }] = useReportMetricsQuery(reportFilter);

  useEffect(() => {
    if (reportMetrics?.ReportPages) {
      setTrackedReportPageMetricIds(
        new Map(
          reportMetrics.ReportPages.flatMap((r) =>
            r.ReportPageMetrics.map((rpm) => ({
              Id: rpm.Id,
              enabled: !!rpm.MetricLimits.length,
            })),
          ).map((d) => [d.Id, d.enabled]),
        ),
      );
    }
  }, [reportMetrics?.ReportPages]);

  const trackedReports = useMemo(
    () =>
      Array.from(trackedReportPageMetricIds).filter(([, enabled]) => enabled)
        .length > 0,
    [trackedReportPageMetricIds],
  );

  const invalidWeightSum = useMemo(
    () =>
      Array.from(data)
        .filter(([id]) => !!trackedReportPageMetricIds.get(id))
        .map(([_, { weight }]) => weight)
        .reduce((prev, curr) => prev + curr, 0) !== 100 && trackedReports,
    [data, trackedReportPageMetricIds, trackedReports],
  );

  return (
    <ReportingPage reportTitle="Report settings" className="print:hidden">
      <div className="flex flex-col space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {reportMetrics?.ReportPages.map((r) => (
            <ReportEntry
              key={r.Id}
              data={r}
              setData={setData}
              enabledMetrics={trackedReportPageMetricIds}
              setEnabledMetrics={setTrackedReportPageMetricIds}
              validWeight={!invalidWeightSum}
            />
          ))}
        </div>
        <div className="flex justify-end">
          <StyledButton
            isLoading={fetching}
            disabled={invalidWeightSum || !trackedReports}
            onClick={() =>
              insertMetricLimits(
                {
                  Limits: Array.from(data)
                    // Filter limits that should not be tracked
                    .filter(([id]) => !!trackedReportPageMetricIds.get(id))
                    .map(([reportPageMetricId, entry]) => {
                      const reportPagesId = reportMetrics?.ReportPages.find(
                        (rp) =>
                          rp.ReportPageMetrics.some(
                            (rpm) => rpm.Id === reportPageMetricId,
                          ),
                      )?.Id;
                      const metricsId = reportMetrics?.ReportPages.find(
                        (rp) => rp.Id === reportPagesId,
                      )?.ReportPageMetrics.find(
                        (rpm) => rpm.Id === reportPageMetricId,
                      )?.Metric.Id;
                      const metricLimitId = reportMetrics?.ReportPages.find(
                        (rp) => rp.Id === reportPagesId,
                      )?.ReportPageMetrics.find(
                        (rpm) => rpm.Id === reportPageMetricId,
                      )?.MetricLimits[0]?.Id;

                      return {
                        Id: metricLimitId,
                        Weight: entry.weight,
                        DivideByTargetValue: entry.divideByTargetValue,
                        TargetValue: entry.targetValue,
                        ReportPageMetric: {
                          data: {
                            Id: reportPageMetricId,
                            ReportPagesId: reportPagesId,
                            MetricsId: metricsId,
                          },
                          on_conflict: {
                            constraint:
                              ReportPageMetric_Constraint.ReportPageMetricPkey,
                            update_columns: [ReportPageMetric_Update_Column.Id],
                          },
                        },
                      };
                    }),
                  UntrackMetrics: Array.from(trackedReportPageMetricIds)
                    .filter(([_, tracked]) => !tracked)
                    .map(([id]) => id),
                },
                hasuraHeader(HasuraPermissions.VIEW_REPORTS),
              ).then((data) =>
                toast(data, {
                  message: {
                    content: intl.formatMessage({
                      id: 'Saved report settings',
                    }),
                    type: 'success',
                  },
                }),
              )
            }
          >
            <FormattedMessage id="Save" />
          </StyledButton>
        </div>
      </div>
    </ReportingPage>
  );
}
