import {
  addDays,
  addHours,
  differenceInDays,
  isAfter,
  isSameDay,
  startOfDay,
} from 'date-fns';
import Card from 'mda2-frontend/src/generic/components/Card';
import Overview, {
  desksPerBuilding,
  findValue,
} from 'mda2-frontend/src/generic/components/Overview';
import {
  Order,
  Types,
} from 'mda2-frontend/src/generic/components/Overview/OverviewRow';
import {
  useAllFloorReservationsQuery,
  useOrganizationTopologyQuery,
} from 'mda2-frontend/src/graphql/types';
import useStore from 'mda2-frontend/src/model/store';
import { lower, upper } from 'mda2-frontend/src/utils/date';
import deskIsUsed from 'mda2-frontend/src/utils/deskIsUsed';
import getColor from 'mda2-frontend/src/utils/getColor';
import usePolling from 'mda2-frontend/src/utils/graphql/usePolling';
import useRoomDeskFilter from 'mda2-frontend/src/utils/graphql/useRoomDeskFilter';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import FavoriteReservation from '../FavoriteReservation';
import ReservationDate from '../ReservationDate';
import ReservationMap from '../ReservationMap';

export default function ReservationOverview(): JSX.Element {
  const [orderProperty] = useState<Types>(Types.RESERVED);
  const [order] = useState<Order>(1);
  const flatpickrStartRef = useRef(null);
  const flatpickrEndRef = useRef(null);
  const businessHours = useStore(
    (state) => state.organizationSettings.businessHours.BusinessHours,
  );
  const [reservationStartDate, setReservationStartDate] = useState(
    addDays(lower(businessHours), 1),
  );
  const [reservationEndDate, setReservationEndDate] = useState(
    addDays(upper(businessHours), 1),
  );

  const [{ data: buildingsTopology, fetching: loadingTopology }] =
    useOrganizationTopologyQuery({
      variables: { ...useRoomDeskFilter() },
    });
  const [
    { data: reservations, fetching: loadingReservations },
    reexecuteQuery,
  ] = useAllFloorReservationsQuery({
    variables: {
      Start: reservationStartDate,
      End: reservationEndDate,
      ...useRoomDeskFilter(),
    },
  });
  usePolling(loadingReservations, reexecuteQuery);

  const getReservedDesks = useCallback(
    (floorId: number) =>
      (reservations?.DeskReservations?.filter(
        (reservation) =>
          reservation.Desk.Sensor?.RoomSensors[0]?.Room.FloorId === floorId,
      ).length ?? 0) +
      (reservations?.Desks?.filter(
        (reservation) =>
          isSameDay(reservationStartDate, new Date()) &&
          deskIsUsed(
            reservation.Sensor?.UpdatedAt,
            reservation.Sensor?.Value ?? 0,
            reservation.Sensor?.IsPrivate ?? true,
          ) &&
          reservation.Sensor?.RoomSensors[0]?.Room.FloorId === floorId,
      ).length ?? 0),
    [reservationStartDate, reservations?.DeskReservations, reservations?.Desks],
  );

  const getUnavailableDesks = useCallback(
    (floorId: number) =>
      reservations?.Desks?.filter(
        (d) =>
          d.Sensor?.IsPrivate &&
          d.Sensor?.RoomSensors[0]?.Room.FloorId === floorId,
      ).length ?? 0,
    [reservations?.Desks],
  );

  const getReservedRooms = useCallback(
    (floorId: number) =>
      reservations?.RoomReservations?.filter(
        (reservation) => reservation.Room.FloorId === floorId,
      ).length ?? 0,
    [reservations?.RoomReservations],
  );

  const getUnavailableRooms = useCallback(
    (floorId: number) =>
      reservations?.Rooms?.filter((r) => r.IsPrivate && r.FloorId === floorId)
        .length ?? 0,
    [reservations?.Rooms],
  );

  const formattedData = useMemo(() => {
    if (reservations?.DeskReservations || reservations?.RoomReservations) {
      // Aggregate the reservationdata per building and floor.
      // Format the data for pie chart.
      return desksPerBuilding(buildingsTopology)
        .map((b) => ({
          id: b.id,
          name: b.name,
          floors: b.floors.map((floor) => ({
            id: floor.id,
            number: floor.number,
            total: [
              {
                id: Types.UNUSED,
                value:
                  floor.places -
                  getReservedDesks(floor.id) -
                  getUnavailableDesks(floor.id),
              },
              { id: Types.RESERVED, value: getReservedDesks(floor.id) },
              {
                id: Types.TOTAL,
                value: floor.places - getUnavailableDesks(floor.id),
              },
              {
                id: Types.OFFLINE,
                value: floor.offline,
              },
            ],
          })),
          roomFloors: b.floors.map((floor) => ({
            id: floor.id,
            number: floor.number,
            total: [
              {
                id: Types.UNUSED,
                value:
                  floor.placesMeeting -
                  getReservedRooms(floor.id) -
                  getUnavailableRooms(floor.id),
              },
              { id: Types.RESERVED, value: getReservedRooms(floor.id) },
              {
                id: Types.TOTAL,
                value: floor.placesMeeting - getUnavailableRooms(floor.id),
              },
              {
                id: Types.OFFLINE,
                value: floor.offlineMeeting,
              },
            ],
          })),
        }))
        .sort((a, b) => {
          if (order === Order.DOWN) {
            return findValue(a, orderProperty) - findValue(b, orderProperty);
          }
          return findValue(b, orderProperty) - findValue(a, orderProperty);
        });
    }
    return [];
  }, [
    buildingsTopology,
    getReservedDesks,
    getReservedRooms,
    getUnavailableDesks,
    getUnavailableRooms,
    order,
    orderProperty,
    reservations?.DeskReservations,
    reservations?.RoomReservations,
  ]);

  useEffect(() => {
    if (isAfter(reservationStartDate, reservationEndDate)) {
      const differenceDays = differenceInDays(
        startOfDay(reservationStartDate),
        startOfDay(reservationEndDate),
      );

      // Add the days to the end date in order to extract only the time difference
      const endDateNewDay = addDays(reservationEndDate, differenceDays);

      // New end date is now after the start date
      if (isAfter(endDateNewDay, reservationStartDate)) {
        setReservationEndDate(endDateNewDay);
        (flatpickrEndRef.current as any)?.flatpickr.setDate(endDateNewDay);
      } else {
        setReservationEndDate(addHours(reservationStartDate, 1));
        (flatpickrEndRef.current as any)?.flatpickr.setDate(
          addHours(reservationStartDate, 1),
        );
      }
    }
  }, [reservationEndDate, reservationStartDate]);

  useEffect(() => {
    if (businessHours) {
      setReservationStartDate(addDays(lower(businessHours), 1));
      (flatpickrStartRef.current as any)?.flatpickr.setDate(
        addDays(lower(businessHours), 1),
      );
      setReservationEndDate(addDays(upper(businessHours), 1));
      (flatpickrEndRef.current as any)?.flatpickr.setDate(
        addDays(upper(businessHours), 1),
      );
    }
  }, [businessHours]);

  return (
    <Card>
      <div className="space-y-2">
        <div className="flex flex-col lg:flex-row space-y-4 lg:space-x-2 lg:space-y-0 justify-between">
          <ReservationDate
            reservationStartDate={reservationStartDate}
            reservationEndDate={reservationEndDate}
            onStartChange={(selectedDate) => {
              if (selectedDate[0]) setReservationStartDate(selectedDate[0]);
            }}
            onEndChange={(selectedDate) => {
              if (selectedDate[0]) setReservationEndDate(selectedDate[0]);
            }}
            flatpickrStartRef={flatpickrStartRef}
            flatpickrEndRef={flatpickrEndRef}
          />
          <FavoriteReservation
            reservationEndDate={reservationEndDate}
            reservationStartDate={reservationStartDate}
          />
        </div>
        <Overview
          title="Reservations"
          data={formattedData}
          columns={[Types.UNUSED, Types.RESERVED, Types.TOTAL]}
          pieChartColors={[
            getColor('GREEN'),
            getColor('RED'),
            getColor('NEUTRAL200'),
          ]}
          noCard
          defaultSorting={Types.UNUSED}
          dataLoading={loadingReservations || loadingTopology}
          map={
            <ReservationMap
              className="h-96"
              reservationStartDate={reservationStartDate}
              reservationEndDate={reservationEndDate}
            />
          }
        />
      </div>
    </Card>
  );
}
