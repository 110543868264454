import Card from 'generic/components/Card';
import Tooltip from 'generic/components/Tooltip';
import { FormattedMessage, useIntl } from 'translations/Intl';

import type { Floor } from 'mda2-frontend/src/common/types';
import Button from 'mda2-frontend/src/generic/components/Form/Button';
import PrivateWrapper from 'mda2-frontend/src/generic/components/PrivateWrapper';
import NoContent from 'mda2-frontend/src/generic/components/layout/NoContent';
import { useFloorQuery, useFloorsQuery } from 'mda2-frontend/src/graphql/types';
import useStore from 'mda2-frontend/src/model/store';
import { HasuraPermissions } from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import useDecodedParams from 'mda2-frontend/src/utils/useDecodedParams';
import { useEffect, useMemo, useState } from 'react';
import { HiBars4, HiOutlinePlus } from 'react-icons/hi2';
import { NavLink } from 'react-router-dom';

import AddFloorModal from '../AddFloorModal';
import FloorRoomView from './components/FloorRoomView';

function FloorSkeleton() {
  return (
    <div className="w-full animate-pulse flex flex-col p-4 space-y-4 group-hover:bg-neutral-50 dark:group-hover:bg-neutral-700">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <div className="w-32 bg-neutral-200 h-6 rounded-md" />
          <div className="w-4 bg-neutral-200 h-4 rounded-md" />
          <div className="w-4 bg-neutral-200 h-4 rounded-md" />
        </div>
        <div className="w-4 bg-neutral-200 h-4 rounded-md" />
      </div>
      <div className="w-full bg-neutral-200 rounded-md map-height" />
      <div className="flex w-full space-x-2">
        <div className="w-32 bg-neutral-200 h-8 rounded-md" />
        <div className="w-32 bg-neutral-200 h-8 rounded-md" />
      </div>
    </div>
  );
}

interface FloorListProps {
  isAdding: boolean;
  setIsAdding: (isAdding: boolean) => void;
  setFloor: (floorNumber: number) => void;
}

export default function FloorList({
  isAdding,
  setIsAdding,
  setFloor,
}: FloorListProps) {
  const intl = useIntl();
  const { buildingName, floorNumber: floorParam } = useDecodedParams();
  const floor = useStore((state) => state.userSettings.floor);
  const building = useStore((state) => state.userSettings.building);
  const [floorNumber, setFloorNumber] = useState<number | undefined>(
    floorParam ? Number.parseInt(floorParam, 10) : undefined,
  );
  const [singleFloor, setSingleFloor] = useState<Floor>();
  const context = useMemo(() => ({ additionalTypenames: ['Floors'] }), []);

  const [{ data: floorData, fetching: loadingFloor }] = useFloorsQuery({
    variables: {
      BuildingName: buildingName,
    },
    // Adding a typename for correct caching when there is an empty list
    context,
  });

  const [{ data: floorInfo, fetching: loadingInfo }] = useFloorQuery({
    variables: {
      BuildingName: buildingName,
      FloorNumber: floorNumber,
    },
    pause: typeof floorNumber !== 'number',
    context,
  });

  const floors = useMemo(
    () => floorData?.Floors.slice().sort((a, b) => b.Number - a.Number),
    [floorData],
  );

  // If no floor is selected yet then choose a default one
  useEffect(() => {
    if (
      floors &&
      floors.length > 0 &&
      !(
        typeof floorNumber === 'number' &&
        floors.map((f) => f.Number).includes(floorNumber)
      )
    ) {
      setFloorNumber(
        building?.Name === buildingName
          ? floor?.Number ?? 0
          : Math.min(...floors.map((f) => f.Number)),
      );
    }
  }, [
    building,
    building?.Name,
    buildingName,
    floor?.Number,
    floorNumber,
    floors,
  ]);

  useEffect(() => {
    if (typeof floorNumber === 'number' && floorInfo?.Floors) {
      setSingleFloor(floorInfo.Floors.find((f) => f.Number === floorNumber));
    }
  }, [floorInfo, floorNumber]);

  useEffect(() => {
    if (typeof floorNumber === 'number') {
      setFloor(floorNumber);
    }
  }, [floorNumber, setFloor]);

  return (
    <div className="flex space-x-4 max-h-screen transition-all w-full">
      {floors && floors.length > 0 ? (
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 w-full">
          <div className="flex md:flex-col w-full md:w-fit md:h-full space-x-2 md:space-y-4 md:space-x-0">
            <PrivateWrapper roleRequired={HasuraPermissions.WRITE_FLOOR}>
              <Tooltip
                content={
                  <Button
                    title={intl.formatMessage({
                      id: 'Add floor',
                    })}
                    data-test-id="add-new-floor"
                    onClick={() => {
                      setIsAdding(true);
                    }}
                    className="cursor-pointer flex-shrink-0 hover:bg-primary-400 text-primary-500 size-10 hover:text-white bg-primary-200 rounded-full items-center flex justify-center"
                  >
                    <div className="relative">
                      <HiBars4 className="size-5 text-neutral-500" />
                      <HiOutlinePlus className="absolute right-0 top-3 text-neutral-500 shadow bg-white rounded-xl size-2" />
                    </div>
                  </Button>
                }
              >
                <FormattedMessage id="Add floor" />
              </Tooltip>
            </PrivateWrapper>
            {floors.map((fl) => {
              const { Number, Name } = fl;
              return (
                <Tooltip
                  key={Number}
                  content={
                    <NavLink
                      data-test-id={`floor-${Number}`}
                      to={`/admin/${encodeURIComponent(
                        buildingName ?? '',
                      )}/${Number}`}
                      onClick={() => setFloorNumber(Number)}
                      className={`flex-shrink-0 hover:bg-primary-400 text-primary-500 size-10 hover:text-white bg-primary-200 rounded-full items-center flex justify-center ${
                        singleFloor?.Number === Number
                          ? 'bg-primary-400 hover:bg-primary-400 cursor-default text-white'
                          : 'cursor-pointer'
                      }`}
                    >
                      {Number}
                    </NavLink>
                  }
                >
                  <div className="px-4">{Name ?? Number}</div>
                </Tooltip>
              );
            })}
          </div>

          <div className="w-full">
            <Card className="relative h-full overflow-hidden" fullScreenButton>
              {singleFloor ? (
                <FloorRoomView floor={singleFloor} />
              ) : (
                <FloorSkeleton />
              )}
            </Card>
          </div>
        </div>
      ) : (
        <NoContent
          loading={loadingFloor || loadingInfo}
          clickAction={() => setIsAdding(true)}
          type="floor"
        />
      )}

      <AddFloorModal
        isAdding={isAdding}
        setIsAdding={setIsAdding}
        isEditAction={false}
      />
    </div>
  );
}
