import Title from 'mda2-frontend/src/generic/components/Title';

import AssetList from './components/AssetList';

export default function FindMyAssetView(): JSX.Element {
  return (
    <div className="flex flex-col">
      <Title value="Asset List" />
      <AssetList />
    </div>
  );
}
