import StyledButton from 'generic/components/Form/Button/StyledButton';
import Table, { ACTION_ID } from 'generic/components/Table/Table';
import Tooltip from 'generic/components/Tooltip';
import { FormattedMessage, useIntl } from 'translations/Intl';
import { formattedDistance } from 'utils/date';

import type { ColumnDef } from '@tanstack/react-table';
import PrivateWrapper from 'mda2-frontend/src/generic/components/PrivateWrapper';
import Loader from 'mda2-frontend/src/generic/components/layout/BarLoader';
import { useSensorPoliciesQuery } from 'mda2-frontend/src/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import { useMemo, useState } from 'react';
import { HiOutlinePlus } from 'react-icons/hi2';

import AddSensorPolicyModal from './components/AddSensorPolicyModal';
import {
  ActionCell,
  ConfigurationCell,
  DefaultCell,
  NameCell,
} from './components/Cells';
import RemoveSensorPolicyModal, {
  type SensorPolicy,
} from './components/RemoveSensorPolicyModal/RemoveSensorPolicyModal';

export default function SensorPolicyView() {
  const [openAddSensorPolicyModal, setOpenAddSensorPolicyModal] =
    useState(false);
  const [openDeleteSensorPolicyModal, setOpenDeleteSensorPolicyModal] =
    useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState<
    SensorPolicy | undefined
  >(undefined);

  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();

  const [{ data: policies, fetching: loadingPolicies }] =
    useSensorPoliciesQuery({
      context: useMemo(
        () => hasuraHeader(HasuraPermissions.VIEW_ADMIN, ['SensorPolicies']),
        [hasuraHeader],
      ),
    });

  const defaultColumns: ColumnDef<SensorPolicy>[] = [
    {
      enableGrouping: false,
      id: 'defaultSelection',
      cell: DefaultCell,
    },
    {
      accessorKey: 'Name',
      id: 'name',
      header: intl.formatMessage({ id: 'Name' }),
      cell: NameCell,
    },
    {
      accessorKey: 'Configuration',
      id: 'configuration',
      header: intl.formatMessage({ id: 'Configuration' }),
      cell: ConfigurationCell,
      enableColumnFilter: false,
    },
    {
      id: 'beaconCount',
      header: intl.formatMessage({ id: 'Number of beacons' }),
      cell: ({ row }) =>
        row.original.MqttBeacons_aggregate.aggregate?.count ?? 0,
    },
    {
      header: intl.formatMessage({ id: 'Created at' }),
      id: 'createdAt',
      accessorFn: (row) => new Date(row.CreatedAt),
      cell: ({ row }) => formattedDistance(new Date(row.original.CreatedAt)),
      enableColumnFilter: false,
    },
    {
      header: intl.formatMessage({ id: 'Last updated' }),
      id: 'updatedAt',
      accessorFn: (row) => new Date(row.UpdatedAt),
      cell: ({ row }) => formattedDistance(new Date(row.original.UpdatedAt)),
      enableColumnFilter: false,
    },
    {
      enableGrouping: false,
      id: ACTION_ID,
      cell: ({ row }) => (
        <ActionCell
          row={row}
          setSelectedPolicy={setSelectedPolicy}
          setOpenAddSensorPolicyModal={setOpenAddSensorPolicyModal}
          setOpenDeleteSensorPolicyModal={setOpenDeleteSensorPolicyModal}
        />
      ),
    },
  ];

  const data = useMemo(() => {
    if (policies?.SensorPolicies) {
      const sorted = [...policies.SensorPolicies].sort((a, b) =>
        a.Name.localeCompare(b.Name),
      );
      return sorted;
    }
    return [];
  }, [policies]);

  return (
    <>
      <Loader loading={loadingPolicies} />
      <Table<SensorPolicy>
        id="groups"
        columns={defaultColumns}
        data={data}
        loading={loadingPolicies}
        renderAdditionalAction={
          <PrivateWrapper roleRequired={HasuraPermissions.WRITE_ORGANIZATION}>
            <Tooltip
              content={
                <StyledButton onClick={() => setOpenAddSensorPolicyModal(true)}>
                  <HiOutlinePlus
                    data-test-id="add-policy-button"
                    className="size-5"
                  />
                </StyledButton>
              }
            >
              <FormattedMessage id="Add sensor policy" />
            </Tooltip>
          </PrivateWrapper>
        }
      />

      <RemoveSensorPolicyModal
        policyToRemove={selectedPolicy}
        open={openDeleteSensorPolicyModal}
        setOpen={setOpenDeleteSensorPolicyModal}
      />

      <AddSensorPolicyModal
        selectedPolicy={selectedPolicy}
        setSelectedPolicy={setSelectedPolicy}
        open={openAddSensorPolicyModal}
        setOpen={setOpenAddSensorPolicyModal}
      />
    </>
  );
}
