import type { CellContext, Row } from '@tanstack/react-table';
import { DeviceTypes } from 'common/types';
import LuminaireIcon from 'generic/components/LuminaireIcon';
import Pill from 'generic/components/Pill';
import PrivateWrapper from 'generic/components/PrivateWrapper';
import Tooltip from 'generic/components/Tooltip';
import { MqttSystems } from 'graphql/types';
import { forwardRef, useEffect, useState } from 'react';
import {
  HiOutlineArrowSmallRight,
  HiOutlineCheckBadge,
  HiOutlineTrash,
  HiOutlineXCircle,
} from 'react-icons/hi2';
import { FormattedMessage, type IntlMessageKeys } from 'translations/Intl';
import { HasuraPermissions } from 'utils/graphql/useHasuraHeaders';
import useMqttMessage from 'utils/mqtt/useMqttMessage';
import type { LiveBeaconsQueryData } from '../RemoveBeaconModal/RemoveBeaconModal';

const ProgressBar = forwardRef<HTMLDivElement, { progress: number }>(
  (props, forwardedRef) => {
    const { progress, ...rest } = props;

    return (
      <div
        ref={forwardedRef}
        className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700"
        {...rest}
      >
        <div
          className="bg-primary-500 h-1.5 rounded-full"
          style={{ width: `${progress}%` }}
        />
      </div>
    );
  },
);

export const getStatusForBeacon = (
  offline: boolean,
  bluerangeStatus?: string | null,
  deviceType?: string | null,
): IntlMessageKeys => {
  if (bluerangeStatus && deviceType === 'Gateway') {
    return bluerangeStatus
      .replace('COMPLIANT', 'Online')
      .replace('INACTIVE', 'Offline')
      .replace('DELETED', 'Offline')
      .replace('-', 'Unknown') as IntlMessageKeys;
  }
  return offline ? 'Offline' : 'Online';
};

export function AutoUpdateCell({ row }: { row: Row<LiveBeaconsQueryData> }) {
  if (row.original.MqttBeaconSource.Name !== MqttSystems.Mda3) {
    return '-';
  }

  return row.original.IsExcludedFromUpdates ? (
    <HiOutlineXCircle className="size-5 text-red-500" />
  ) : (
    <HiOutlineCheckBadge className="size-5 text-green-500" />
  );
}

export const getIncompliantFirmwares = (data: LiveBeaconsQueryData) => {
  return (
    data.MqttBeaconFirmwares?.filter((f) =>
      data.FirmwarePackage?.FirmwarePackageFirmwares?.find(
        (f2) =>
          f2.Firmware.ModuleId === f.Firmware.ModuleId &&
          f2.Firmware.Version !== f.Firmware.Version,
      ),
    ) ?? []
  );
};

export function FWPackageCell({ row }: { row: Row<LiveBeaconsQueryData> }) {
  const firmwarePackage = row.original.FirmwarePackage?.Version;
  const incompliantFirmwares = getIncompliantFirmwares(row.original);
  const compliantPackage = incompliantFirmwares.length === 0;

  return firmwarePackage ? (
    <div className="flex flex-col">
      <div className="flex space-x-1 items-center">
        <div>
          {compliantPackage ? (
            <HiOutlineCheckBadge className="size-5 text-green-500" />
          ) : (
            <HiOutlineXCircle className="size-5 text-red-500" />
          )}
        </div>
        <div>{firmwarePackage}</div>
      </div>
      {!compliantPackage && (
        <div className="text-xxs">
          <FormattedMessage id="Non-compliant" />:
          {incompliantFirmwares?.map((i) => (
            <div key={i.Firmware.Id} className="flex space-x-1 items-center">
              <div>{i.Firmware.Module.Name}:</div>
              <div className="text-red-500">{i.Firmware.Version}</div>
              <div>
                <HiOutlineArrowSmallRight className="size-2 block" />
              </div>
              <div className="text-green-500">
                {
                  row.original.FirmwarePackage?.FirmwarePackageFirmwares.find(
                    (f) => f.Firmware.ModuleId === i.Firmware.ModuleId,
                  )?.Firmware.Version
                }
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  ) : (
    '-'
  );
}

export function DeleteCell({
  row,
  setBeaconsToRemove,
}: {
  row: Row<LiveBeaconsQueryData>;
  setBeaconsToRemove: (
    beacons: Row<LiveBeaconsQueryData>[] | undefined,
  ) => void;
}) {
  return (
    <PrivateWrapper roleRequired={HasuraPermissions.DELETE_MQTTBEACON}>
      <Tooltip
        content={
          <p>
            <HiOutlineTrash
              onClick={() => {
                setBeaconsToRemove([row]);
              }}
              className="size-5 cursor-pointer text-primary-500 hover:text-primary-700"
              data-test-id="delete-beacon"
            />
          </p>
        }
      >
        <FormattedMessage id="Delete" />
      </Tooltip>
    </PrivateWrapper>
  );
}

export function StatusCell({
  cell,
}: CellContext<LiveBeaconsQueryData, unknown>) {
  const [progressBarVisible, setProgressBarVisible] = useState(false);
  const { message: download } = useMqttMessage({
    mqttTopic: `${cell.row.original.UniqueIdentifier}/info/download`,
    skip:
      cell.row.original.MqttBeaconSource.Name !== MqttSystems.Mda3 ||
      cell.row.original.IsOffline,
  });
  const { IsOffline, BluerangeInfo, DeviceType } = cell.row.original;
  const status = getStatusForBeacon(
    !!IsOffline,
    BluerangeInfo?.Status ?? '-',
    DeviceType?.Name,
  );

  useEffect(() => {
    if (download) {
      setProgressBarVisible(true);

      // Hide the progress bar after download finishes
      if (Number.parseInt(download.slice(0, -1) ?? '0', 10) === 100) {
        setTimeout(() => setProgressBarVisible(false), 10_000);
      }
    } else {
      setProgressBarVisible(false);
    }
  }, [download]);

  return (
    <div className="flex flex-col">
      <div className="flex items-center">
        <div
          className={`size-2.5 rounded-full ${
            status === 'Online' ? 'bg-green-500' : 'bg-red-500'
          } me-2`}
        />
        <FormattedMessage id={status} />
      </div>
      {download && progressBarVisible && (
        <Tooltip
          content={
            <ProgressBar
              progress={Number.parseInt(download.slice(0, -1), 10)}
            />
          }
        >
          {download}
        </Tooltip>
      )}
    </div>
  );
}

export function SensorTypesCell({
  row,
}: CellContext<LiveBeaconsQueryData, unknown>) {
  const sensors = row.original.Sensors;
  const uniqueSensors = [
    ...new Set(sensors.map((s) => s.SensorType.Name)),
  ].sort((a, b) => a.localeCompare(b));

  return (
    <div className="grid grid-cols-2 gap-1">
      {uniqueSensors.length
        ? uniqueSensors.map((sensorType) => (
            <div
              key={sensorType}
              className="col-span-1 hover:w-fit transition-all"
            >
              <Pill className="hover:shadow z-0 hover:z-40 relative">
                <p className="text-ellipsis overflow-hidden hover:overflow-visible">
                  <FormattedMessage id={sensorType as IntlMessageKeys} /> (
                  {
                    sensors.filter((s) => s.SensorType.Name === sensorType)
                      .length
                  }
                  )
                </p>
              </Pill>
            </div>
          ))
        : '-'}
    </div>
  );
}

export function DeviceTypeCell(
  props: CellContext<LiveBeaconsQueryData, unknown>,
) {
  const { row } = props;
  return (
    <Tooltip
      content={
        <p className="dark:bg-neutral-600 rounded-full p-1">
          <LuminaireIcon
            device={{
              deviceType:
                (row.original.DeviceType?.Name as DeviceTypes | undefined) ??
                DeviceTypes.NODE,
              desksInUse: row.original.NumberOfAvailableDesks ?? 0,
            }}
            size={50}
          />
        </p>
      }
    >
      <p>
        {row.original.DeviceType?.Name ?? <FormattedMessage id="Unknown" />}
      </p>
    </Tooltip>
  );
}
