import type { HoveredFeature } from 'mda2-frontend/src/common/types';
import type Layer from 'mda2-frontend/src/generic/layers/Layer';
import type { MapEvent } from 'ol';
import type OLMap from 'ol/Map';
import type { AtPixelOptions } from 'ol/Map';
import type MapBrowserEvent from 'ol/MapBrowserEvent';
import { unByKey } from 'ol/Observable';
import View, { type FitOptions, type ViewOptions } from 'ol/View';
import type { EventsKey } from 'ol/events';
import { touchOnly } from 'ol/events/condition';
import { equals } from 'ol/extent';
import { DoubleClickZoom, MouseWheelZoom, PinchZoom } from 'ol/interaction';
import { defaults } from 'ol/interaction/defaults';
import VectorLayer from 'ol/layer/Vector';
import { PureComponent } from 'react';

interface BasicMapProps<T> {
  animationOptions?: {
    center?: number[];
    resolution?: number;
    zoom?: number;
  };
  disableZoom?: boolean;
  center?: number[];
  extent?: number[];
  fitOptions?: FitOptions;
  layers: Layer[];
  map: OLMap;
  onFeaturesClick?: (features: T[], evt: MapBrowserEvent<PointerEvent>) => void;
  featuresClickOptions?: AtPixelOptions;
  onFeaturesHover?: (
    features: HoveredFeature<T>[],
    evt: MapBrowserEvent<PointerEvent>,
  ) => void;
  onMapMoved?: (event: MapEvent) => void;
  resolution?: number;
  viewOptions?: ViewOptions;
  zoom: number;
}

type State = { node: HTMLDivElement | null };

/**
 * The BasicMap component renders an [ol/map](https://openlayers.org/en/latest/apidoc/module-ol_Map-Map.html).
 *
 * The map's view is created with the following parameters for the view:
 *  - projection: 'EPSG:3857'
 *  - zoom: 0
 *  - minZoom: 0
 *  - maxZoom: 22
 *
 * These options can be overridden using the viewOptions property.
 */
class BasicMap<T> extends PureComponent<BasicMapProps<T>, State> {
  layers: Layer[];

  map: OLMap;

  moveEndRef: EventsKey | null;

  pointerMoveRef: EventsKey | null;

  singleClickRef: EventsKey | null;

  constructor(props: BasicMapProps<T>) {
    super(props);

    const { map } = this.props;

    this.map = map;

    this.state = {
      node: null,
    };

    this.layers = [];
    this.moveEndRef = null;
    this.singleClickRef = null;
    this.pointerMoveRef = null;
    this.setNode = this.setNode.bind(this);
  }

  componentDidMount(): void {
    const {
      layers,
      extent,
      viewOptions,
      center,
      zoom,
      resolution,
      disableZoom,
    } = this.props;
    const { node } = this.state;
    this.map.setTarget(node ?? undefined);

    if (disableZoom) {
      const defaultInteractions = [
        ...defaults()
          .getArray()
          .filter(
            (i) =>
              ![
                new MouseWheelZoom().constructor,
                new DoubleClickZoom().constructor,
                new PinchZoom().constructor,
              ].includes(i.constructor),
          ),
      ];
      for (const interaction of this.map.getInteractions().getArray()) {
        if (
          !defaultInteractions
            .map((i) => i.constructor)
            .includes(interaction.constructor)
        ) {
          this.map.removeInteraction(interaction);
        }
      }
    }

    // We set the view here otherwise the map is not correctly zoomed.
    this.map.setView(new View({ ...viewOptions, center, zoom, resolution }));

    // Since ol 6.1.0 touch-action is set to auto and creates a bad navigation experience on mobile,
    // so we have to force it to none for mobile.
    // https://github.com/openlayers/openlayers/pull/10187/files
    const viewPort = this.map.getViewport();
    viewPort.style.touchAction = 'none';
    viewPort.setAttribute('touch-action', 'none');

    // Fit only works if the map has a size.
    if (this.map.getSize() && extent) {
      this.map.getView().fit(extent);
    }

    this.setLayers(layers);
    this.listenMoveEnd();
    this.listenSingleClick();
    this.listenPointerMove();
  }

  componentDidUpdate(prevProps: BasicMapProps<T>, prevState: State): void {
    const {
      animationOptions,
      center,
      extent,
      fitOptions,
      layers,
      resolution,
      viewOptions,
      zoom,
      onMapMoved,
      onFeaturesClick,
      onFeaturesHover,
    } = this.props;
    const { node } = this.state;

    if (prevState.node !== node) {
      this.map.setTarget(node ?? undefined);

      // When the node is set we reinitialize the extent with the extent property.
      if (!prevState.node && node && extent) {
        this.map.getView().fit(extent);
      }
    }

    if (prevProps.layers !== layers) {
      this.setLayers(layers);
    }

    // Creates a new view if necessary before updating the others prop.
    if (
      viewOptions &&
      JSON.stringify(viewOptions) !== JSON.stringify(prevProps.viewOptions)
    ) {
      // Re-create a view, ol doesn't provide any method to setExtent of view.
      this.map.setView(
        new View({
          ...viewOptions,
          center,
          resolution,
          zoom,
        }),
      );
    }

    const view = this.map.getView();

    if (animationOptions && prevProps.animationOptions !== animationOptions) {
      view.animate(animationOptions);
    }

    if (prevProps.center !== center) {
      view.setCenter(center);
    }

    if (zoom !== prevProps.zoom) {
      view.setZoom(zoom);
    }

    if (resolution !== prevProps.resolution) {
      view.setResolution(resolution);
    }

    if (
      extent &&
      (!equals(extent, prevProps.extent || []) || !prevProps.extent)
    ) {
      view.fit(extent, fitOptions);
    }

    if (onMapMoved !== prevProps.onMapMoved) {
      this.listenMoveEnd();
    }

    if (onFeaturesClick !== prevProps.onFeaturesClick) {
      this.listenSingleClick();
    }

    if (onFeaturesHover !== prevProps.onFeaturesHover) {
      this.listenPointerMove();
    }
  }

  componentWillUnmount(): void {
    const { layers } = this.props;
    if (this.moveEndRef) {
      unByKey(this.moveEndRef);
    }
    if (this.singleClickRef) {
      unByKey(this.singleClickRef);
    }
    if (this.pointerMoveRef) {
      unByKey(this.pointerMoveRef);
    }
    // Terminate all layers on unmount BasicMap
    for (const layer of layers) {
      this.terminateLayer(layer);
    }
  }

  setNode(node: HTMLDivElement): void {
    this.setState({ node });
  }

  setLayers(layers: Layer[] = []): void {
    const layersToRemove = this.layers.filter(
      (layer: Layer) => !layers.includes(layer),
    );
    for (const layer of layersToRemove) {
      this.terminateLayer(layer);
    }

    const layersToInit = layers.filter((layer) => !this.layers.includes(layer));
    for (const layer of layersToInit) {
      this.initLayer(layer);
    }

    this.layers = layers;
  }

  initLayer(layer: Layer): void {
    layer.init(this.map);
    if (!this.map.getLayers().getArray().includes(layer.olLayer)) {
      this.map.addLayer(layer.olLayer);
    }
    const layers = layer.children || [];
    for (const l of layers) {
      this.initLayer(l);
    }
  }

  terminateLayer(layer: Layer): void {
    if (this.map.getLayers().getArray().includes(layer.olLayer)) {
      this.map.removeLayer(layer.olLayer);
    }
    layer.terminate();
    const layers = layer.children || [];
    for (const l of layers) {
      this.terminateLayer(l);
    }
  }

  listenMoveEnd(): void {
    const { onMapMoved } = this.props;

    if (this.moveEndRef) {
      unByKey(this.moveEndRef);
    }

    if (!onMapMoved) {
      return;
    }

    this.moveEndRef = this.map.on('moveend', (evt) => onMapMoved(evt));
  }

  listenSingleClick(): void {
    const { onFeaturesClick, featuresClickOptions } = this.props;
    if (this.singleClickRef) {
      unByKey(this.singleClickRef);
    }

    if (!onFeaturesClick) {
      return;
    }

    this.singleClickRef = this.map.on('singleclick', (evt) => {
      const features = evt.map.getFeaturesAtPixel(
        evt.pixel,
        featuresClickOptions,
      );
      onFeaturesClick((features as T[]) || [], evt);
    });
  }

  listenPointerMove(): void {
    const { onFeaturesHover } = this.props;
    if (this.pointerMoveRef) {
      unByKey(this.pointerMoveRef);
    }

    if (!onFeaturesHover) {
      return;
    }

    this.pointerMoveRef = this.map.on(
      'pointermove',
      (evt: MapBrowserEvent<PointerEvent>) => {
        if (touchOnly(evt)) {
          // Don't trigger hover when not using mouse.
          return;
        }
        const features = evt.map.getFeaturesAtPixel(evt.pixel);
        const hoveredFeatures = features
          .map((feature) => {
            const layer = this.layers.find(
              (l) =>
                l.olLayer instanceof VectorLayer &&
                l.olLayer.getSource().getFeatures().includes(feature),
            );
            if (layer) {
              return {
                feature,
                layer,
              };
            }
            return null;
          })
          .filter((f): f is NonNullable<typeof f> => !!f);

        onFeaturesHover(hoveredFeatures as HoveredFeature<T>[], evt);
      },
    );
  }

  render(): JSX.Element {
    return (
      <div
        className="h-full border-2 border-transparent focus:border-neutral-200 dark:focus:border-neutral-700 focus:rounded"
        ref={this.setNode}
        role="presentation"
        aria-label="map"
      />
    );
  }
}

export default BasicMap;
