import type { SensorAverageQuery } from 'graphql/types';
import type { Colors } from 'mda2-frontend/src/common/types';
import getClimateStatus, {
  ClimateStatus,
} from 'mda2-frontend/src/utils/getClimateStatus';
import getColor from 'mda2-frontend/src/utils/getColor';
import { lower, upper } from 'mda2-frontend/src/utils/numberrange';
import type Feature from 'ol/Feature';
import type OLMap from 'ol/Map';
import GeoJSON, { type GeoJSONFeature } from 'ol/format/GeoJSON';
import type OLGeometry from 'ol/geom/Geometry';
import type Point from 'ol/geom/Point';
import OLVectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import type { LayerOptions } from './Layer';
import type TypedFeature from './TypedFeature';
import VectorLayer from './VectorLayer';

export type ClimateBeaconFeatureType = TypedFeature<
  NonNullable<SensorAverageQuery['MqttBeacons']>[number],
  OLGeometry
>;

const formatClimateLimits = (
  sv: SensorAverageQuery['MqttBeacons'][number]['Sensors'][number],
) => {
  return {
    good: {
      start:
        lower(sv.SensorType.ClimateSensorLimits[0]?.GoodValue!) ?? undefined,
      end: upper(sv.SensorType.ClimateSensorLimits[0]?.GoodValue!) ?? undefined,
    },
    acceptable: {
      start:
        lower(sv.SensorType.ClimateSensorLimits[0]?.AcceptableValue!) ??
        undefined,
      end:
        upper(sv.SensorType.ClimateSensorLimits[0]?.AcceptableValue!) ??
        undefined,
    },
    poor: {
      start:
        lower(sv.SensorType.ClimateSensorLimits[0]?.PoorValue!) ?? undefined,
      end: upper(sv.SensorType.ClimateSensorLimits[0]?.PoorValue!) ?? undefined,
    },
  };
};

const getSensibleRangeColor = (
  sensors: SensorAverageQuery['MqttBeacons'][number]['Sensors'],
): keyof typeof Colors => {
  // All values are in a good range
  if (
    sensors.every(
      (sv) =>
        getClimateStatus(sv.Value ?? 0, formatClimateLimits(sv)) ===
        ClimateStatus.GOOD,
    )
  ) {
    return 'GREEN';
  }

  if (
    sensors.some(
      (sv) =>
        getClimateStatus(sv.Value ?? 0, formatClimateLimits(sv)) ===
        ClimateStatus.POOR,
    )
  ) {
    return 'RED';
  }

  return 'YELLOW';
};

const styleFunction = (
  feature: ClimateBeaconFeatureType,
  hoveredFeature: ClimateBeaconFeatureType | undefined,
  isMobile: boolean,
) => {
  const isHovered = feature.getGeometry() === hoveredFeature?.getGeometry();
  const sensors = feature.getProperties().Sensors;
  const offline = feature
    .getProperties()
    .Sensors.every((s) => s.MqttBeacon.IsOffline);

  const getRadius = () => {
    if (isMobile && isHovered) return 12;
    if (isHovered) return 6;
    return 5;
  };

  return [
    new Style({
      image: new Circle({
        radius: getRadius(),
        fill: new Fill({
          color: offline
            ? getColor('NEUTRAL900', '.4')
            : getColor(getSensibleRangeColor(sensors), '.9'),
        }),
        stroke: new Stroke({
          color: offline
            ? getColor('NEUTRAL900', '.4')
            : getColor(getSensibleRangeColor(sensors), '1'),
          width: isHovered ? 3 : 2,
        }),
      }),
    }),
  ];
};

interface ClimateLayerOptions extends LayerOptions {
  olLayer: OLVectorLayer<VectorSource<Feature<Point>>>;
}

class ClimateBeaconLayer extends VectorLayer<Point> {
  features?: SensorAverageQuery['MqttBeacons'];

  hoveredFeature?: ClimateBeaconFeatureType;

  isMobile: boolean;

  init(map: OLMap): void {
    super.init(map);
  }

  constructor() {
    super({
      ...{
        olLayer: new OLVectorLayer({
          source: new VectorSource(),
          style: (feature) =>
            styleFunction(
              feature as ClimateBeaconFeatureType,
              this.hoveredFeature,
              this.isMobile,
            ),
        }),
      },
    } as ClimateLayerOptions);

    this.hoveredFeature = undefined;
    this.isMobile = false;
  }

  setFeatures(feats: SensorAverageQuery['MqttBeacons']): void {
    this.features = feats;
    const source = this.olLayer.getSource() as VectorSource<Feature<Point>>;
    const newFeatures = new GeoJSON().readFeatures(
      ClimateBeaconLayer.toGeoJson(this.features),
    );
    source.clear();
    source.addFeatures(newFeatures as Feature<Point>[]);
  }

  static toGeoJson(feats?: SensorAverageQuery['MqttBeacons']): GeoJSONFeature {
    const features = feats?.map((feat) => {
      const { Geometry } = feat;
      return {
        type: 'Feature',
        geometry: Geometry,
        properties: {
          ...feat,
        },
      };
    });
    return {
      type: 'FeatureCollection',
      features,
    };
  }
}

export default ClimateBeaconLayer;
