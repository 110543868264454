import { useParams } from 'react-router-dom';

export default function useDecodedParams() {
  const params = useParams();
  const newParams = { ...params };
  for (const key of Object.keys(newParams)) {
    const paramValue = newParams[key];
    if (key in newParams && paramValue) {
      newParams[key] = decodeURIComponent(paramValue);
    }
  }
  return newParams;
}
