import PermissionCheckbox from 'generic/components/PermissionCheckbox';
import { FormattedMessage, useIntl } from 'translations/Intl';
import { uuidv4 } from 'utils/uuid';

import { Action } from 'mda2-frontend/src/common/types';
import Input from 'mda2-frontend/src/generic/components/Form/Input';
import Modal from 'mda2-frontend/src/generic/components/Modal';
import ModalFooter from 'mda2-frontend/src/generic/components/ModalFooter';
import Transition from 'mda2-frontend/src/generic/components/Transition';
import {
  type ClientRoleInfos,
  MqttOrganizations_Constraint,
  MqttOrganizations_Update_Column,
  type OrganizationRolesAndFirmwarePackagesQuery,
  useInsertOrganizationMutation,
  useOrganizationRolesAndFirmwarePackagesQuery,
  useUpdateOrganizationLicenseMutation,
} from 'mda2-frontend/src/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import useToast from 'mda2-frontend/src/utils/graphql/useToast';
import { useEffect, useMemo, useState } from 'react';
import { FaCircleNotch } from 'react-icons/fa';
import {
  HiOutlineBuildingOffice,
  HiOutlineChatBubbleOvalLeft,
  HiOutlineChatBubbleOvalLeftEllipsis,
  HiOutlineKey,
} from 'react-icons/hi2';

import { addYears } from 'date-fns';
import Checkbox from 'generic/components/Form/Checkbox';
import { DatePickerInput } from 'generic/components/Form/DatePicker';
import Select from 'generic/components/Form/Select';
import PrivateWrapper from 'generic/components/PrivateWrapper';
import Subtitle from 'generic/components/Subtitle';
import useStore from 'model/store';
import type { OperationResult } from 'urql';
import {
  endOfDayUTC,
  lower,
  serializeRange,
  startOfDayUTC,
  upper,
} from 'utils/date';
import type { Organization } from '../RemoveOrganizationModal/RemoveOrganizationModal';

interface AddOrganizationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedOrganization?: Organization;
  setSelectedOrganization: (selectedOrganization?: Organization) => void;
  isAdding: boolean;
  setIsAdding: (value: boolean) => void;
}

type UserRole = ClientRoleInfos;

export default function AddOrganizationModal({
  open,
  setOpen,
  selectedOrganization,
  setSelectedOrganization,
  isAdding,
  setIsAdding,
}: AddOrganizationModalProps) {
  const intl = useIntl();
  const userRoles = useStore((state) => state.user)?.roles;
  const [selectedFirmwarePackage, setSelectedFirmwarePackage] =
    useState<
      OrganizationRolesAndFirmwarePackagesQuery['FirmwarePackages'][number]
    >();
  const [checkedRoles, setCheckedRoles] = useState<UserRole[]>([]);
  const [deletedRoles, setDeletedRoles] = useState<UserRole[]>([]);
  const [availableLicenses, setAvailableLicenses] = useState(10);
  const [licenseDurationStart, setLicenseDurationStart] = useState(new Date());
  const [licenseDurationEnd, setLicenseDurationEnd] = useState(
    addYears(new Date(), 1),
  );
  const [salesContactEmail, setSalesContactEmail] = useState('');
  const [customerContactEmail, setCustomerContactEmail] = useState('');
  const [organizationName, setOrganizationName] = useState<string>('');
  const [addSampleData, setAddSampleData] = useState(false);
  const [organizationUuid, setOrganizationUuid] = useState(uuidv4());
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();
  const [{ data: rolesAndFirmwarePackages, fetching: loading }] =
    useOrganizationRolesAndFirmwarePackagesQuery({
      variables: { Organization: selectedOrganization?.Name ?? '' },
      pause: isAdding || !userRoles?.includes(HasuraPermissions.READ_ALL),
      context: useMemo(
        () => hasuraHeader(HasuraPermissions.READ_ALL),
        [hasuraHeader],
      ),
    });

  const [{ fetching: loadingAddOrganization }, addOrganization] =
    useInsertOrganizationMutation();
  const [
    { fetching: loadingUpdateLicenseOrganization },
    updateOrganizationLicense,
  ] = useUpdateOrganizationLicenseMutation();

  useEffect(() => {
    setCheckedRoles(
      rolesAndFirmwarePackages?.GetRoleMappingForOrganization.enabledRoles.map(
        (r) => ({
          id: r.id ?? '0',
          name: r.name ?? '',
        }),
      ) ?? [],
    );
  }, [rolesAndFirmwarePackages?.GetRoleMappingForOrganization]);

  useEffect(() => {
    if (isAdding) {
      setCheckedRoles([]);
    }
  }, [isAdding]);

  useEffect(() => {
    if (selectedOrganization?.Id) {
      setOrganizationName(selectedOrganization.Name);
      setOrganizationUuid(selectedOrganization.Id);
      setAvailableLicenses(selectedOrganization.AvailableLicenses);
      setLicenseDurationStart(lower(selectedOrganization.LicenseDuration));
      setLicenseDurationEnd(upper(selectedOrganization.LicenseDuration));
      setSalesContactEmail(selectedOrganization.SalesContactEmail);
      setCustomerContactEmail(selectedOrganization.CustomerContactEmail);
    }
  }, [
    selectedOrganization?.Id,
    selectedOrganization?.Name,
    selectedOrganization?.AvailableLicenses,
    selectedOrganization?.LicenseDuration,
    selectedOrganization?.CustomerContactEmail,
    selectedOrganization?.SalesContactEmail,
  ]);

  const resetValues = () => {
    setOpen(false);
    setSelectedOrganization(undefined);
    setIsAdding(false);
    setOrganizationName('');
    setOrganizationUuid(uuidv4());
    setAvailableLicenses(10);
    setLicenseDurationStart(new Date());
    setLicenseDurationEnd(addYears(new Date(), 1));
    setSelectedFirmwarePackage(undefined);
    setSalesContactEmail('');
    setCustomerContactEmail('');
  };

  const showToast = (data: OperationResult) => {
    if (data.error) {
      toast(data);
    } else {
      toast(data, {
        message: {
          type: 'success',
          content: intl.formatMessage(
            {
              id: isAdding ? 'Added organization' : 'Updated organization',
            },
            {
              name: isAdding ? organizationName : selectedOrganization?.Name,
            },
          ),
        },
      });
      resetValues();
    }
  };

  return (
    <Modal
      action={isAdding ? Action.ADD : Action.UPDATE}
      title={`${intl.formatMessage({
        id: isAdding ? 'Add organization' : 'Edit organization',
      })} ${isAdding ? '' : (selectedOrganization?.Name ?? '')}`}
      open={open}
      setShowModal={resetValues}
      footer={
        <ModalFooter
          disabled={
            userRoles?.includes(HasuraPermissions.READ_ALL)
              ? !organizationName ||
                loading ||
                !salesContactEmail ||
                !customerContactEmail ||
                availableLicenses < 1 ||
                !licenseDurationStart ||
                !licenseDurationEnd ||
                (isAdding && !selectedFirmwarePackage)
              : availableLicenses < 1 ||
                !licenseDurationStart ||
                !licenseDurationEnd
          }
          action={isAdding ? Action.ADD : Action.UPDATE}
          loading={<FormattedMessage id="Save" />}
          isLoading={loadingAddOrganization || loadingUpdateLicenseOrganization}
          onProceed={() =>
            userRoles?.includes(HasuraPermissions.READ_ALL)
              ? addOrganization(
                  {
                    UserInput: {
                      addSampleData,
                      organization: organizationName ?? '',
                      uuid: organizationUuid,
                      clientRolesToAdd: { mda2: checkedRoles },
                      clientRolesToDelete: { mda2: deletedRoles },
                      oldOrganization:
                        isAdding ||
                        selectedOrganization?.Name === organizationName
                          ? undefined
                          : selectedOrganization?.Name,
                      expired: isAdding
                        ? undefined
                        : licenseDurationEnd < new Date(),
                    },
                    Organization: {
                      Id: organizationUuid,
                      Name: organizationName,
                      LicenseDuration: serializeRange({
                        start: {
                          value: new Date(startOfDayUTC(licenseDurationStart)),
                          inclusive: true,
                        },
                        end: {
                          value: new Date(endOfDayUTC(licenseDurationEnd)),
                          inclusive: false,
                        },
                      }),
                      AvailableLicenses: availableLicenses,
                      SalesContactEmail: salesContactEmail,
                      CustomerContactEmail: customerContactEmail,
                      MqttOrganizations: {
                        data: [{ UniqueIdentifier: organizationUuid }],
                        on_conflict: {
                          constraint:
                            MqttOrganizations_Constraint.MqttOrganizationsUniqueIdentifierKey,
                          update_columns: [
                            MqttOrganizations_Update_Column.UpdatedAt,
                          ],
                        },
                      },
                      // Set a default firmware package when first adding a new organization
                      // Later it is selected through the Firmwares page
                      FirmwarePackageOrganizations: isAdding
                        ? {
                            data: [
                              {
                                FirmwarePackageId: selectedFirmwarePackage?.Id,
                                Latest: selectedFirmwarePackage?.Id
                                  ? true
                                  : undefined,
                              },
                            ],
                          }
                        : undefined,
                    },
                  },
                  hasuraHeader(HasuraPermissions.READ_ALL, ['RolesOutput']),
                ).then((d) => showToast(d))
              : updateOrganizationLicense(
                  {
                    Id: organizationUuid,
                    Duration: serializeRange({
                      start: {
                        value: new Date(startOfDayUTC(licenseDurationStart)),
                        inclusive: true,
                      },
                      end: {
                        value: new Date(endOfDayUTC(licenseDurationEnd)),
                        inclusive: false,
                      },
                    }),
                    AvailableLicenses: availableLicenses,
                  },
                  hasuraHeader(HasuraPermissions.VIEW_ORGANIZATIONS, [
                    'RolesOutput',
                  ]),
                ).then((d) => showToast(d))
          }
          onCancel={resetValues}
        />
      }
    >
      <div className="space-y-4">
        <PrivateWrapper roleRequired={HasuraPermissions.READ_ALL}>
          <Input
            type="text"
            label={intl.formatMessage({
              id: 'Name',
            })}
            value={organizationName}
            placeholder={intl.formatMessage({ id: 'Organization name' })}
            icon={<HiOutlineBuildingOffice />}
            onChangeValue={setOrganizationName}
          />
          <div className="text-sm  text-neutral-700 dark:text-white space-y-4">
            <Transition show={!isAdding && loading}>
              <FaCircleNotch className="size-5 animate-spin text-primary-500" />
            </Transition>
            <PermissionCheckbox
              roles={
                rolesAndFirmwarePackages?.allRoles.userRoles
                  .sort((a, b) => a.name?.localeCompare(b.name ?? '') ?? 0)
                  .map((role) => ({
                    id: role.id ?? '0',
                    name: role.name ?? '',
                  })) ?? []
              }
              checkedRoles={checkedRoles}
              setCheckedRoles={setCheckedRoles}
              deletedRoles={deletedRoles}
              setDeletedRoles={setDeletedRoles}
            />
          </div>
          <Transition show={isAdding} className="space-y-2">
            <Checkbox
              checked={addSampleData}
              setChecked={setAddSampleData}
              label={intl.formatMessage({ id: 'Add sample data' })}
              tooltip={intl.formatMessage({
                id: 'Creates a building, floor, room and desk',
              })}
            />
            <Select
              label="Firmware package"
              value={selectedFirmwarePackage}
              options={rolesAndFirmwarePackages?.FirmwarePackages ?? []}
              onChangeSelected={(selected) =>
                selected && setSelectedFirmwarePackage(selected)
              }
              renderValue={(s) => s?.Version ?? '-'}
              keyParameter="Id"
              required
              isDeselectable={false}
            />
          </Transition>
        </PrivateWrapper>
        <div className="flex flex-col space-y-2">
          <Subtitle value={intl.formatMessage({ id: 'Licensing' })} />
          <Input
            data-test-id="available-licenses-input"
            required
            type="number"
            label={intl.formatMessage({
              id: 'Available licenses',
            })}
            value={availableLicenses}
            placeholder={intl.formatMessage({ id: 'Available licenses' })}
            icon={<HiOutlineKey />}
            onChangeValue={(e) => setAvailableLicenses(Number.parseInt(e, 10))}
          />
          <div className="flex space-x-2">
            <DatePickerInput
              // Since headless-ui 2.1.2 upgrade this is needed, no idea why
              key={licenseDurationStart.getTime()}
              dataTestId="license-start-input"
              required
              label="Date from"
              options={{
                enableTime: false,
                defaultDate: [licenseDurationStart],
                dateFormat: 'M j Y',
                maxDate: licenseDurationEnd,
                onChange: ([selectedDate]) =>
                  setLicenseDurationStart(selectedDate ?? new Date()),
              }}
            />
            <DatePickerInput
              // Since headless-ui 2.1.2 upgrade this is needed, no idea why
              key={licenseDurationEnd.getTime()}
              dataTestId="license-end-input"
              required
              label="Date to"
              options={{
                enableTime: false,
                defaultDate: [licenseDurationEnd],
                dateFormat: 'M j Y',
                minDate: licenseDurationStart,
                onChange: ([selectedDate]) =>
                  setLicenseDurationEnd(selectedDate ?? new Date()),
              }}
            />
          </div>
          <PrivateWrapper roleRequired={HasuraPermissions.READ_ALL}>
            <Input
              required
              type="email"
              label={intl.formatMessage({
                id: 'Sales contact email',
              })}
              value={salesContactEmail}
              placeholder={intl.formatMessage({ id: 'Sales contact email' })}
              icon={<HiOutlineChatBubbleOvalLeft />}
              onChangeValue={setSalesContactEmail}
            />
            <Input
              required
              type="email"
              label={intl.formatMessage({
                id: 'Customer contact email',
              })}
              value={customerContactEmail}
              placeholder={intl.formatMessage({
                id: 'Customer contact email',
              })}
              icon={<HiOutlineChatBubbleOvalLeftEllipsis />}
              onChangeValue={setCustomerContactEmail}
            />
          </PrivateWrapper>
        </div>
      </div>
    </Modal>
  );
}
