import { useReportDatesQuery } from 'graphql/types';
import useStore from 'model/store';
import { useEffect, useMemo } from 'react';
import { lower, upper } from 'utils/date';
import format from 'utils/format';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import Select from '../Select';

export default function ReportDatePicker() {
  const hasuraHeader = useHasuraHeader();
  const reportDate = useStore((state) => state.userSettings.reportDate);
  const setReportDate = useStore((state) => state.userApi.setReportDate);
  const [{ data }] = useReportDatesQuery({
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.VIEW_REPORTS),
      [hasuraHeader],
    ),
  });

  const options = useMemo(
    () => data?.Reports?.map((d) => d.Period) ?? [],
    [data],
  );

  // Set an initial report date if there is none
  useEffect(() => {
    if (!reportDate && options?.[0]) {
      setReportDate(options[0]);
    }
  }, [reportDate, setReportDate, options?.[0]]);

  return (
    <Select
      dataTestId="select-report-date"
      // Get the start date of the report in yyyy-mm-dd format
      optionDataTestId={(d) => d?.split(',')[0]?.substring(1) ?? ''}
      label="Report date"
      value={options.find((d) => d === reportDate)}
      onChangeSelected={(selected) => {
        if (selected) {
          setReportDate(selected);
        }
      }}
      renderValue={(r) =>
        r ? `${format(lower(r), 'PP')} - ${format(upper(r), 'PP')}` : ''
      }
      options={options}
      isDeselectable={false}
    />
  );
}
