import { Feature } from 'ol';
import type { Geometry } from 'ol/geom';

export default class TypedFeature<
  T extends { [x: string]: any },
  K extends Geometry,
> extends Feature<K> {
  // biome-ignore lint/complexity/noUselessConstructor: Helps with types
  constructor(properties: T & { geometry: K }) {
    super(properties);
  }
  getProperties() {
    return super.getProperties() as Pick<T, keyof T>;
  }
}
