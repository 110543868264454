import {
  ReactIntegration,
  ReactRouterVersion,
  getWebInstrumentations,
} from '@grafana/faro-react';
import { initializeFaro, type Faro } from '@grafana/faro-web-sdk';
import { matchRoutes } from 'react-router-dom';

export default function initialize(): Faro {
  return initializeFaro({
    url: 'https://issues.regent.cloud/collect',
    apiKey: import.meta.env.VITE_FARO_API_KEY,
    instrumentations: [
      // Load the default Web instrumentations
      ...getWebInstrumentations({ captureConsole: true }),
      new ReactIntegration({
        router: {
          version: ReactRouterVersion.V6_data_router,
          dependencies: {
            matchRoutes,
          },
        },
      }),
    ],
    app: {
      name: 'frontend',
      version: import.meta.env.VITE_VERSION,
      environment: process.env.NODE_ENV,
    },
  });
}
