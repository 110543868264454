import { getWeekDayFromNumber } from 'mda2-frontend/src/utils/date';
import { FormattedMessage } from 'translations/Intl';
import Button from '../Form/Button';

interface DaySelectProps {
  dayOfWeek: number | null;
  setDayOfWeek: (dayOfWeek: number | null) => void;
}

export default function DaySelect({ dayOfWeek, setDayOfWeek }: DaySelectProps) {
  return (
    <div className="flex text-xs space-x-0.5" data-test-id="day-select">
      {[null, 1, 2, 3, 4, 5, 6, 0].map((weekday) => (
        <Button
          key={weekday}
          className={`py-1 px-4 rounded-xl transition-all ${
            dayOfWeek === weekday
              ? 'bg-primary-200 text-primary-500 dark:text-neutral-500 cursor-default'
              : 'hover:bg-neutral-200 dark:hover:bg-neutral-700'
          }`}
          onClick={() => setDayOfWeek(weekday)}
        >
          {weekday === null ? (
            <FormattedMessage id="Business days" />
          ) : (
            getWeekDayFromNumber(weekday)
          )}
        </Button>
      ))}
    </div>
  );
}
