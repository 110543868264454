import type { Range } from 'common/types';

const parseNumberRange = (range: string) => {
  const parts = range.split(',');

  if (parts.length !== 2 || !parts[0] || !parts[1]) {
    throw new Error('Invalid range');
  }

  const startValue = Number.parseInt(parts[0].slice(1).replaceAll('"', ''), 10);
  const endValue = Number.parseInt(
    parts[1].slice(0, -1).replaceAll('"', ''),
    10,
  );

  return {
    start: {
      inclusive: parts[0][0] === '[',
      value: Number.isNaN(startValue) ? undefined : startValue,
    },

    end: {
      inclusive: parts[1][parts[1].length - 1] === ']',
      value: Number.isNaN(endValue) ? undefined : endValue,
    },
  };
};

export const serializeRange = ({ start, end }: Range) => {
  const inclusivity = (inclusive: boolean) => (inclusive ? '[]' : '()');

  return `${start ? inclusivity(start.inclusive)[0] : '('}${
    start?.value ?? ''
  },${end?.value ?? ''}${end ? inclusivity(end.inclusive)[1] : ')'}`;
};

export const lower = (range: string): number | undefined =>
  parseNumberRange(range).start.value;

export const upper = (range: string): number | undefined =>
  parseNumberRange(range).end.value;
