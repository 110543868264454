import type { ThemeConfig } from '@visx/xychart/lib/theme/buildChartTheme';
import { Themes } from 'common/types';
import useStore from 'model/store';
import getColor from './getColor';

export default function useXYChartTheme({
  colorRange,
  // @ts-expect-error When adding own config (buildChartTheme()) it looks wrong
}: { colorRange: ThemeConfig['colors'] }): XYChartTheme {
  const backgroundColor = useStore((state) => state.userSettings.theme.color);

  return {
    colors: colorRange,
    backgroundColor:
      backgroundColor === Themes.LIGHT
        ? getColor('WHITE')
        : getColor('NEUTRAL900'),
  };
}
