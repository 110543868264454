import useStore from 'model/store';
import { FormattedMessage } from 'translations/Intl';

import Select from 'mda2-frontend/src/generic/components/Form/Select';
import type { BuildingPartsFragment } from 'mda2-frontend/src/graphql/types';
import { useMemo } from 'react';

interface BuildingSelectProps {
  buildings?: BuildingPartsFragment[];
  isDeselectable?: boolean;
  onBuildingSelect?: (b: BuildingPartsFragment | null) => void;
  buildingName?: string | null;
}

export default function BuildingSelect({
  buildings = [],
  isDeselectable = false,
  onBuildingSelect,
  buildingName,
}: BuildingSelectProps): JSX.Element {
  const building = useStore((state) => state.userSettings.building);
  const setBuilding = useStore((state) => state.userApi.setBuilding);
  const setFloor = useStore((state) => state.userApi.setFloor);
  const params = useMemo(() => new URLSearchParams(), []);

  return (
    <Select
      dataTestId="select-building"
      value={
        (buildings.find((b) =>
          buildingName !== undefined
            ? b.Name === buildingName
            : b.Id === building?.Id,
        ) ??
          null) ||
        (!isDeselectable ? buildings[0] : null)
      }
      label="Building"
      onChangeSelected={(selected) => {
        if (onBuildingSelect) {
          onBuildingSelect(selected ?? null);
        } else {
          if (selected) {
            setBuilding({ Id: selected.Id, Name: selected.Name });
          } else {
            setBuilding(undefined);
            params.delete('building');
          }
          setFloor(undefined);
          params.delete('floor');
        }
      }}
      options={buildings}
      isDeselectable={isDeselectable}
      renderValue={(b) => b?.Name ?? ''}
      keyParameter="Id"
      defaultValue={<FormattedMessage id="All" />}
    />
  );
}
