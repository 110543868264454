import { Transition as HUITransition } from '@headlessui/react';
import type { ElementType } from 'react';

interface TransitionProps {
  className?: string;
  show?: boolean;
  children: React.ReactNode;
  as?: ElementType<any>;
  unmount?: boolean;
  appear?: boolean;
}

export default function Transition({
  className,
  show,
  children,
  as = 'div',
  unmount = true,
  appear = true,
}: TransitionProps) {
  return (
    <HUITransition
      appear={appear}
      show={show}
      as={as}
      unmount={unmount}
      className={className}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-out duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      {children}
    </HUITransition>
  );
}
