import { RoomTypes } from 'mda2-frontend/src/common/types';
import useStore from 'mda2-frontend/src/model/store';

export default function useRoomDeskFilter() {
  const roomTypes = useStore((state) => state.userSettings.roomTypes);

  return {
    Rooms: roomTypes.includes(RoomTypes.MEETING),
    Desks: roomTypes.includes(RoomTypes.DESKS),
  };
}
