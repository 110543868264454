const hexToBase64 = (hexInput: string): string =>
  hexInput
    ? btoa(
        (hexInput.replace('\\x', '').match(/\w{2}/g) ?? [])
          .map((a: string) => String.fromCharCode(Number.parseInt(a, 16)))
          .join(''),
      )
    : '';

export default hexToBase64;
