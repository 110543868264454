import { FormattedMessage } from 'translations/Intl';

import { LS_HIDE_CANCELLED_RESERVATIONS } from 'mda2-frontend/src/constants';
import Switch from 'mda2-frontend/src/generic/components/Form/Switch';
import { useState } from 'react';

import ReservationsAgenda from '../ReservationsAgenda';

export default function ReservationList(): JSX.Element {
  const [showCancelledEvents, setShowCancelledEvents] = useState(
    localStorage.getItem(LS_HIDE_CANCELLED_RESERVATIONS)
      ? localStorage.getItem(LS_HIDE_CANCELLED_RESERVATIONS) === 'true'
      : true,
  );

  return (
    <div className="flex flex-col gap-2">
      <Switch
        data-test-id="cancel-switch-reservation-list"
        isEnabled={showCancelledEvents}
        onSetEnable={(enabling) => {
          localStorage.setItem(
            LS_HIDE_CANCELLED_RESERVATIONS,
            JSON.stringify(enabling),
          );
          setShowCancelledEvents(enabling);
        }}
        label={<FormattedMessage id="Show cancelled reservations" />}
      />
      <div data-test-id="reservation-list">
        <ReservationsAgenda />
      </div>
    </div>
  );
}
