import { addDays, differenceInDays } from 'date-fns';
import Subtitle from 'generic/components/Subtitle';
import { useReportSummaryQuery } from 'graphql/types';
import { lower, upper } from 'utils/date';
import useReportFilter from 'utils/graphql/useReportFilter';
import { uuidv4 } from 'utils/uuid';
import ReportingPage from '../ReportingPage';
import type { SummaryData } from './LineChart';
import LineChart from './LineChart';

export default function ReportSummary() {
  const { context, variables } = useReportFilter();
  const [{ data, fetching }] = useReportSummaryQuery({
    context,
    variables: {
      Building: variables.Building,
    },
  });

  const summaryData: SummaryData = {
    limits: {
      good: 100, // 80-100%
      acceptable: 80, // 60-80%
      poor: 60, // 0-60%
    },
    reports: (data?.ReportPages ?? []).flatMap((page) => {
      const selectedReportPageMetric = page.ReportPageMetrics.find(
        (rpm) => rpm.MetricLimits.length,
      );
      const selectedLimit = selectedReportPageMetric?.MetricLimits[0];

      return (selectedReportPageMetric?.ReportReportPageMetrics ?? []).flatMap(
        (rpm) => {
          const days =
            differenceInDays(
              upper(rpm.Report.Period),
              lower(rpm.Report.Period),
            ) + 1;

          const weight = selectedLimit?.Weight ?? 0;
          let value = selectedLimit?.DivideByTargetValue
            ? (rpm.Value * 100) / selectedLimit.TargetValue
            : ((selectedLimit?.TargetValue ?? 1) * 100) / rpm.Value;

          if (value === Number.POSITIVE_INFINITY) {
            value = 0;
          }

          return Array.from({ length: days }, (_, i) => ({
            id: uuidv4(),
            reportPageTitle: page.Name,
            weight,
            date: addDays(lower(rpm.Report.Period), i),
            value,
          }));
        },
      );
    }),
  };

  return (
    <ReportingPage loading={fetching} reportTitle="Management summary">
      <Subtitle value="Compliance" />
      <div className="h-96 w-full">
        <LineChart data={summaryData} />
      </div>
    </ReportingPage>
  );
}
