import Card from 'mda2-frontend/src/generic/components/Card';
import PrivateWrapper from 'mda2-frontend/src/generic/components/PrivateWrapper';
import OccupancyMap from 'mda2-frontend/src/pages/FindMyPlaceView/components/OccupancyMap';
import { HasuraPermissions } from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';

import LiveOccupancy from './components/LiveOccupancy';

export default function DashboardView(): JSX.Element {
  return (
    <div className="space-y-6">
      <div className="grid grid-cols-12 gap-2">
        <LiveOccupancy />
        <PrivateWrapper roleRequired={HasuraPermissions.FEATURE_PEOPLECOUNT}>
          <Card className="relative col-span-12 md:col-span-6 lg:col-span-8 xl:col-span-9">
            <OccupancyMap className="map-height-dashboard" />
          </Card>
        </PrivateWrapper>
      </div>
    </div>
  );
}
