import Asset from 'img/devices/minified/Asset.svg?react';
import Gateway from 'img/devices/minified/Gateway.svg?react';
import Generic from 'img/devices/minified/Generic.svg?react';
import Lightpad1 from 'img/devices/minified/Lightpad1.svg?react';
import Lightpad2 from 'img/devices/minified/Lightpad2.svg?react';
import Lightpad3 from 'img/devices/minified/Lightpad3.svg?react';
import Lightpad4 from 'img/devices/minified/Lightpad4.svg?react';
import Neo from 'img/devices/minified/Neo.svg?react';
import SmartmoduleOne from 'img/devices/minified/Smartmodule_One.svg?react';
import SmartmoduleX from 'img/devices/minified/Smartmodule_X.svg?react';
import SoloSlim from 'img/devices/minified/Solo_Slim_test.svg?react';

import { DeviceTypes } from 'mda2-frontend/src/common/types';

export interface DeviceProps {
  deviceType: DeviceTypes;
  desksInUse?: number;
}

interface LuminaireIconProps {
  device: DeviceProps;
  size?: number;
}

export default function LuminaireIcon({
  device,
  size = 60,
}: LuminaireIconProps) {
  switch (device.deviceType) {
    case DeviceTypes.ASSET:
      return <Asset height={size} width={size} />;

    case DeviceTypes.GATEWAY:
      return <Gateway height={size} width={size} />;

    case DeviceTypes.NODE:
      return <Generic height={size} width={size} />;

    case DeviceTypes.LIGHTPAD:
      switch (device.desksInUse) {
        case 1:
          return <Lightpad1 height={size} width={size} />;

        case 2:
          return <Lightpad2 height={size} width={size} />;

        case 3:
          return <Lightpad3 height={size} width={size} />;
        case 4:
          return <Lightpad4 height={size} width={size} />;

        default:
          return <Lightpad1 height={size} width={size} />;
      }

    case DeviceTypes.SOLO_SLIM:
      return <SoloSlim height={size} width={size} />;

    case DeviceTypes.SMART_MODULE_O:
      return <SmartmoduleOne height={size} width={size} />;

    case DeviceTypes.SMART_MODULE_X:
      return <SmartmoduleX height={size} width={size} />;

    case DeviceTypes.NEO:
      return <Neo height={size} width={size} />;

    case DeviceTypes.LEVEL:
      return <Lightpad1 height={size} width={size} />;

    case DeviceTypes.TWEAK:
      return <Lightpad1 height={size} width={size} />;

    default:
      return <Generic height={size} width={size} />;
  }
}
