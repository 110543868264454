import { Annotation, CircleSubject, Connector, Label } from '@visx/annotation';
import { curveBasis } from '@visx/curve';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import { ParentSize } from '@visx/responsive';
import { scaleLinear, scaleOrdinal, scaleTime } from '@visx/scale';
import { Line, LinePath } from '@visx/shape';
import { Threshold } from '@visx/threshold';
import { type MarginProps, Themes } from 'common/types';
import { extent } from 'd3-array';
import Axis from 'generic/components/Chart/Axis';
import Legend from 'generic/components/Chart/Legend';
import { RAINBOW } from 'mda2-frontend/src/constants';
import useStore from 'model/store';
import { AnimatedPath } from 'pages/AnalyticsView/components/BrushCart/Components/AreaChart';
import { REPORTING_RIGHT_MARGIN } from 'pages/ReportingView/Reports/Reports';
import { cloneElement, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'translations/Intl';
import format from 'utils/format';
import getColor, { primaryColorToRGB } from 'utils/getColor';

const weightedSum = (month: string, data: SummaryData) =>
  (data.reports
    .filter((d) => format(d.date, 'yyyy-MM') === month)
    .reduce((a, b) => a + b.value * b.weight, 0) ?? 0) / 100;
const weightedWeights = (month: string, data: SummaryData) =>
  (data.reports
    .filter((d) => format(d.date, 'yyyy-MM') === month)
    .reduce((a, b) => a + b.weight, 0) ?? 0) / 100;

export type SummaryData = {
  limits: {
    good: number;
    acceptable: number;
    poor: number;
  };
  reports: {
    id: string;
    reportPageTitle: string;
    weight: number;
    date: Date;
    value: number;
  }[];
};

interface ResponsiveLineChartProps {
  margin?: MarginProps;
  data: SummaryData;
}

interface LineChartProps extends ResponsiveLineChartProps {
  height: number;
  width: number;
}

const getPeriod = (d: SummaryData['reports'][number]) => d.date;

function LineChart({
  height,
  width,
  margin = {
    top: width > 1300 ? 50 : 80,
    left: 70,
    right: REPORTING_RIGHT_MARGIN,
    bottom: 50,
  },
  data,
}: LineChartProps) {
  const intl = useIntl();
  const theme = useStore((state) => state.userSettings.theme);
  const reportTitles = [...new Set(data.reports.map((d) => d.reportPageTitle))];
  const xMax = Math.max(width - margin.left - margin.right, 0);
  const yMax = height - margin.top - margin.bottom;

  const colorScale = scaleOrdinal({
    domain: reportTitles,
    range: RAINBOW,
  });

  const xScale = scaleTime<number>({
    range: [0, xMax],
    domain: extent(data.reports, getPeriod) as [Date, Date],
  });

  const yScale = scaleLinear<number>({
    range: [yMax, 0],
    domain: [0, 100],
    nice: true,
  });

  const shapeScale = scaleOrdinal<string, React.FC | React.ReactNode>({
    domain: [intl.formatMessage({ id: 'Weighted average' })],
    range: [
      <Line
        key={intl.formatMessage({ id: 'Weighted average' })}
        from={{ x: 0, y: 8 }}
        to={{ x: 10, y: 8 }}
        strokeWidth={2}
        stroke={
          theme.color === Themes.LIGHT
            ? getColor('NEUTRAL600')
            : getColor('NEUTRAL300')
        }
      />,
    ],
  });

  const weighted = (month: string) =>
    weightedSum(month, data) / weightedWeights(month, data);

  const reportData = data.reports.map((d) => ({
    ...d,
    weightedValue: weighted(format(d.date, 'yyyy-MM')),
  }));

  const annotationDatum = useMemo(
    () => reportData[reportData.length - 1],
    [reportData],
  );

  const [annotationPosition, setAnnotationPosition] = useState<
    { x: number; y: number; dx: number; dy: number } | undefined
  >();

  useEffect(() => {
    if (annotationDatum?.id && !annotationPosition?.x) {
      setAnnotationPosition({
        x: xScale(annotationDatum.date),
        y: yScale(annotationDatum.weightedValue),
        dx: -100,
        dy: -50,
      });
    }
  }, [
    annotationDatum?.id,
    annotationDatum?.date,
    annotationDatum?.weightedValue,
    xScale,
    yScale,
    annotationPosition?.x,
  ]);

  return (
    <div className="relative">
      <svg width={width} height={height}>
        <Group top={margin.top} left={margin.left}>
          <GridRows
            numTicks={10}
            scale={yScale}
            width={xMax}
            height={yMax}
            strokeDasharray="1,3"
            stroke={getColor('NEUTRAL600')}
            strokeOpacity={0.6}
          />
          <Threshold
            id="good"
            data={data.reports ?? []}
            x={(x) => xScale(getPeriod(x)) ?? 0}
            y0={() => yScale(data.limits.good)}
            y1={() => yScale(data.limits.acceptable)}
            clipAboveTo={0}
            clipBelowTo={yMax}
            aboveAreaProps={{
              fill: getColor('GREEN', '0.1'),
            }}
          />
          <Threshold
            id="acceptable"
            data={data.reports ?? []}
            x={(x) => xScale(getPeriod(x)) ?? 0}
            y0={() => yScale(data.limits.acceptable)}
            y1={() => yScale(data.limits.poor)}
            clipAboveTo={0}
            clipBelowTo={yMax}
            aboveAreaProps={{
              fill: getColor('YELLOW', '0.1'),
            }}
          />
          <Threshold
            id="poor"
            data={data.reports ?? []}
            x={(x) => xScale(getPeriod(x)) ?? 0}
            y0={() => yScale(data.limits.poor)}
            y1={() => yScale(0)}
            clipAboveTo={0}
            clipBelowTo={yMax}
            aboveAreaProps={{
              fill: getColor('RED', '0.1'),
            }}
          />
          <LinePath
            curve={curveBasis}
            data={reportData.filter(
              // Just select any report as they all have the same "weightedValue"
              (r) => r.reportPageTitle === reportTitles[0],
            )}
            x={(x) => xScale(getPeriod(x)) ?? 0}
            y={(y) => yScale(y.weightedValue) ?? 0}
          >
            {({ path }) => (
              <AnimatedPath
                path={path}
                data={reportData.filter(
                  (r) => r.reportPageTitle === reportTitles[0],
                )}
                strokeWidth={5}
                strokeOpacity={1}
              />
            )}
          </LinePath>
          {reportTitles.map((d) => (
            <LinePath
              key={d}
              curve={curveBasis}
              data={data.reports.filter((r) => r.reportPageTitle === d)}
              x={(x) => xScale(getPeriod(x)) ?? 0}
              y={(y) => yScale(y.value) ?? 0}
            >
              {({ path }) => (
                <AnimatedPath
                  path={path}
                  data={data.reports.filter((r) => r.reportPageTitle === d)}
                  stroke={colorScale(d)}
                />
              )}
            </LinePath>
          ))}
          <Axis
            lowLevelChart
            orientation="left"
            scale={yScale}
            tickFormat={(y) => `${y}%`}
            label={intl.formatMessage({ id: 'Compliance' })}
          />
          <Axis
            lowLevelChart
            orientation="bottom"
            top={yMax}
            scale={xScale}
            label={intl.formatMessage({ id: 'Date' })}
          />
          {annotationPosition?.x && (
            <Annotation
              x={annotationPosition.x}
              y={annotationPosition.y}
              dx={annotationPosition.dx}
              dy={annotationPosition.dy}
            >
              <Connector stroke={primaryColorToRGB(500)} type="elbow" />
              <Label
                backgroundFill="white"
                showAnchorLine={false}
                anchorLineStroke={primaryColorToRGB(500)}
                backgroundProps={{ stroke: primaryColorToRGB(500) }}
                fontColor={primaryColorToRGB(500)}
                subtitle={`${annotationDatum?.weightedValue?.toFixed(2)}%`}
                title={intl.formatMessage({ id: 'Weighted average' })}
                width={100}
              />
              <CircleSubject stroke={primaryColorToRGB(500)} />
            </Annotation>
          )}
        </Group>
      </svg>
      <div className="flex items-center justify-evenly absolute top-0 w-full space-y-2">
        <div className="flex flex-col items-center">
          <div className="flex">
            <Legend scaleType="ordinal" scale={colorScale} />
          </div>
          <div className="flex">
            <LegendOrdinal
              direction="row"
              labelMargin="0 15px 0 0"
              scale={shapeScale}
            >
              {(groups) => (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {groups.map((label, i) => {
                    const shape = shapeScale(label.datum);
                    return (
                      <LegendItem margin="0 5px" key={`legend-quantile-${i}`}>
                        <svg width="16" height="16" viewBox="0 0 16 16">
                          {cloneElement(shape as React.ReactElement)}
                        </svg>
                        <LegendLabel align="left" className="text-xs">
                          {label.text}
                        </LegendLabel>
                      </LegendItem>
                    );
                  })}
                </div>
              )}
            </LegendOrdinal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function ResponsiveLineChart(props: ResponsiveLineChartProps) {
  return (
    <ParentSize>
      {({ height, width }) => (
        <LineChart {...props} height={height} width={width} />
      )}
    </ParentSize>
  );
}
