export enum ClimateStatus {
  GOOD = 'good',
  ACCEPTABLE = 'acceptable',
  POOR = 'poor',
}

const getClimateStatus = (
  value: number,
  limits: {
    good: {
      start: number | undefined,
      end: number | undefined,
    },
    acceptable: {
      start: number | undefined,
      end: number | undefined,
    },
    poor: {
      start: number | undefined,
      end: number | undefined,
    },
  }
): ClimateStatus => {
  if (limits.good.start && limits.good.end) {
    if (value >= (limits.good.start) && value < (limits.good.end)) {
      return ClimateStatus.GOOD;
    }
  } else if (limits.good.end && value < (limits.good.end)) {
    return ClimateStatus.GOOD;
  }

  if (
    limits.acceptable.start && limits.acceptable.end &&
    value >= (limits.acceptable.start) &&
    value < (limits.acceptable.end)
  ) {
    return ClimateStatus.ACCEPTABLE;
  }

  return ClimateStatus.POOR;
}

export default getClimateStatus;
