import type { SliderItem } from 'react-compound-slider';

interface TickProps {
  tick: SliderItem;
  count: number;
  format?: (val: number) => string;
}

export default function Tick({
  tick,
  count,
  format = (d) => `${d}`,
}: TickProps) {
  return (
    <div>
      <div
        className="absolute mt-0.5 w-0.5 h-2 bg-neutral-200"
        style={{ left: `${tick.percent}%` }}
      />
      <div
        className="absolute mt-3 text-xs text-center"
        style={{
          left: `${tick.percent}%`,
          width: `${100 / count}%`,
          marginLeft: `${-(100 / count) / 2}%`,
        }}
      >
        {format(tick.value)}
      </div>
    </div>
  );
}
