import type { OccupancyMapAssetsQuery } from 'mda2-frontend/src/graphql/types';
import getColor, { primaryColorToRGB } from 'mda2-frontend/src/utils/getColor';
import type { Feature } from 'ol';
import type OLMap from 'ol/Map';
import GeoJSON, { type GeoJSONFeature } from 'ol/format/GeoJSON';
import type { default as OLGeometry } from 'ol/geom/Geometry';
import type Point from 'ol/geom/Point';
import OLVectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import type { LayerOptions } from './Layer';
import type TypedFeature from './TypedFeature';
import VectorLayer from './VectorLayer';

type AssetQueryType = NonNullable<
  OccupancyMapAssetsQuery['MqttAssetTrackers']
>[number];
interface AssetType extends AssetQueryType {
  isAssetFeature: boolean;
}

export type AssetFeatureType = TypedFeature<AssetType, OLGeometry>;

const getAssetColor = (
  isFill: boolean,
  offline: boolean,
  isMoving: boolean,
) => {
  if (offline) {
    return isFill ? getColor('NEUTRAL400', '.9') : getColor('NEUTRAL600', '.8');
  }
  if (isMoving) {
    return primaryColorToRGB(600, 1);
  }
  return isFill ? primaryColorToRGB(300, 1) : primaryColorToRGB(500, 1);
};

const styleFunction = (
  feature: AssetFeatureType,
  hoveredFeature: AssetFeatureType | undefined,
) => {
  const isHovered = feature.getGeometry() === hoveredFeature?.getGeometry();
  const offline = !!feature.getProperties().IsOffline;
  const isMoving = feature.getProperties().IsMoving;
  return [
    new Style({
      image: new Circle({
        radius: isHovered ? 9 : 5,
        fill: new Fill({
          color: getAssetColor(true, offline, isMoving),
        }),
        stroke: new Stroke({
          color: getAssetColor(false, offline, isMoving),
          width: isHovered ? 3 : 2,
        }),
      }),
    }),
  ];
};

interface AssetLayerOptions extends LayerOptions {
  olLayer: OLVectorLayer<VectorSource<Feature<Point>>>;
}

class AssetLayer extends VectorLayer<Point> {
  features?: OccupancyMapAssetsQuery['MqttAssetTrackers'];

  hoveredFeature?: AssetFeatureType;

  height?: number;

  width?: number;

  init(map: OLMap): void {
    super.init(map);
    this.height = undefined;
    this.width = undefined;
  }

  constructor() {
    super({
      ...{
        olLayer: new OLVectorLayer({
          source: new VectorSource(),
          style: (feature) =>
            styleFunction(feature as AssetFeatureType, this.hoveredFeature),
        }),
      },
      name: 'assetLayer',
    } as AssetLayerOptions);

    this.hoveredFeature = undefined;
    this.height = undefined;
    this.width = undefined;
  }

  setFeatures(feats: OccupancyMapAssetsQuery['MqttAssetTrackers']): void {
    if (feats) {
      this.features = feats.filter(
        (f) => f.XCoordinate !== 0 && f.YCoordinate !== 0,
      );
    }
    const source = this.olLayer.getSource() as VectorSource<Feature<Point>>;
    const newFeatures = new GeoJSON().readFeatures(
      this.toGeoJson(this.features),
    );
    source.clear();
    source.addFeatures(newFeatures as Feature<Point>[]);
  }

  setBaseImageSize(width: number, height: number): void {
    this.width = width;
    this.height = height;
    if (this.features) {
      // If image size was changed then the features need to be set again
      this.setFeatures(this.features);
    }
  }

  toGeoJson(
    feats?: OccupancyMapAssetsQuery['MqttAssetTrackers'],
  ): GeoJSONFeature {
    const features =
      this.height !== undefined && this.width !== undefined
        ? feats?.map((feat) => {
            const geometry = {
              type: 'Point',
              coordinates: [
                (this.width as number) * feat.XCoordinate,
                (this.height as number) -
                  (this.height as number) * feat.YCoordinate,
              ],
            };
            return {
              type: 'Feature',
              geometry,
              properties: {
                ...feat,
                isAssetFeature: true,
              },
            };
          })
        : [];
    return {
      type: 'FeatureCollection',
      features,
    };
  }
}

export default AssetLayer;
