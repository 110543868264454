import keycloak from 'mda2-frontend/src/keycloak';
import { HasuraPermissions } from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import { Navigate, Outlet } from 'react-router-dom';

import useStore from 'model/store';
import { type Route, SERVICES_ROUTES } from '../routes';

interface PrivateRouteProps {
  roleRequired: HasuraPermissions | HasuraPermissions[];
  fallbackRoute?: Route['path'];
  noRedirection?: boolean;
}

function PrivateRoute({
  roleRequired,
  fallbackRoute = 'dashboard',
  noRedirection,
}: PrivateRouteProps) {
  const userRoles = useStore((state) => state.user)?.roles;

  const isAuthorized = (role: HasuraPermissions) =>
    (!!keycloak.token && keycloak.hasResourceRole(role)) ||
    // Special case for MDA3 role, as it is "virtual" and calculated based on the number of MDA3 beacons
    (role === HasuraPermissions.MDA3 &&
      userRoles?.includes(HasuraPermissions.MDA3));

  const authorized = Array.isArray(roleRequired)
    ? roleRequired.every((role) => isAuthorized(role))
    : isAuthorized(roleRequired);

  if (!authorized) {
    return noRedirection ? null : (
      <Navigate
        to={`/${
          SERVICES_ROUTES.find((service) =>
            Array.isArray(service.userRole)
              ? service.userRole.every((role) => keycloak.hasResourceRole(role))
              : keycloak.hasResourceRole(service.userRole),
          )?.path ?? fallbackRoute
        }`}
        replace
      />
    );
  }
  return <Outlet />;
}

export default PrivateRoute;
