interface BarLoaderProps {
  loading: boolean;
  isCard?: boolean;
}

export default function BarLoader({ isCard, loading }: BarLoaderProps) {
  return (
    // Empty div needed otherwise it won't be at the top alway (don't ask why)
    loading ? (
      <div>
        <div
          data-test-id="bar-loader"
          className={`${
            isCard ? 'absolute z-0' : 'fixed z-40'
          } top-0 left-0 right-0 w-full animate-pulse`}
        >
          <div
            className={`bg-primary-500 dark:bg-primary-700 ${
              isCard ? 'mx-2 h-0.5 rounded-lg' : 'h-1'
            }`}
          />
        </div>
      </div>
    ) : null
  );
}
