import Legend from 'generic/components/Chart/Legend';
import useStore from 'model/store';
import { useIntl } from 'translations/Intl';

import { curveBasis } from '@visx/curve';
import { ParentSize } from '@visx/responsive';
import { scaleOrdinal } from '@visx/scale';
import { AnimatedLineSeries, Grid, Tooltip, XYChart } from '@visx/xychart';
import Transition from 'generic/components/Transition';
import { ModuleType, Themes } from 'mda2-frontend/src/common/types';
import { TWIN_COLORS } from 'mda2-frontend/src/constants';
import Axis from 'mda2-frontend/src/generic/components/Chart/Axis';
import LoadingSpinner from 'mda2-frontend/src/generic/components/LoadingSpinner';
import type { RoomOccupancySensorCountQuery } from 'mda2-frontend/src/graphql/types';
import localize from 'mda2-frontend/src/utils/format';
import getColor from 'mda2-frontend/src/utils/getColor';
import { useCallback, useMemo, useState } from 'react';
import useXYChartTheme from 'utils/useXYChartTheme';

interface ResponsiveRoomOccupancySensorCountProps {
  margin?: { top: number; right: number; bottom: number; left: number };
  roomOccupancySensorCount?: RoomOccupancySensorCountQuery['f_history_room_occupancy_15m'];
  loadingRoomOccupancySensorCount: boolean;
  hideLegend?: boolean;
}

interface RoomOccupancySensorCountProps
  extends ResponsiveRoomOccupancySensorCountProps {
  width: number;
  height: number;
}

interface ChartData {
  Value: number;
  Date: Date;
  SensorType: string;
  OriginalValue?: number;
}

const renderKey = (
  d:
    | RoomOccupancySensorCountQuery['f_history_room_occupancy_15m'][number]
    | {
        Date: number;
        SensorType: string;
        Value: number;
        BeaconName?: string;
        Index?: number;
      },
) => {
  let sensorKey = 'area';
  if (d.SensorType !== ModuleType.AREACOUNT) {
    sensorKey = d.SensorType === ModuleType.LINECOUNT_IN ? 'in' : 'out';
  }
  const { BeaconName, Index } = d;
  return `${BeaconName || ''}-${sensorKey}-${
    typeof Index === 'number' ? Index : ''
  }`;
};

const TOTAL_SENSOR_TYPE = 'total';

const sortSensors = (a: string, b: string) => {
  const [beaconNameA, sensorTypeA, indexA] = a.split('-');
  const [beaconNameB, sensorTypeB, indexB] = b.split('-');
  return (
    (beaconNameA || '').localeCompare(beaconNameB || '') ||
    Number.parseInt(indexA ?? '0', 10) - Number.parseInt(indexB ?? '0', 10) ||
    (sensorTypeA || '').localeCompare(sensorTypeB || '')
  );
};

function RoomOccupancySensorCount({
  width,
  height,
  margin = {
    top: 50,
    left: 60,
    bottom: 40,
    right: 60,
  },
  roomOccupancySensorCount,
  loadingRoomOccupancySensorCount,
  hideLegend,
}: RoomOccupancySensorCountProps) {
  const intl = useIntl();
  const theme = useStore((state) => state.userSettings.theme);
  const room = useStore((state) => state.userSettings.room);
  const [numTicks] = useState(4);

  const sensorsData = useMemo(
    () =>
      roomOccupancySensorCount && roomOccupancySensorCount.length > 0
        ? roomOccupancySensorCount.map((sensorData) => ({
            ...sensorData,
            Date: new Date(sensorData.Date),
          }))
        : [
            {
              Id: 0,
              SensorId: 0,
              SensorType: ModuleType.LINECOUNT_IN,
              Index: 0,
              Value: 0,
              BeaconName: 'Default',
              Sign: 1,
              Date: new Date(),
            },
          ],
    [roomOccupancySensorCount],
  );

  const lineSensorTypes = useMemo<string[]>(
    () =>
      [
        ...new Set(
          sensorsData
            .filter((d) => d.SensorType !== ModuleType.AREACOUNT)
            .map((d) => renderKey(d)),
        ),
      ].sort(sortSensors),
    [sensorsData],
  );

  const areaSensorTypes = useMemo<string[]>(
    () =>
      [
        ...new Set(
          sensorsData
            .filter((d) => d.SensorType === ModuleType.AREACOUNT)
            .map((d) => renderKey(d)),
        ),
      ].sort(sortSensors),
    [sensorsData],
  );

  const colorRange = useMemo(
    () => [
      ...TWIN_COLORS.slice(0, lineSensorTypes.length + areaSensorTypes.length),
      theme.color === Themes.LIGHT ? '#000' : '#fff',
    ],
    [lineSensorTypes.length, areaSensorTypes.length, theme],
  );

  const xyTheme = useXYChartTheme({
    colorRange,
  });

  // return area sensors infos & line total to be display on the Secondary Y axis (right)
  const secondaryYScaleData = useMemo(() => {
    const lineSensorsData = sensorsData.filter(
      (sD) => sD.SensorType !== ModuleType.AREACOUNT,
    );

    const aggretateLinesOnDates = [
      ...new Set(lineSensorsData.map((sD) => sD.Date.getTime())),
    ].map((date) => ({
      Date: date,
      SensorType: TOTAL_SENSOR_TYPE,
      Value: lineSensorsData
        .filter((sD) => sD.Date.getTime() === date)
        .reduce(
          (acc, curr) =>
            acc +
            curr.Value *
              (curr.Sign *
                (curr.SensorType === ModuleType.LINECOUNT_IN ? 1 : -1)),
          0,
        ),
    }));

    const aggregatedRightYScaleData = [
      ...aggretateLinesOnDates,
      ...sensorsData.filter((sD) => sD.SensorType === ModuleType.AREACOUNT),
    ];

    const min = Math.min(...aggregatedRightYScaleData.map((d) => d.Value));
    const range =
      Math.max(...aggregatedRightYScaleData.map((d) => d.Value)) - min;
    const sensorMin = Math.min(...sensorsData.map((c) => c.Value));
    const sensorRange =
      Math.max(...sensorsData.map((c) => c.Value)) - sensorMin;
    const conversionFactor = sensorRange / range;

    return aggregatedRightYScaleData.map((d) => ({
      ...d,
      // Value transformed to the sensor In/Out Range
      // to be able to display two Y Axis on the same graphic
      Value: sensorMin + (d.Value - min) * conversionFactor,
      // Original value to show in the tooltip
      OriginalValue: d.Value,
    }));
  }, [sensorsData]);

  const convertPrimaryTickToSecondaryScale = useCallback(
    (tick: number) => {
      // primary values are just line sensors (left Y Axis)
      const primaryValues = sensorsData
        .filter((sD) => sD.SensorType !== ModuleType.AREACOUNT)
        .map((c) => c.Value);
      // secondary values are just total and area sensors (right Y Axis)
      const secondaryValues = secondaryYScaleData.map((c) => c.OriginalValue);
      const primaryMin = Math.min(...primaryValues);
      const primaryMax = Math.max(...primaryValues);
      const secondaryMin = Math.min(...secondaryValues);
      const secondaryMax = Math.max(...secondaryValues);
      const primaryDomain = primaryMax - primaryMin;
      const secondaryDomain = secondaryMax - secondaryMin;

      const conversionFactor = primaryDomain / secondaryDomain;

      const convertedTick =
        (tick - primaryMin) / conversionFactor + secondaryMin;

      return convertedTick.toFixed(0);
    },
    [secondaryYScaleData, sensorsData],
  );

  const filteredTotal = secondaryYScaleData.filter(
    (d) => d.SensorType === TOTAL_SENSOR_TYPE,
  );

  return (
    <>
      <LoadingSpinner loading={loadingRoomOccupancySensorCount} />
      <div className="relative" data-test-id="room-line-count">
        <XYChart
          margin={margin}
          theme={xyTheme}
          height={height}
          width={width}
          xScale={{
            type: 'time',
          }}
          yScale={{
            type: 'linear',
            domain: [
              Math.min(
                ...[
                  ...secondaryYScaleData.map((c) => c.Value),
                  ...sensorsData.map((c) => c.Value),
                ],
              ),
              Math.max(
                ...[
                  ...secondaryYScaleData.map((c) => c.Value),
                  ...sensorsData.map((c) => c.Value),
                ],
              ),
            ],
            nice: true,
            zero: false,
          }}
        >
          <Grid
            rows
            numTicks={numTicks}
            columns={false}
            strokeDasharray="1,3"
            stroke={getColor('NEUTRAL600')}
          />
          {lineSensorTypes.map((sensorData) => (
            <AnimatedLineSeries
              key={sensorData}
              dataKey={sensorData}
              data={sensorsData.filter((sD) => renderKey(sD) === sensorData)}
              xAccessor={(d) => d.Date}
              yAccessor={(d) => d.Value}
              fillOpacity={0.4}
              curve={curveBasis}
            />
          ))}
          {areaSensorTypes.map((sensorData) => (
            <AnimatedLineSeries
              key={sensorData}
              dataKey={sensorData}
              data={secondaryYScaleData.filter(
                (sD) => renderKey(sD) === sensorData,
              )}
              xAccessor={(d) => d.Date}
              yAccessor={(d) => d.Value}
              fillOpacity={0.4}
              curve={curveBasis}
            />
          ))}
          {filteredTotal.length && (
            <AnimatedLineSeries
              key={room?.Name || ''}
              dataKey={room?.Name || ''}
              data={filteredTotal}
              xAccessor={(d) => d.Date}
              yAccessor={(d) => d.Value}
              fillOpacity={0.4}
              curve={curveBasis}
            />
          )}
          <Axis
            orientation="left"
            numTicks={numTicks}
            label={intl.formatMessage({ id: 'In / Out' })}
            labelOffset={25}
          />
          <Axis
            orientation="bottom"
            numTicks={width > 768 ? 5 : 2}
            tickFormat={(v: string) =>
              localize(new Date(v), 'eeeeee do LLL, p', true)
            }
          />
          {filteredTotal.length && (
            <Axis
              orientation="right"
              numTicks={numTicks}
              tickFormat={(tick) => convertPrimaryTickToSecondaryScale(tick)}
              label={intl.formatMessage({ id: 'Total' })}
              labelOffset={25}
            />
          )}
          <Tooltip<ChartData>
            showVerticalCrosshair
            renderTooltip={({ tooltipData, colorScale }) => (
              <div
                className="dark:text-neutral-200"
                data-test-id="room-line-count-tooltip"
              >
                {localize(
                  tooltipData?.nearestDatum?.datum.Date ?? new Date(),
                  'eeeeee do LLL, p',
                  true,
                )}
                {Object.values(tooltipData?.datumByKey ?? {}).map(
                  (roomObject) => (
                    <div key={roomObject.key}>
                      <span
                        style={{
                          color: colorScale?.(roomObject.key),
                          textDecoration:
                            tooltipData?.nearestDatum?.key === roomObject.key
                              ? 'underline'
                              : undefined,
                        }}
                      >
                        {roomObject.key}
                      </span>{' '}
                      {Number.isNaN(roomObject.datum.Value)
                        ? '-'
                        : typeof roomObject.datum.OriginalValue === 'number'
                          ? roomObject.datum.OriginalValue
                          : roomObject.datum.Value}
                    </div>
                  ),
                )}
              </div>
            )}
          />
        </XYChart>
      </div>
      <Transition show={!hideLegend}>
        <div className="absolute w-full flex flex-wrap justify-center top-8 text-xs">
          <Legend
            key={lineSensorTypes.length + secondaryYScaleData.length}
            scaleType="ordinal"
            labelFormat={(r) => r}
            scale={scaleOrdinal({
              domain: filteredTotal.length
                ? [...lineSensorTypes, ...areaSensorTypes, room?.Name ?? '']
                : [...lineSensorTypes, ...areaSensorTypes],
              range: colorRange,
            })}
          />
        </div>
      </Transition>
    </>
  );
}

export default function ResponsiveRoomOccupancySensorCount(
  props: ResponsiveRoomOccupancySensorCountProps,
) {
  return (
    <ParentSize>
      {({ width, height }) => (
        <RoomOccupancySensorCount {...props} width={width} height={height} />
      )}
    </ParentSize>
  );
}
