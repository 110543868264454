import Table from 'generic/components/Table/Table';
import useStore from 'model/store';
import { FormattedMessage, useIntl } from 'translations/Intl';

import type { ColumnDef } from '@tanstack/react-table';
import Button from 'mda2-frontend/src/generic/components/Form/Button';
import Transition from 'mda2-frontend/src/generic/components/Transition';
import Loader from 'mda2-frontend/src/generic/components/layout/BarLoader';
import {
  type 
  KeycloakSubGroupMembersQuery,
  type 
  KeycloakUsersQuery,
  useKeycloakUsersQuery,
} from 'mda2-frontend/src/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import { useEffect, useMemo, useState } from 'react';
import { HiOutlineChevronRight, HiUserPlus } from 'react-icons/hi2';

export interface User {
  id: string;
}

type Data = KeycloakUsersQuery['GetUsers']['groupMembers'][number];

interface MembersInputProps {
  loadingGroupMembers: boolean;
  members:
    | KeycloakSubGroupMembersQuery['GetUsersForSubGroup']['groupMembers']
    | null;
  usersToAdd: User[];
  setUsersToAdd: (userToAdd: User[]) => void;
  usersToDelete: User[];
  setUsersToDelete: (userToDelete: User[]) => void;
  selectedGroup?: string;
}

export default function MembersInput({
  members,
  loadingGroupMembers,
  usersToAdd,
  setUsersToAdd,
  usersToDelete,
  setUsersToDelete,
  selectedGroup,
}: MembersInputProps): JSX.Element | null {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const [open, setOpen] = useState(selectedGroup === 'Admin');
  const user = useStore((state) => state.user);
  const [{ data: users, fetching: loadingUsers }] = useKeycloakUsersQuery({
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.VIEW_USERGROUP),
      [hasuraHeader],
    ),
  });

  const defaultColumns: ColumnDef<Data>[] = [
    {
      accessorKey: 'username',
      id: 'username',
      header: intl.formatMessage({ id: 'Name' }),
      enableGrouping: false,
    },
  ];

  useEffect(() => {
    if (members) {
      setUsersToAdd(
        members.map((u) => ({
          id: u.id,
        })),
      );
    }
  }, [setUsersToAdd, members]);

  const initialRowSelection = useMemo(
    () =>
      Object.fromEntries(
        Object.entries<boolean>(
          users?.GetUsers.groupMembers.reduce(
            (o, key, idx) =>
              Object.assign(o, {
                [idx]: members?.map((m) => m.id).includes(key.id),
              }),
            {},
          ) ?? {},
        ).filter(([, value]) => value),
      ),
    // Only set the initial selection when the data is available
    [members, users?.GetUsers.groupMembers],
  );

  return (
    <>
      <Loader loading={loadingUsers || loadingGroupMembers} />
      <div className="flex flex-col gap-2 border border-neutral-300 dark:border-neutral-700 p-2 rounded-md">
        <Button
          className="flex items-center gap-2"
          onClick={() => setOpen(!open)}
          data-test-id="members-button"
        >
          <HiUserPlus className="text-xs size-5 dark:text-neutral-200" />
          <div className="font-bold">
            <FormattedMessage id="Members" />
          </div>
          <HiOutlineChevronRight
            className={`${open ? 'rotate-90' : ''} size-6 transition-transform`}
          />
        </Button>

        <Transition
          show={open && (users?.GetUsers.groupMembers || []).length > 0}
          className="flex flex-col gap-2"
        >
          <Table<Data>
            id="group-members"
            columns={defaultColumns}
            initialState={{
              rowSelection: initialRowSelection,
            }}
            data={
              users?.GetUsers.groupMembers.map((u) => ({
                ...u,
                // Only disable selecting/deselecting user when a group is being edited
                disabled: selectedGroup === 'Admin' && u.email === user?.email,
              })) || []
            }
            loading={loadingUsers}
            enabledFeatures={{
              enableGlobalFilter: false,
              enableRowSelection: true,
              enableCsvExport: false,
              enableColumnSelection: false,
            }}
            onSelectedChange={(r) => {
              const clickedUser = users?.GetUsers.groupMembers.find(
                (u) => u.id === r.original.id,
              );
              if (r.getIsSelected()) {
                // remove from usersToAdd
                setUsersToAdd(usersToAdd.filter((u) => u.id !== r.original.id));
                // add to usersToDelete
                if (clickedUser) {
                  setUsersToDelete([...usersToDelete, { id: clickedUser.id }]);
                }
              } else {
                // filter from usersToDelete
                setUsersToDelete(
                  usersToDelete.filter((u) => u.id !== r.original.id),
                );
                // add to usersToAdd
                if (clickedUser) {
                  setUsersToAdd([...usersToAdd, { id: clickedUser.id }]);
                }
              }
            }}
          />
        </Transition>
      </div>
    </>
  );
}
