import { useOrganizationOfflineMinutesQuery } from 'graphql/types';
import useStore from 'model/store';
import { useIntl } from 'translations/Intl';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';

import Card from 'mda2-frontend/src/generic/components/Card';
import PieChart from 'mda2-frontend/src/generic/components/PieChart';
import getColor from 'mda2-frontend/src/utils/getColor';
import { useMemo } from 'react';

interface OrganizationsStatusProps {
  onlineBeacons: number;
  offlineBeacons: number;
  organization: string;
}

export default function OrganizationStatus({
  onlineBeacons,
  offlineBeacons,
  organization,
}: OrganizationsStatusProps) {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const userRoles = useStore((state) => state.user)?.roles;
  const [{ data }] = useOrganizationOfflineMinutesQuery({
    context: useMemo(
      () =>
        hasuraHeader(
          userRoles?.includes(HasuraPermissions.READ_ALL)
            ? HasuraPermissions.READ_ALL
            : HasuraPermissions.READ,
        ),
      [hasuraHeader, userRoles],
    ),
  });
  return (
    <Card
      title={organization}
      className="relative w-32 h-32 pb-2 px-6 py-6"
      infoText={intl.formatMessage(
        {
          id: 'Offline-chart-description',
        },
        {
          offlineMinutes: data?.Organizations.find(
            (o) => o.Name === organization,
          )?.OfflineMinutes,
        },
      )}
    >
      <PieChart
        colors={[getColor('RED'), getColor('GREEN')]}
        data={[
          {
            id: intl.formatMessage({ id: 'Offline Beacons' }),
            value: offlineBeacons,
          },
          {
            id: intl.formatMessage({ id: 'Online Beacons' }),
            value: onlineBeacons,
          },
        ]}
      />
    </Card>
  );
}
