import Transition from 'generic/components/Transition';

interface StatusSkeletonProps {
  loading: boolean;
}

export default function StatusSkeleton({ loading }: StatusSkeletonProps) {
  return (
    <Transition show={loading}>
      <div className="w-full animate-pulse space-x-2 flex group-hover:bg-neutral-50 dark:group-hover:bg-neutral-700">
        <div className="w-36 h-32 bg-neutral-200 rounded-md items-center p-4 space-y-2">
          <div className="w-full justify-between flex">
            <div className="h-2 w-12 rounded-md bg-neutral-400" />
            <div className="size-2 rounded-md bg-neutral-400" />
          </div>
          <div className="w-full justify-center flex">
            <div className="h-20 w-20 bg-neutral-400 rounded-full" />
          </div>
        </div>
        <div className="w-full bg-neutral-200 rounded-md h-32" />
      </div>
    </Transition>
  );
}
