import type { SnapFeatures } from 'common/types';
import getColor from 'mda2-frontend/src/utils/getColor';
import type Feature from 'ol/Feature';
import type Geometry from 'ol/geom/Geometry';
import OLVectorLayer from 'ol/layer/Vector';
import type VectorSource from 'ol/source/Vector';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import type { LayerOptions } from './Layer';
import VectorLayer from './VectorLayer';

const styleFunction = (
  feature: SnapFeatures,
  snappedFeatureIds: null | string[],
) => {
  const isVisible = snappedFeatureIds?.includes(
    feature.getProperties().snapIdentifier,
  );

  return [
    new Style({
      stroke: new Stroke({
        // Not possible to hide https://stackoverflow.com/questions/54108038/getfeaturesatpixel-to-include-decluttered-hidden-features
        color: getColor('WHITE', isVisible ? '1' : '0'),
        width: 3,
        lineDash: [7, 10],
      }),
    }),
    new Style({
      stroke: new Stroke({
        color: getColor('NEUTRAL900', isVisible ? '.6' : '0'),
        width: 2,
        lineDash: [7, 10],
      }),
    }),
  ];
};

interface Options extends LayerOptions {
  name: string;
  // TODO: OL 9.2.5 requires this hack of adding Feature<Geometry> otherwise there are type errors
  source: VectorSource<Feature<Geometry> | SnapFeatures>;
}

interface SnapLayerOptions {
  name: string;
  // TODO: OL 9.2.5 requires this hack of adding Feature<Geometry> otherwise there are type errors
  olLayer: OLVectorLayer<VectorSource<Feature<Geometry> | SnapFeatures>>;
}

class SnapLayer extends VectorLayer<SnapFeatures> {
  name: string;

  // TODO: OL 9.2.5 requires this hack of adding Feature<Geometry> otherwise there are type errors
  source: VectorSource<Feature<Geometry> | SnapFeatures>;

  snappedLineIds: null | string[];

  selectedBeaconId: null | string;

  constructor(options: Options) {
    super({
      ...{
        olLayer: new OLVectorLayer({
          source: options.source,
          style: (feat) =>
            styleFunction(feat as SnapFeatures, this.snappedLineIds),
        }),
      },
      name: options.name,
    } as SnapLayerOptions);
    this.snappedLineIds = null;
    this.selectedBeaconId = null;
    this.name = options.name;
    this.source = options.source;
  }

  setSnappedLineIds(newSnappedLineIds: string[] | null) {
    this.snappedLineIds = newSnappedLineIds;
    this.olLayer.changed();
  }
}

export default SnapLayer;
