import Select from 'generic/components/Form/Select';
import Switch from 'generic/components/Form/Switch';
import Tooltip from 'generic/components/Tooltip';
import type { SetSceneConfigMutationVariables } from 'graphql/types';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'translations/Intl';

export type BluerangeConfigScene = Omit<
  Omit<SetSceneConfigMutationVariables, 'BeaconInfos'>,
  'MqttSystem'
> & {
  pirDelay: number;
} & { powerMode: number } & { targetBrightness: number } & {
  aloneAtWork: number;
};

interface BluerangeConfigurationProps {
  setConfiguration: React.Dispatch<
    React.SetStateAction<BluerangeConfigScene | undefined>
  >;
}

export default function BluerangeConfiguration({
  setConfiguration,
}: BluerangeConfigurationProps) {
  const intl = useIntl();
  const [pirDelay, setPIRDelay] = useState(0);
  const [powerMode, setPowerMode] = useState(0);
  const [targetBrightness, setTargetBrightness] = useState(0);
  const [aloneAtWork, setAloneAtWork] = useState(0);
  const [now, setNow] = useState(false);

  const configurationData = {
    powerMode: {
      0: intl.formatMessage({ id: 'Do not change' }),
      1: intl.formatMessage({ id: 'Full automatic (automatic on/off)' }),
      2: intl.formatMessage({ id: 'Semi automatic (manual on/automatic off)' }),
      3: intl.formatMessage({ id: 'Manual (manual on/off)' }),
      4: intl.formatMessage({
        id: 'Manual with plug-in circuit (on when power on)',
      }),
    },
    pirDelay: {
      0: intl.formatMessage({ id: 'Do not change' }),
      10: intl.formatMessage({ id: '{min} minutes' }, { min: 10 }),
      30: intl.formatMessage({ id: '{min} minutes' }, { min: 30 }),
      60: intl.formatMessage({ id: '{min} minutes' }, { min: 60 }),
      120: intl.formatMessage({ id: '{min} minutes' }, { min: 120 }),
    },
    targetBrightness: {
      0: intl.formatMessage({ id: 'Do not change' }),
      1: intl.formatMessage({ id: 'Deactivate' }),
      200: intl.formatMessage({ id: 'Lux target value' }, { lux: 200 }),
      300: intl.formatMessage({ id: 'Lux target value' }, { lux: 300 }),
      400: intl.formatMessage({ id: 'Lux target value' }, { lux: 400 }),
      500: intl.formatMessage({ id: 'Lux target value' }, { lux: 500 }),
      600: intl.formatMessage({ id: 'Lux target value' }, { lux: 600 }),
      700: intl.formatMessage({ id: 'Lux target value' }, { lux: 700 }),
      800: intl.formatMessage({ id: 'Lux target value' }, { lux: 800 }),
    },
    aloneAtWork: {
      0: intl.formatMessage({ id: 'Do not change' }),
      1: intl.formatMessage({ id: 'Activate' }),
      2: intl.formatMessage({ id: 'Deactivate' }),
    },
  };

  useEffect(() => {
    setConfiguration({
      powerMode,
      pirDelay,
      targetBrightness,
      aloneAtWork,
      Now: now,
    });
  }, [
    powerMode,
    pirDelay,
    targetBrightness,
    aloneAtWork,
    setConfiguration,
    now,
  ]);

  return (
    <div className="flex flex-col space-y-2">
      <Select
        value={powerMode}
        onChangeSelected={(selected) =>
          selected ? setPowerMode(selected) : setPowerMode(0)
        }
        options={Object.keys(configurationData.powerMode).map((d) => Number(d))}
        label="On & Off behavior"
        renderValue={(d) =>
          configurationData.powerMode[
            d as keyof typeof configurationData.powerMode
          ]
        }
      />
      <Select
        value={targetBrightness}
        onChangeSelected={(selected) =>
          selected ? setTargetBrightness(selected) : setTargetBrightness(0)
        }
        options={Object.keys(configurationData.targetBrightness).map((d) =>
          Number(d),
        )}
        label="Brightness regulation"
        renderValue={(d) =>
          configurationData.targetBrightness[
            d as keyof typeof configurationData.targetBrightness
          ]
        }
      />
      <Select
        value={pirDelay}
        onChangeSelected={(selected) =>
          selected ? setPIRDelay(selected) : setPIRDelay(0)
        }
        options={Object.keys(configurationData.pirDelay).map((d) => Number(d))}
        label="Change delay time"
        renderValue={(d) =>
          configurationData.pirDelay[
            d as keyof typeof configurationData.pirDelay
          ]
        }
      />
      <Select
        value={aloneAtWork}
        onChangeSelected={(selected) =>
          selected ? setAloneAtWork(selected) : setAloneAtWork(0)
        }
        options={Object.keys(configurationData.aloneAtWork).map((d) =>
          Number(d),
        )}
        label="Alone@Work operation"
        renderValue={(d) =>
          configurationData.aloneAtWork[
            d as keyof typeof configurationData.aloneAtWork
          ]
        }
      />
      <div className="flex items-center space-x-1">
        <div>
          <FormattedMessage id="Midnight" />
        </div>
        <Switch
          isEnabled={now}
          onSetEnable={setNow}
          disabledColor="bg-green-500"
        />
        <div className="flex space-x-1 items-center">
          <div>
            <FormattedMessage id="Now" />
          </div>
          <Tooltip>
            <FormattedMessage id="now-description" />
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
