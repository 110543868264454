import StyledButton from 'generic/components/Form/Button/StyledButton';
import HelpTooltip from 'generic/components/HelpTooltip';
import LuminaireIcon from 'generic/components/LuminaireIcon/LuminaireIcon';
import Tooltip from 'generic/components/Tooltip';
import Transition from 'generic/components/Transition';
import useStore from 'model/store';
import { FormattedMessage, useIntl } from 'translations/Intl';

import {
  DeviceTypes,
  ModuleType,
  OtherType,
} from 'mda2-frontend/src/common/types';
import Button from 'mda2-frontend/src/generic/components/Form/Button';
import Input from 'mda2-frontend/src/generic/components/Form/Input';
import Panel from 'mda2-frontend/src/generic/components/Panel';
import {
  type UnusedBeaconsSubscription,
  useUnusedBeaconsSubscription,
} from 'mda2-frontend/src/graphql/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  HiOutlineCog,
  HiOutlineMagnifyingGlass,
  HiOutlineWifi,
} from 'react-icons/hi2';

import EnrollDevices from './components/EnrollDevices';
import MqttConfigurationModal from './components/EnrollDevices/components/MqttConfigurationModal';
import SetupHotspotModal from './components/SetupHotspotModal';

interface SelectNewBeaconCardProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onBeaconSelect: (
    beacon: UnusedBeaconsSubscription['MqttBeacons'][number],
  ) => void;
}

export default function SelectNewBeaconCard({
  open,
  setOpen,
  onBeaconSelect,
}: SelectNewBeaconCardProps) {
  const intl = useIntl();
  const organizationUuid = useStore(
    (state) => state.organizationSettings.organizationUuid,
  );
  const enrollmentConfiguration = useStore(
    (state) => state.enrollmentConfiguration,
  );
  const setInitialSetupSteps = useStore((state) => state.setInitialSetupSteps);
  const [beaconSearch, setBeaconSearch] = useState('');
  const [showEnroll, setShowEnroll] = useState(false);
  const [showHotspot, setShowHotspot] = useState(false);
  const [showMqttConfiguration, setShowMqttConfiguration] = useState(false);

  const [{ data: unusedBeacons, fetching: loadingBeacons }] =
    useUnusedBeaconsSubscription({
      variables: {
        DeskType: OtherType.DESKINUSE,
        ModuleTypes: Object.values(ModuleType),
      },
    });

  const filteredList = useMemo(() => {
    if (unusedBeacons?.MqttBeacons) {
      if (beaconSearch.length > 0) {
        return unusedBeacons.MqttBeacons.filter(
          (beacon) => beacon.Name.search(new RegExp(beaconSearch, 'i')) !== -1,
        );
      }
      return unusedBeacons.MqttBeacons;
    }
    return [];
  }, [unusedBeacons, beaconSearch]);

  /*  UUID is too long for the WiFi hotspot (max length 32)
   *  UUID in WiFi hotspot is used for setting an ACL and having a direct mapping
   *  from organization ID in the database to ACL in Keycloak user attributes
   *
   *  Only the last part will be removed and it should still be unique enough for
   *  it to not be a problem
   */
  const shortenUuid = useCallback(() => {
    if (organizationUuid) {
      const splitUuid = organizationUuid.split('-');
      return `${splitUuid[0]}-${splitUuid[1]}-${splitUuid[2]}-${splitUuid[3]}`;
    }
    return null;
  }, [organizationUuid]);

  useEffect(() => {
    if (open) {
      setBeaconSearch('');
    }
  }, [open]);

  return (
    <Panel open={open} setOpen={setOpen} dataTestId="select-beacon-panel">
      <Panel.Content
        title={<FormattedMessage id="Available devices" />}
        className="space-y-3"
      >
        <div className="overflow-hidden space-y-3 flex flex-col flex-grow">
          <Input
            type="text"
            value={beaconSearch}
            data-test-id="beacon-search"
            onChangeValue={(e) => setBeaconSearch(e)}
            icon={<HiOutlineMagnifyingGlass />}
            placeholder={intl.formatMessage({
              id: 'Device name',
            })}
            required
            autoFocus
          />

          <div className="rounded-md relative border border-neutral-200 dark:border-neutral-700 flex flex-col overflow-hidden max-h-full">
            <div
              data-test-id="devices-list"
              className="rounded-b-md bg-white dark:bg-neutral-800 divide-y divide-neutral-200 dark:divide-neutral-700 overflow-y-auto"
            >
              {filteredList.map((beacon) => (
                <Button
                  data-test-id={beacon.Name}
                  key={beacon.Id}
                  className="flex flex-row hover:bg-neutral-100 dark:hover:bg-neutral-900 w-full items-center px-1 py-2 gap-2"
                  onClick={() =>
                    onBeaconSelect({
                      ...beacon,
                      // Need to add __typename as this field is checked in order to determine if it is a beacon
                      // With the useSubscription hook this field is not set
                      __typename: 'MqttBeacons',
                    })
                  }
                >
                  <span className="dark:bg-neutral-600 rounded-full p-1">
                    <LuminaireIcon
                      device={{
                        deviceType:
                          (beacon.DeviceType?.Name as
                            | DeviceTypes
                            | undefined) ?? DeviceTypes.NODE,
                        desksInUse: beacon.NumberOfAvailableDesks ?? 0,
                      }}
                    />
                  </span>
                  <div className="whitespace-nowrap text-sm text-left">
                    {beacon.Name}
                  </div>
                </Button>
              ))}

              <Transition
                show={filteredList.length === 0 && loadingBeacons}
                className="divide-y divide-neutral-200 dark:divide-neutral-700"
              >
                {Array.from({ length: 4 }).map((_, i) => (
                  <div
                    key={i}
                    className="w-full animate-pulse flex p-2 space-x-2"
                  >
                    <div className="w-8 bg-neutral-200 h-6 rounded-md" />
                    <div className="w-24 bg-neutral-200 h-6 rounded-md" />
                  </div>
                ))}
              </Transition>

              <Transition show={filteredList.length === 0 && !loadingBeacons}>
                <div className=" px-6 py-2 flex flex-col space-y-2">
                  <FormattedMessage id="No devices" />
                </div>
              </Transition>
            </div>
          </div>
        </div>
        <div className="space-y-3">
          <div className="flex space-x-2">
            <HelpTooltip
              stepName="clickedEnroll"
              content={
                <StyledButton
                  className="w-full"
                  data-test-id="enroll-devices"
                  onClick={() => {
                    setShowEnroll(!showEnroll);
                    setInitialSetupSteps('clickedEnroll');
                  }}
                >
                  {showEnroll ? (
                    <FormattedMessage id="Stop enrollment" />
                  ) : (
                    <FormattedMessage id="Enroll devices" />
                  )}
                </StyledButton>
              }
            >
              <FormattedMessage id="Initial: Enroll device" />
            </HelpTooltip>
            <Transition show={showEnroll}>
              <Tooltip
                staticPlacement={
                  !enrollmentConfiguration &&
                  !showMqttConfiguration &&
                  !showHotspot
                }
                content={
                  <StyledButton
                    data-test-id="configure-enrollment"
                    className={`h-full ${
                      enrollmentConfiguration
                        ? '!bg-green-100  hover:bg-green-300 dark:bg-green-100 dark:hover:bg-green-300'
                        : '!bg-red-100 hover:!bg-red-300 dark:bg-red-100 dark:hover:bg-red-300 animate-pulse'
                    }`}
                    onClick={() => setShowMqttConfiguration(true)}
                  >
                    <HiOutlineCog
                      className={`size-5 ${
                        enrollmentConfiguration
                          ? 'text-green-500'
                          : 'text-red-500'
                      }`}
                    />
                  </StyledButton>
                }
              >
                <FormattedMessage id="MQTT target configuration" />
              </Tooltip>
            </Transition>
            <Transition show={showEnroll}>
              <Tooltip
                content={
                  <StyledButton
                    className="h-full !bg-green-100 hover:!bg-green-300 dark:bg-green-100 dark:hover:bg-green-300"
                    onClick={() => setShowHotspot(true)}
                  >
                    <HiOutlineWifi className="size-5 text-green-500" />
                  </StyledButton>
                }
              >
                <FormattedMessage id="Setup hotspot" />
              </Tooltip>
            </Transition>
          </div>

          <Transition show={showEnroll && !!shortenUuid()}>
            <EnrollDevices shortenedOrganizationUuid={shortenUuid()!} />
          </Transition>
        </div>

        <MqttConfigurationModal
          open={showMqttConfiguration}
          setOpen={setShowMqttConfiguration}
        />

        <Transition show={!!shortenUuid()}>
          <SetupHotspotModal
            open={showHotspot}
            setOpen={setShowHotspot}
            uuid={shortenUuid()!}
          />
        </Transition>
      </Panel.Content>
    </Panel>
  );
}
