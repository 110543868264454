import type { Map } from 'ol';
import type Feature from 'ol/Feature';
import Observable, { unByKey } from 'ol/Observable';
import type { Coordinate } from 'ol/coordinate';
import type { EventsKey } from 'ol/events';
import type Geometry from 'ol/geom/Geometry';
import type OLLayer from 'ol/layer/Layer';
import type LayerRenderer from 'ol/renderer/Layer';
import type Source from 'ol/source/Source';
import { uuidv4 } from 'utils/uuid';

export interface LayerOptions {
  name?: string;
  children?: Layer[];
  clickCallbacks?: ((...args: any) => void)[];
  map?: Map;
  olLayer?: OLLayer<Source, LayerRenderer<any>>;
  olListenersKeys?: EventsKey[];
  properties?: any;
  visible?: boolean;
  onClick?: () => void;
}

/**
 * A class representing a layer to display on map.
 *
 * @classproperty {string} name - Name of the layer
 * @classproperty {Layer[]} children - List of children.
 * @classproperty {boolean} visible - Define if the layer is visible or not.
 * @classproperty {Object} properties - Custom properties.
 * @classproperty {ol/Map~Map} map - The map where the layer is displayed.
 */
export default class Layer extends Observable {
  children: Exclude<LayerOptions['children'], undefined> = [];

  clickCallbacks?: LayerOptions['clickCallbacks'] = [];

  map?: LayerOptions['map'];

  olLayer!: Exclude<LayerOptions['olLayer'], undefined>;

  olListenersKeys?: LayerOptions['olListenersKeys'] = [];

  properties?: LayerOptions['properties'] = null;

  visible = true;

  name?: LayerOptions['name'] = '';

  /**
   * @param {Object} options
   * @param {string} [options.name=uuid()] Layer name. Default use a generated uuid.
   * @param {Array<Layer>} [options.children=[]] Sublayers.
   * @param {Object} [options.properties={}] Application-specific layer properties.
   * @param {boolean} [options.visible=true] If true this layer is visible on the map.
   * @param {ol/layer/Layer~Layer} options.olLayer The layer (required).
   */
  constructor(options: LayerOptions) {
    super();
    this.defineProperties(options);

    // Add click callback
    const { onClick } = options;
    if (onClick) {
      this.onClick(onClick);
    }

    this.olLayer.setVisible(this.visible);
  }

  defineProperties({
    name,
    children,
    visible,
    properties,
    olLayer,
  }: LayerOptions): void {
    const dfltName = name || uuidv4();
    Object.defineProperties(this, {
      name: {
        value: dfltName,
      },
      key: {
        value: dfltName.toLowerCase(),
      },
      children: {
        value: children || [],
        writable: true,
      },
      visible: {
        value: visible === undefined ? true : visible,
        writable: true,
      },
      properties: {
        value: properties || {},
      },
      map: {
        writable: true,
      },
      olLayer: { value: olLayer, writable: true },
      olListenersKeys: {
        value: [],
      },
      /**
       * Callback function when a user click on a vehicle.
       */
      clickCallbacks: {
        value: [],
      },
    });
  }

  /**
   * Initialize the layer with the map passed in parameters.
   *
   * @param {ol/Map~Map|mapboxgl.Map} map A map.
   */
  init(map: Map): void {
    this.terminate();
    this.map = map;

    if (!this.map) {
      return;
    }

    this.olListenersKeys?.push(
      this.map.getLayers().on('remove', (evt) => {
        if (evt.element === this.olLayer) {
          this.terminate();
        }
      }),
    );
  }

  /**
   * Terminate what was initialized in init function. Remove layer, events...
   */
  terminate(): void {
    if (this.olListenersKeys) {
      unByKey(this.olListenersKeys);
    }
  }

  /**
   * Get a layer property.
   *
   * @param {string} name Property name.
   * @returns {property} Property
   */
  get(name: string): unknown {
    return this.properties[name];
  }

  /**
   * Set a layer property.
   *
   * @param {string} name Property name.
   * @param {string | boolean} value Value.
   */
  set(name: string, value: unknown): void {
    this.properties[name] = value;
    this.dispatchEvent(`change:${name}`);
  }

  /**
   * Change the visibility of the layer
   *
   * @param {boolean} visible Defines the visibility of the layer
   * @param {boolean} [stopPropagationDown]
   * @param {boolean} [stopPropagationUp]
   * @param {boolean} [stopPropagationSiblings]
   */
  setVisible(visible: boolean, stopPropagation = false): void {
    if (visible === this.visible) {
      return;
    }
    this.visible = visible;

    this.dispatchEvent({
      type: 'change:visible',
      target: this,
      propagationStopped: stopPropagation,
      defaultPrevented: stopPropagation,
      preventDefault: () => null,
      stopPropagation: () => null,
    });

    this.olLayer.setVisible(this.visible);
  }

  /**
   * Returns an array with visible child layers
   *
   * @returns {Layer[]} Visible children
   */
  getVisibleChildren() {
    return this.children?.filter((child) => child.visible);
  }

  /**
   * Checks whether the layer has child layers with visible set to True
   *
   * @returns {boolean} True if the layer has visible child layers
   */
  hasVisibleChildren(): boolean {
    return !!this.hasChildren(true);
  }

  /**
   * Checks whether the layer has any child layers with visible equal to the input parameter
   *
   * @param {boolean} visible The state to check the childlayers against
   * @returns {boolean} True if the layer has children with the given visibility
   */
  hasChildren(visible: boolean): boolean {
    return !!this.children?.find((child) => child.visible === visible);
  }

  /**
   * Add a child layer
   *
   * @param {Layer} layer Add a child layer
   */
  addChild(layer: Layer): void {
    this.children?.unshift(layer);
    this.dispatchEvent('change:children');
  }

  /**
   * Removes a child layer by layer name
   *
   * @param {string} name Layer's name
   */
  removeChild(name: string): void {
    for (let i = 0; i < (this.children?.length ?? 0); i += 1) {
      if (this.children?.[i]?.name === name) {
        this.children?.splice(i, 1);
        return;
      }
    }
    this.dispatchEvent('change:children');
  }

  /**
   * Request feature information for a given coordinate.
   * This function must be implemented by inheriting layers.
   *
   * @returns {Promise<{layer: Layer, features: ol/Feature~Feature[0], coordinate: null}}>} An empty response.
   */
  getFeatureInfoAtCoordinate(coordinate?: Coordinate): Promise<{
    layer: Layer;
    features: Feature<Geometry>[];
    coordinate?: Coordinate;
  }> {
    // This layer returns no feature info.
    // The function is implemented by inheriting layers.
    return Promise.resolve({
      layer: this,
      features: [],
      coordinate,
    });
  }

  onClick(callback: () => void): void {
    if (typeof callback === 'function' && this.clickCallbacks?.length) {
      if (!this.clickCallbacks.includes(callback)) {
        this.clickCallbacks.push(callback);
      }
    } else {
      throw new Error('callback must be of type function.');
    }
  }
}
