import { FormattedMessage, useIntl } from 'translations/Intl';

import { Action } from 'mda2-frontend/src/common/types';
import Input from 'mda2-frontend/src/generic/components/Form/Input';
import Modal from 'mda2-frontend/src/generic/components/Modal';
import ModalFooter from 'mda2-frontend/src/generic/components/ModalFooter';
import Subtitle from 'mda2-frontend/src/generic/components/Subtitle';
import Transition from 'mda2-frontend/src/generic/components/Transition';
import {
  type BuildingPartsFragment,
  useBuildingSensorsQuery,
  useDeleteBuildingMutation,
} from 'mda2-frontend/src/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import useToast from 'mda2-frontend/src/utils/graphql/useToast';
import useStore from 'model/store';
import { useState } from 'react';
import { FaCircleNotch } from 'react-icons/fa';
import { HiOutlineTrash } from 'react-icons/hi2';

interface RemoveBuildingModalProps {
  buildingToRemove?: BuildingPartsFragment | undefined;
  setBuildingToRemove: (building?: BuildingPartsFragment) => void;
}

export default function RemoveBuildingModal({
  buildingToRemove,
  setBuildingToRemove,
}: RemoveBuildingModalProps): JSX.Element {
  const intl = useIntl();
  const AAWActivated = useStore((state) => state.AAWActivated);
  const [confirmDelete, setConfirmDelete] = useState('');
  const [{ fetching: isRemoving }, removeBuilding] =
    useDeleteBuildingMutation();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();

  const [{ data: buildingSensorsData, fetching: loadingBuildingSensors }] =
    useBuildingSensorsQuery({
      variables: { BuildingId: buildingToRemove?.Id ?? 0 },
      pause: !buildingToRemove?.Id,
    });
  const hasPrivateSensors = !!(
    buildingSensorsData?.Buildings_by_pk?.Floors ?? []
  ).find((b) =>
    b.Rooms.find((r) => r.RoomSensors.find((rS) => rS.Sensor.IsPrivate)),
  );

  return (
    <Modal
      title={intl.formatMessage({
        id: 'Confirm action',
      })}
      action={Action.REMOVE}
      open={!!buildingToRemove}
      setShowModal={() => setBuildingToRemove(undefined)}
      footer={
        <ModalFooter
          disabled={
            loadingBuildingSensors || confirmDelete !== buildingToRemove?.Name
          }
          proceed={intl.formatMessage({
            id: 'Confirm',
          })}
          isLoading={isRemoving}
          action={Action.REMOVE}
          onProceed={() => {
            if (confirmDelete === buildingToRemove?.Name) {
              removeBuilding(
                {
                  Name: buildingToRemove?.Name,
                  Input: {
                    buildingId: buildingToRemove.Id,
                  },
                  AAWActivated,
                },
                hasuraHeader(HasuraPermissions.DELETE_BUILDING, [
                  'MqttBeacons',
                ]),
              )
                .then((data) => toast(data))
                .finally(() => {
                  setBuildingToRemove(undefined);
                  setConfirmDelete('');
                });
            }
          }}
          onCancel={() => {
            setBuildingToRemove(undefined);
            setConfirmDelete('');
          }}
        />
      }
    >
      {loadingBuildingSensors ? (
        <Transition show={loadingBuildingSensors}>
          <FaCircleNotch className="size-6 animate-spin text-primary-500 m-4" />
        </Transition>
      ) : (
        <>
          <Subtitle
            value={intl.formatMessage({
              id: 'Are you sure to remove this building?',
            })}
            className="text-base pb-2"
          />

          <p className="pb-2">
            <FormattedMessage
              id="Deleting the building will delete all associated floors, rooms and desks. Enter the building name {name} in order to delete it"
              values={{
                name: <b>{buildingToRemove?.Name}</b>,
              }}
            />
          </p>

          {hasPrivateSensors && (
            <p className="pb-2 font-bold">
              <FormattedMessage id="There are private rooms in this building. By removing it, the entire history of the related beacons will be irrevocably deleted." />
            </p>
          )}

          <Input
            type="text"
            autoFocus
            value={confirmDelete}
            id="Remove building"
            data-test-id="remove-building"
            icon={<HiOutlineTrash />}
            onChangeValue={(input) => setConfirmDelete(input)}
            placeholder={buildingToRemove?.Name}
            required
          />
        </>
      )}
    </Modal>
  );
}
