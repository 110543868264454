import type { Event } from '../ReservationsCalendar/CustomEvent';

interface UpdateReservationProps {
  selectedEvent: Event;
}

export default function UpdateReservation({
  selectedEvent,
}: UpdateReservationProps): JSX.Element | null {
  return (
    <div className="flex flex-col  pt-4">
      <div className="text-lg  text-neutral-900 dark:text-neutral-400">
        {selectedEvent.title}
      </div>
    </div>
  );
}
