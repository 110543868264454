import Tooltip from 'generic/components/Tooltip';
import useStore from 'model/store';
import { FormattedMessage, useIntl } from 'translations/Intl';

import { RoomTypes } from 'mda2-frontend/src/common/types';
import Button from 'mda2-frontend/src/generic/components/Form/Button';
import Transition from 'mda2-frontend/src/generic/components/Transition';
import {
  type 
  FloorsTopologyQuery,
  useLiveOccupancyQuery,
} from 'mda2-frontend/src/graphql/types';
import getColor from 'mda2-frontend/src/utils/getColor';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import usePolling from 'mda2-frontend/src/utils/graphql/usePolling';
import { useEffect, useMemo, useState } from 'react';
import { HiOutlineExclamationTriangle } from 'react-icons/hi2';
import { Link, useNavigate } from 'react-router-dom';

import RoomOccupancy from './components/RoomOccupancy';

interface Floor {
  Id: number;
  Number: number;
  Rooms: null;
}
interface FloorOccupancyInterface {
  floor: Floor | FloorsTopologyQuery['Floors'][number];
  buildingName: string;
  buildingId?: number;
}

export default function FloorOccupancy({
  floor,
  buildingName,
  buildingId,
}: FloorOccupancyInterface): JSX.Element {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const navigate = useNavigate();
  const [initialLoading, setInitialLoading] = useState(true);
  const userRoles = useStore((state) => state.user)?.roles;
  const roomTypes = useStore((state) => state.userSettings.roomTypes);
  const setBuilding = useStore((state) => state.userApi.setBuilding);
  const setFloor = useStore((state) => state.userApi.setFloor);
  const [{ data, fetching: loading }, reexecuteQuery] = useLiveOccupancyQuery({
    variables: {
      Floor: floor.Number,
      Building: buildingName,
    },
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.VIEW_DASHBOARD),
      [hasuraHeader],
    ),
    pause: buildingName === '-' || !roomTypes.includes(RoomTypes.DESKS),
  });
  usePolling(loading, reexecuteQuery, 1000 * 15);

  useEffect(() => {
    if (initialLoading && !loading) {
      setInitialLoading(loading);
    }
  }, [loading, initialLoading]);

  const floorHasDesks = !!floor.Rooms?.find(
    (room) => room.RoomSensors.map((rS) => rS.Sensor.Desk).length > 0,
  );
  const offlineCount = data?.f_live_desks_occupancy[0]
    ? data.f_live_desks_occupancy[0].OfflineCount
    : 0;

  return (
    <>
      <div className="flex flex-row justify-between items-center">
        <Button
          className={`font-bold text-left ${
            buildingId ? 'hover:text-primary-500 hover:cursor-pointer' : ''
          }`}
          onClick={() => {
            // If it has no building ID then it is embedded
            if (buildingId) {
              setBuilding({ Id: buildingId, Name: buildingName });
              setFloor({ Id: floor.Id, Number: floor.Number });
              navigate('/findmyplace');
            }
          }}
        >
          <FormattedMessage
            id="{number} Floor"
            values={{
              number: floor.Number,
            }}
          />
        </Button>
        <Transition
          show={floorHasDesks && roomTypes.includes(RoomTypes.DESKS)}
          as="div"
        >
          <div className="flex items-center">
            <div
              className="text-sm"
              data-test-id={`floor-occupancy-${floor.Number}`}
            >
              {(
                (data?.f_live_desks_occupancy[0]?.HotPercentage || 0) +
                (data?.f_live_desks_occupancy[0]?.WarmPercentage || 0)
              ).toFixed(2)}{' '}
              % <FormattedMessage id="In use" /> (
              {(data?.f_live_desks_occupancy[0]?.HotCount || 0) +
                (data?.f_live_desks_occupancy[0]?.WarmCount || 0)}{' '}
              {(data?.f_live_desks_occupancy[0]?.HotCount || 0) +
                (data?.f_live_desks_occupancy[0]?.WarmCount || 0) ===
              1 ? (
                <FormattedMessage id="Desk" />
              ) : (
                <FormattedMessage id="Desks" />
              )}
              )
            </div>
            <Tooltip>
              <div className="text-xs flex flex-col">
                <div>
                  <span
                    style={{
                      color: getColor('RED'),
                    }}
                  >
                    <FormattedMessage id="hot" /> (
                    {data?.f_live_desks_occupancy[0]?.HotPercentage}%,{' '}
                    {data?.f_live_desks_occupancy[0]?.HotCount}{' '}
                    {data?.f_live_desks_occupancy[0]?.HotCount === 1 ? (
                      <FormattedMessage id="Desk" />
                    ) : (
                      <FormattedMessage id="Desks" />
                    )}
                    )
                  </span>{' '}
                  +
                </div>
                <div
                  style={{
                    color: getColor('YELLOW'),
                  }}
                >
                  <FormattedMessage id="warm" /> (
                  {data?.f_live_desks_occupancy[0]?.WarmPercentage}%,{' '}
                  {data?.f_live_desks_occupancy[0]?.WarmCount}{' '}
                  {data?.f_live_desks_occupancy[0]?.WarmCount === 1 ? (
                    <FormattedMessage id="Desk" />
                  ) : (
                    <FormattedMessage id="Desks" />
                  )}
                  )
                </div>
              </div>
            </Tooltip>
            {offlineCount > 0 && (
              <Tooltip
                content={
                  userRoles?.includes(HasuraPermissions.VIEW_STATUS) ? (
                    <Link
                      to={`/status?status=${intl.formatMessage({
                        id: 'Offline',
                      })}&building=${encodeURIComponent(
                        buildingName,
                      )}&floor=${encodeURIComponent(floor.Number)}`.replaceAll(
                        '%20',
                        '+',
                      )}
                    >
                      <HiOutlineExclamationTriangle className="size-4 rounded-full text-neutral-700 hover:text-primary-500 dark:text-white bg-neutral-200 dark:bg-neutral-700 transition-all" />
                    </Link>
                  ) : (
                    <div>
                      <HiOutlineExclamationTriangle className="size-4 rounded-full text-neutral-700 dark:text-white bg-neutral-200 dark:bg-neutral-700 transition-all" />
                    </div>
                  )
                }
              >
                <div className="text-xs flex">
                  {offlineCount === 1 ? (
                    <FormattedMessage
                      id="Offline: {number} desk"
                      values={{ number: offlineCount }}
                    />
                  ) : (
                    <FormattedMessage
                      id="Offline: {number} desks"
                      values={{ number: offlineCount }}
                    />
                  )}
                </div>
              </Tooltip>
            )}
          </div>
        </Transition>
      </div>
      {initialLoading ? (
        <div className="flex gap-2 py-1 justify-between">
          <div className="flex gap-2 w-full">
            <div className="size-3 rounded-full bg-neutral-200 animate-pulse rounded-md" />
            <div className="h-3 w-1/2 bg-neutral-200 animate-pulse rounded-md" />
          </div>
          <div className="h-3 w-8 bg-neutral-200 animate-pulse rounded-md" />
        </div>
      ) : (
        <Transition
          show={
            roomTypes.includes(RoomTypes.MEETING) &&
            !!data?.f_live_rooms_occupancy
          }
        >
          <RoomOccupancy
            floor={floor}
            buildingName={buildingName}
            loadedData={data?.f_live_rooms_occupancy}
          />
        </Transition>
      )}
    </>
  );
}
