import BluerangeConfiguration from 'pages/StatusView/components/ConfigureBeaconModal/components/BluerangeConfiguration';
import { useIntl } from 'translations/Intl';

import {
  MqttSystems,
  useSetSceneConfigMutation,
} from 'mda2-frontend/src/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from 'mda2-frontend/src/utils/graphql/useHasuraHeaders';
import parseBluerangeTopic from 'mda2-frontend/src/utils/parseBluerangeTopic';
import { useState } from 'react';
import { HiCog } from 'react-icons/hi2';

import type { BluerangeConfigScene } from 'pages/StatusView/components/ConfigureBeaconModal/components/BluerangeConfiguration/BluerangeConfiguration';
import useToast from 'utils/graphql/useToast';
import SetSceneButton from '../SetSceneButton';

interface SetSceneConfigButtonProps {
  mqttTopic: string;
}

export default function SetSceneConfigButton({
  mqttTopic,
}: SetSceneConfigButtonProps): JSX.Element {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const toast = useToast();
  const [configuration, setConfiguration] = useState<BluerangeConfigScene>();
  const [, setSceneConfigMutation] = useSetSceneConfigMutation();

  return (
    <SetSceneButton
      icon={<HiCog />}
      title="Set scene config"
      onProceed={() => {
        if (configuration) {
          setSceneConfigMutation(
            {
              BeaconInfos: [mqttTopic].map((topic) => ({
                beaconTopic: parseBluerangeTopic(topic),
                data: [
                  configuration.powerMode.toString(), // DATA 1 -> Power mode
                  configuration.pirDelay.toString(), // DATA 2 -> PIR delay
                  configuration.targetBrightness.toString(), // DATA 3 -> Target brightness
                  configuration.aloneAtWork.toString(), // DATA 4 -> AloneAtWork
                ],
              })),
              MqttSystem: MqttSystems.Bluerange,
              Now: configuration.Now,
            },
            hasuraHeader(HasuraPermissions.VIEW_ADMIN),
          ).then((data) => {
            toast(data, {
              message: {
                type: 'success',
                content: intl.formatMessage({ id: 'Configured beacon' }),
              },
            });
          });
        }
      }}
    >
      <BluerangeConfiguration setConfiguration={setConfiguration} />
    </SetSceneButton>
  );
}
